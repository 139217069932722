import { renderTransformers } from 'components/Map/core/layers/assets/point';
import { renderCabinets } from 'components/Map/core/layers/assets/point';
import { renderSwitches } from 'components/Map/core/layers/assets/point';
import { renderLoadingSwitches } from 'components/Map/core/layers/assets/point';
import { renderStreetLightCabinets } from 'components/Map/core/layers/assets/point';
import { renderStreetLights } from 'components/Map/core/layers/assets/point';
import { renderCableConnectors } from 'components/Map/core/layers/assets/point';
import { renderRTU } from 'components/Map/core/layers/assets/point';
import { renderCables } from 'components/Map/core/layers/assets/line';
import { renderOverheadLines } from 'components/Map/core/layers/assets/line';
import { renderCnaimElevation } from 'components/Map/core/layers/cnaim/Elevation';
import { renderCnaimCorrosion } from 'components/Map/core/layers/cnaim/Corrosion';
import { renderCnaimDistanceFromCoast } from 'components/Map/core/layers/cnaim/DistanceFromCoast';
import { renderOtherElectricityNetwork } from 'components/Map/core/layers/other/ElectricityNetworks';
import { renderOtherBuildings } from 'components/Map/core/layers/other/Buildings';
import { renderOtherCars } from 'components/Map/core/layers/other/Cars';
import { renderOtherHeatmaps } from 'components/Map/core/layers/other/Heatmap';
import { renderOtherZones } from 'components/Map/core/layers/other/Zones';
import { renderOtherCustomers } from 'components/Map/core/layers/other/Customers';
import { renderOtherCustomersDER } from 'components/Map/core/layers/other/DERs';

const Handler: Record<string, (legendItem: Map.LegendItem, settings: Map.Settings) => Map.Style[]> = {
  renderTransformers,
  renderCabinets,
  renderSwitches,
  renderLoadingSwitches,
  renderStreetLightCabinets,
  renderStreetLights,
  renderCableConnectors,
  renderRTU,
  renderCables,
  renderOverheadLines,
  renderCnaimElevation,
  renderCnaimCorrosion,
  renderCnaimDistanceFromCoast,
  renderOtherElectricityNetwork,
  renderOtherBuildings,
  renderOtherCars,
  renderOtherHeatmaps,
  renderOtherZones,
  renderOtherCustomers,
  renderOtherCustomersDER,
};

export { Handler };
