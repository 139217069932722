import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { employeeModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { employeeSelectorFactory } from 'modules/employees/selectors';
import { Modal, Button, FormInput, SelectUser, HiddenFormSubmit, DateRangePicker } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const EmployeesModal: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(employeeModalIdSelector);
  const employee: Employees.Item | undefined = useSelector(employeeSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && employee);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ employeeModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldTouched,
    setValues,
    setFieldValue,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!employee) return;
    setValues({
      ssoUserId: employee.ssoUserId,
      startDate: employee.startDate ? moment.utc(employee.startDate) : null,
      endDate: employee.endDate ? moment.utc(employee.endDate) : null,
      hours: employee.minutes / 60,
    });
    setShow(true);
  }, [employee, setValues]);

  const handleUserSelectChange = useCallback(
    (userIds: number[]) => {
      setFieldValue('ssoUserId', userIds?.[0] || null);
    },
    [setFieldValue]
  );

  const handleDateRangePickerApply = useCallback(
    (event: React.SyntheticEvent, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      setFieldValue('startDate', picker.startDate);
      setFieldValue('endDate', picker.endDate);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create employee" onClick={toggleModal} size="large" variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit employee' : 'Create employee'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectUser
              errorKey={Boolean(touched.ssoUserId && errors.ssoUserId) ? (errors.ssoUserId as string) : ''}
              userIds={values.ssoUserId ? [values.ssoUserId] : []}
              setUserIds={handleUserSelectChange}
              isClearable
              onBlur={() => setFieldTouched('ssoUserId')}
              disabled={isEditMode}
            />
          </Form.Group>
          <Form.Group>
            <DateRangePicker
              label={`${getIntl('Start date')} - ${getIntl('End date')}`}
              startDate={values.startDate}
              endDate={values.endDate}
              errorKey={Boolean(touched.startDate && errors.startDate) ? (errors.startDate as string) : ''}
              onApply={handleDateRangePickerApply}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Hours"
              type="number"
              name="hours"
              value={values.hours}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.hours && errors.hours) ? (errors.hours as string) : ''}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default EmployeesModal;
