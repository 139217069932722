import React from 'react';
import { Switch, Route } from 'react-router';
import { PageNotFound } from 'pages';
import { ViewOverview, ViewEmissionsForecast, ViewDetailedEmissions, ViewEmissionsByScope } from 'components/CO2e';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';
import { Routes } from 'constants/index';

const PageCO2e: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isCO2eEnabled}>
    <Switch>
      <Route exact path={Routes.CO2e} component={ViewOverview} />
      <Route exact path={Routes.CO2eEmissionsForecast} component={ViewEmissionsForecast} />
      <Route exact path={Routes.CO2eDetailedEmissions} component={ViewDetailedEmissions} />
      <Route exact path={Routes.CO2eEmissionsByScope} component={ViewEmissionsByScope} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageCO2e;
