import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchVoltageLevelsOptionsAction } from 'modules/investment';
import { voltageLevelsOptionsSelector } from 'modules/investment/selectors';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | number[] | null;
}

const SelectVoltageLevel: React.FC<Props> = ({ value, isMulti, onChange, ...props }) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const options = useSelector(voltageLevelsOptionsSelector);

  useEffect(() => {
    if (options || !portfolioId || !scenarioId) return;
    setIsLoading(true);
    dispatch(fetchVoltageLevelsOptionsAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [options, portfolioId, scenarioId, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        const localizedOption = { ...option, label: getIntl(option.label) };
        if (isMulti && Array.isArray(value) && value?.includes(Number(option.value)))
          acc.selectValues.push(localizedOption);
        if (!isMulti && value === Number(option.value)) acc.selectValues.push(localizedOption);
        acc.selectOptions.push(localizedOption);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, getIntl, lng]);

  return (
    <FormReactSelect
      name="voltageLevels"
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey=""
      value={selectValues}
      options={selectOptions}
      onChange={onChange}
      isSearchable={false}
      isMulti={isMulti}
      {...props}
    />
  );
};

export default SelectVoltageLevel;
