import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { getGalleryImages } from 'utils';
import { AssetLifeAPI, PaginationType } from 'constants/index';

const baseUrl: string = 'api/admin/v1/secure/smartMetersActions';

// ------------------------------------
// SMART_METER_ACTIONS Actions
// ------------------------------------
export const fetchSmartMeterActionsAction: any = createAction(
  'meters/FETCH_SMART_METER_ACTIONS',
  async (
    {
      skipPagination,
      skipStoreUpdate,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Meters.Root, 'smartMeterActionsCount' | 'smartMeterActionsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.SMART_METER_ACTIONS)(
        state
      );
      const lang = appLangSelector(state).toLowerCase();
      return axios
        .get(baseUrl, {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang,
          },
        })
        .then((res: any) => ({
          smartMeterActionsCount: res.data.count,
          smartMeterActionsHash: _keyBy(res.data.rows, (item: Meters.SmartMeterAction) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const fetchSmartMeterActionAction: any = createAction('meters/FETCH_SMART_METER_ACTION', async (id: number) => {
  return (): Promise<Pick<Meters.Root, 'smartMeterAction'>> =>
    axios.get(`${baseUrl}/action/${id}`).then(res => ({ smartMeterAction: res.data }));
});

export const fetchSmartMeterChecklistAction: any = createAction(
  'meters/FETCH_SMART_METER_CHECKLIST',
  async (id: number) => {
    return (): Promise<Meters.ChecklistHash> =>
      axios.get(`${baseUrl}/action/${id}/checklist`).then(res => _keyBy(res.data, 'id'));
  }
);

export const updateSmartMeterChecklistAction: any = createAction(
  'meters/UPDATE_SMART_METER_CHECKLIST',
  (id: number, checklist: Meters.Checklist) => {
    return (): Promise<void> => axios.put(`${baseUrl}/action/${id}/checklist`, checklist);
  }
);

export const fetchSmartMeterPhotosAction: any = createAction('meters/FETCH_SMART_METER_PHOTOS', async (id: string) => {
  return (): Promise<Type.GalleryImage[]> =>
    axios.get(`${baseUrl}/attachments/${id}`).then(res => getGalleryImages(res.data));
});

export const saveSmartMeterCommentAction: any = createAction(
  'meters/SAVE_SMART_METER_COMMENT',
  async (id: number, comment: string) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.patch(`${baseUrl}/action/${id}`, { comment }).then(async () => {
        await dispatch(fetchSmartMeterActionAction(id));
        dispatch(setSuccessToastAction('Smart meter action comment has been saved'));
      });
  }
);

export const deleteSmartMeterPhotosAction: any = createAction(
  'meters/DELETE_SMART_METER_PHOTOS',
  async (id: number, ids: number[]) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.delete(`${baseUrl}/attachments/${id}?ids=${ids.join(',')}`).then(() => {
        dispatch(setSuccessToastAction('Selected photos has been deleted'));
      });
  }
);

// ------------------------------------
// METERS_POPULATION Actions
// ------------------------------------
export const fetchMetersPopulationAction: any = createAction(
  'meters/FETCH_METERS_POPULATION',
  async (
    {
      skipPagination,
      skipStoreUpdate,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Meters.Root, 'populationCount' | 'populationHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.METERS_POPULATION)(state);
      return AssetLifeAPI.get('meters_mp/meters_population', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
        },
      }).then((res: any) => ({
        populationCount: res.data.count,
        populationHash: _keyBy(res.data.rows, (item: Meters.Population) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);
