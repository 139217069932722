import React from 'react';
import { Matrix } from 'components/_charts';
import { ContentContainer } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageMonetizedRisk: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isMonetizedRiskEnabled}>
    <ContentContainer className="border-top-0">
      <Matrix apiUrl="intervention/risk_matrix_intervention" />
    </ContentContainer>
  </PagePermissionsProvider>
);

export default PageMonetizedRisk;
