import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { deleteDERAction, fetchDERsAction } from 'modules/newLoad';
import { DERsCountSelector, DERsSplitBySectionHashSelector } from 'modules/newLoad/selectors';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType, TableHeaders } from 'constants/index';
import { IconEdit, IconDelete, IconDetails } from '@utiligize/shared/resources';
import ModalProfile from './ModalProfile';

const TableManageDERs: React.FC<{ year: number }> = ({ year }) => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(DERsCountSelector);
  const DERsSplitBySectionHash = useSelector(DERsSplitBySectionHashSelector);
  const keys = Object.keys(DERsSplitBySectionHash);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchDERsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setLayoutAction({ DERModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      const description: string | null = event.currentTarget.getAttribute('data-description');
      dispatch(
        setModalConfirmationMetaAction({
          title: getIntl('Do you want to delete {{description}}?', { description: description || '' }),
          onConfirm: () => dispatch(deleteDERAction(Number(id))),
        })
      );
    },
    [dispatch, getIntl]
  );

  const handleViewProfileButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ newLoadDERProfileModalId: id }));
    },
    [dispatch]
  );

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[PaginationType.NEW_LOAD_DERS].map(header => {
        if (header.titleKey !== 'Population') return header;
        return {
          ...header,
          title: `${getIntl(header.titleKey)} (${year})`,
        };
      }),
    ],
    [getIntl, year]
  );

  return (
    <>
      <DataTable
        paginationType={PaginationType.NEW_LOAD_DERS}
        totalAmount={count}
        sendRequest={sendRequest}
        customHeaders={customHeaders}
        waitForDependencies={!portfolioId || !scenarioId}
        triggerTableUpdateDeps={[portfolioId, scenarioId]}
        maxHeight="calc(100vh - 207px)"
      >
        {keys?.map((key: string) => {
          const DERs: NewLoad.DER[] = DERsSplitBySectionHash[key];
          return (
            <React.Fragment key={key}>
              <tr>
                <td colSpan={7}>
                  <b>
                    {getIntl('Section')} - {key}
                  </b>
                </td>
              </tr>
              {DERs.map((item: NewLoad.DER) => (
                <tr key={item.id}>
                  <td>{item.description || '-'}</td>
                  <td>{item.category || '-'}</td>
                  <td>{item.n_ders || '-'}</td>
                  <td>{numberFormat(item.ders_in_baseload, { fallback: '-' })}</td>
                  <td>{item.max_per_installation || '-'}</td>
                  <td>{item.grid_zones || '-'}</td>
                  <td className="text-right">
                    {(item.der_profile_info_id || item.der_phase_in_id) && (
                      <Button
                        tooltipKey="Profile"
                        icon={<IconDetails />}
                        data-id={item.id}
                        onClick={handleViewProfileButtonClick}
                        size="small"
                        variant="primary"
                      />
                    )}
                    <Button
                      marginLeft
                      tooltipKey="Edit"
                      icon={<IconEdit />}
                      data-id={item.id}
                      onClick={handleEditBtnClick}
                      size="small"
                      variant="primary"
                    />
                    <Button
                      marginLeft
                      tooltipKey="Delete"
                      icon={<IconDelete />}
                      data-id={item.id}
                      data-description={item.description}
                      onClick={handleDeleteBtnClick}
                      size="small"
                      variant="primary"
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </DataTable>
      <ModalProfile />
    </>
  );
};

export default TableManageDERs;
