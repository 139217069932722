import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.dataQuality;

export const issuesCountSelector = createSelector(
  rootSelector,
  (dataQuality: DataQuality.Root): DataQuality.Root['issuesCount'] => dataQuality.issuesCount
);

export const issuesSelector = createSelector(
  rootSelector,
  (dataQuality: DataQuality.Root): DataQuality.Root['issues'] => dataQuality.issues
);

export const issuesLastUpdateSelector = createSelector(
  rootSelector,
  (dataQuality: DataQuality.Root): DataQuality.Root['issuesLastUpdate'] => dataQuality.issuesLastUpdate
);

export const customersConnectedCountSelector = createSelector(
  rootSelector,
  (dataQuality: DataQuality.Root): DataQuality.Root['customersConnectedCount'] => dataQuality.customersConnectedCount
);

export const customersConnectedSelector = createSelector(
  rootSelector,
  (dataQuality: DataQuality.Root): DataQuality.Root['customersConnected'] => dataQuality.customersConnected
);

// ------------------------------------
// METERS
// ------------------------------------
export const metersMissingInMDMSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): DataQuality.Root['missingInMDM'] => meters.missingInMDM
);

export const metersMissingInMDMCountSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): DataQuality.Root['missingInMDMCount'] => meters.missingInMDMCount
);

export const metersMissingInGISSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): DataQuality.Root['missingInGIS'] => meters.missingInGIS
);

export const metersMissingInGISCountSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): DataQuality.Root['missingInGISCount'] => meters.missingInGISCount
);

export const missingAgeItemsCountSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): number => meters.missingAgeItemsCount
);

export const missingAgeItemsSelector = createSelector(
  rootSelector,
  (meters: DataQuality.Root): DataQuality.Root['missingAgeItems'] => meters.missingAgeItems
);
