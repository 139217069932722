import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from 'context/map';
import { settingsSelector, mapLayersSelector } from 'modules/map/selectors';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import FilterVoltages from './FilterVoltages';
import FilterPrimarySubstations from './FilterPrimarySubstations';
import FilterAssets from './FilterAssets';
import GroupContainer from './GroupContainer';
import GroupTitle from './GroupTitle';
import GroupSection from './GroupSection';

const GroupAssetsFilters: React.FC = () => {
  const { map } = useContext(MapContext);
  const settings = useSelector(settingsSelector);
  const mapLayers = useSelector(mapLayersSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  if (!map || !mapLayers) return null;
  return (
    <GroupContainer>
      <GroupTitle titleKey="Assets filters" />
      <GroupSection>
        <FilterVoltages map={map} singleDiagramEnabled={singleDiagramEnabled} />
      </GroupSection>
      <GroupSection>
        <FilterPrimarySubstations alwaysOpen={singleDiagramEnabled} />
      </GroupSection>
      {settings.hasAssets && !singleDiagramEnabled && (
        <GroupSection>
          <FilterAssets map={map} layers={mapLayers} />
        </GroupSection>
      )}
    </GroupContainer>
  );
};

export default GroupAssetsFilters;
