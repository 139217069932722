import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useLocale } from 'hooks';
import { mobileSubmittedTasksCountSelector, mobileSubmittedTasksSelector } from 'modules/tasks/selectors';
import { fetchTasksAction, deleteTaskAction } from 'modules/tasks';
import { setLayoutAction } from 'modules/layouts';
import { DataTable, TaskStatusBadge, TaskTypeBadge, Button, IconDeleteTableAction } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';
import { IconEdit } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksMobileSubmittedTable: React.FC<Props> = ({ maxTableHeight }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(mobileSubmittedTasksCountSelector);
  const items = useSelector(mobileSubmittedTasksSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksAction({ ...params, includeExternalTime: false, includeExpectedTimeFields: true })).then(
        (action: Shared.ReduxAction<{ mobileSubmittedTasksHash: Type.Hash<Tasks.Task> }>) =>
          Object.values(action.payload?.mobileSubmittedTasksHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ taskModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(push(generatePath(Routes.Tasks, { id })));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.TASKS_MOBILE_SUBMITTED}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      hover
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Task) => (
        <tr key={item.id} data-id={item.id} onClick={handleDetailsBtnClick}>
          <td>
            <TaskTypeBadge id={item.id} type={item.type} className="mr-2" />
            {item.name}
          </td>
          <td>{item.createdAt ? dateFormat(item.createdAt) : '-'}</td>
          <td className="text-nowrap">{item.createdBy?.fullName || '-'}</td>
          <td>{item.departmentName || '-'}</td>
          <td>{item.assetCodes.length ? item.assetCodes.join(', ') : '-'}</td>
          <td>{item.appAction ? getIntl(item.appAction) : '-'}</td>
          <td className="text-center">
            <TaskStatusBadge status={item.status} />
          </td>
          <td className="text-right">
            <Button
              marginLeft
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <IconDeleteTableAction id={item.id} name={item.name || ''} marginLeft action={deleteTaskAction} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TasksMobileSubmittedTable;
