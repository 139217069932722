import * as d3 from 'd3';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import DiagramDefs from './DiagramDefs';
import { useResizeObserver, setupGraph, setupScales, drawGraph, removeHighlight, transformChart } from './utils';
import { Routes, SingleLineDiagramTypes } from 'constants/index';
import { DefaultCircleRadius, ScaleExponentValue } from './constants';

interface Props {
  data: SingleLineDiagram.ResponseData;
  type: SingleLineDiagram.Types;
}

const Diagram: React.FC<Props> = ({ data, type }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const svgRef = useRef<SVGSVGElement | null>(null);
  const dimensions = useResizeObserver(svgRef);
  const zoom = useMemo(() => d3.zoom<any, unknown>().scaleExtent([0.9, 12]), []);
  const uuid = useSelector(mapPanelSelectedAssetUUIDSelector);

  const zoomToCoordinates = useCallback(
    (x: number, y: number, zoomLevel: number = 2.5) => {
      const svg = d3.select(svgRef.current);
      const [width, height] = [Number(svg.attr('width')), Number(svg.attr('height'))];
      const offset = 25;
      const transform = d3.zoomIdentity
        .translate(width / 2 - (x + offset) * zoomLevel, height / 2 - (y + offset) * zoomLevel)
        .scale(zoomLevel);
      svg.transition().duration(1000).call(zoom.transform, transform);
    },
    [zoom.transform]
  );

  useEffect(() => {
    removeHighlight();

    // Select and filter circles, then add class to their parent nodes
    d3.selectAll<SVGCircleElement, SingleLineDiagram.Vertice>('.circle')
      .filter((d: SingleLineDiagram.Vertice) => d.customer_asset_id === uuid)
      .each(function (this: SVGCircleElement, d: SingleLineDiagram.Vertice) {
        d3.select<SVGElement, unknown>(this.parentNode as SVGGraphicsElement).classed('selected-node', true);
        const x = Number((this.parentNode as any).getAttribute('cx'));
        const y = Number((this.parentNode as any).getAttribute('cy'));
        zoomToCoordinates(x, y);
      });

    // Select and filter links, then add class to them
    d3.selectAll<SVGCircleElement, SingleLineDiagram.Edge>('.link')
      .filter((d: SingleLineDiagram.Edge) => d.customer_asset_id === uuid)
      .each(function (this: SVGCircleElement, d: SingleLineDiagram.Edge) {
        d3.select(this).classed('selected-link', true);
        if (!d.source || !d.target || type !== SingleLineDiagramTypes.Tree) return;
        const svg = d3.select(svgRef.current);
        zoomToCoordinates(
          (d.source.V1 + d.target.V1) / 2,
          Number(svg.attr('height')) - (d.source.V2 + d.target.V2) / 2
        );
      });
  }, [uuid, zoomToCoordinates, type]);

  const handleGraphClick = useCallback(
    (uuid: string) => {
      if (!uuid) return dispatch(push(generatePath(Routes.Map)));
      dispatch(push(generatePath(Routes.Map, { uuid }), { keepMapState: true }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!svgRef.current || !dimensions) return;

    // Set the dimensions and margins of the graph
    const { width, height } = dimensions;
    const delta = 3.5;

    // Append the svg object to the chart div
    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

    // Append groups for nodes and links
    const linksGroup = svg.select('.links-group');
    const nodesGroup = svg.select('.nodes-group');

    // Prepare graph data
    const graph = setupGraph({ data, type, width, height });

    // Prepare scales
    const { xScale, yScale } = setupScales({ vertices: data.vertices, width, height, invert: false });
    let currentXScale = xScale;
    let currentYScale = yScale;

    // Draw the nodes, links and markers
    const { nodes, nodeCircles, nodeLabels, links } = drawGraph(
      graph,
      linksGroup,
      nodesGroup,
      delta,
      xScale,
      yScale,
      handleGraphClick
    );

    let scale = 1;
    const getPower = () => Math.pow(scale, ScaleExponentValue);

    // Dragging
    nodes.call(
      d3.drag().on('drag', function (this: any, event: any, d: any) {
        d.V1 += event.dx / scale;
        d.V2 -= event.dy / scale;
        transformChart(d3.select(this), links, currentXScale, currentYScale, delta * getPower());
      })
    );

    const nodeLabelsLowVoltage = nodeLabels.filter(function (this: SVGElement) {
      return d3.select(this).classed('voltage-low');
    });

    // Configure zoom
    const zoomed = (event: any) => {
      // update scales
      currentXScale = event.transform.rescaleX(xScale);
      currentYScale = event.transform.rescaleY(yScale);

      // update node positions
      scale = event.transform.k;
      const power = getPower();
      transformChart(nodes, links, currentXScale, currentYScale, delta * power);
      links.style('stroke-width', power);
      nodeCircles.attr('r', DefaultCircleRadius * power);
      nodeLabels.attr('transform', `scale(${power})`);
      nodeLabelsLowVoltage.style('opacity', Number(scale > 2));
    };
    zoom.on('zoom', zoomed);
    svg.call(zoom);
  }, [data, type, dimensions, zoom, handleGraphClick]);

  return (
    <div className="single-line-chart" style={{ height: '100%', padding: '40px 50px' }}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%', overflow: 'visible' }}>
        <DiagramDefs />
        <g className="links-group" />
        <g className="nodes-group" />
      </svg>
    </div>
  );
};

export default Diagram;
