import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from 'modules/app';
import formsReducer from 'modules/forms';
import manualsReducer from 'modules/manuals';
import layoutsReducer from 'modules/layouts';
import answersReducer from 'modules/answers';
import historyReducer from 'modules/history';
import instructionsReducer from 'modules/instructions';
import tasksReducer from 'modules/tasks';
import assetModelsReducer from 'modules/assetModels';
import inspectionsReducer from 'modules/inspections';
import usersReducer from 'modules/users';
import manufacturersReducer from 'modules/manufacturers';
import metersReducer from 'modules/meters';
import mapReducer from 'modules/map';
import assetsReducer from 'modules/assets';
import employeesReducer from 'modules/employees';
import optionsReducer from 'modules/options';
import networkLoadingReducer from 'modules/networkLoading';
import customersReducer from 'modules/customers';
import investmentReducer from 'modules/investment';
import maintenanceReducer from 'modules/maintenance';
import statisticsReducer from 'modules/statistics';
import permissionsReducer from 'modules/permissions';
import newLoadReducer from 'modules/newLoad';
import setupReducer from 'modules/setup';
import n1Reducer from 'modules/n1';
import dataQualityReducer from 'modules/dataQuality';
import co2eReducer from 'modules/co2e';

const createRootReducer = (history: Type.History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    forms: formsReducer,
    manuals: manualsReducer,
    layouts: layoutsReducer,
    answers: answersReducer,
    instructions: instructionsReducer,
    tasks: tasksReducer,
    assetModels: assetModelsReducer,
    inspections: inspectionsReducer,
    users: usersReducer,
    history: historyReducer,
    manufacturers: manufacturersReducer,
    meters: metersReducer,
    map: mapReducer,
    assets: assetsReducer,
    employees: employeesReducer,
    options: optionsReducer,
    networkLoading: networkLoadingReducer,
    customers: customersReducer,
    investment: investmentReducer,
    maintenance: maintenanceReducer,
    statistics: statisticsReducer,
    permissions: permissionsReducer,
    newLoad: newLoadReducer,
    setup: setupReducer,
    n1: n1Reducer,
    dataQuality: dataQualityReducer,
    co2e: co2eReducer,
  });

export default createRootReducer;
