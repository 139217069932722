import { combineActions, handleActions } from 'redux-actions';
import {
  fetchIssuesAction,
  setIssueIgnoredAction,
  fetchCustomersConnectedAction,
  fetchMetersMissingInMDMAction,
  fetchMetersMissingInGISAction,
  fetchMissingAgeAction,
  getAgeDistributionChartData,
} from './actions';

const initialState: DataQuality.Root = {
  issues: [],
  issuesCount: 0,
  issuesLastUpdate: null,
  customersConnectedCount: 0,
  customersConnected: [],
  missingInMDMCount: 0,
  missingInMDM: [],
  missingInGISCount: 0,
  missingInGIS: [],
  missingAgeItems: [],
  missingAgeItemsCount: 0,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchIssuesAction,
    fetchCustomersConnectedAction,
    fetchMetersMissingInMDMAction,
    fetchMetersMissingInGISAction,
    fetchMissingAgeAction
  ).toString() as any]: {
    next: (
      state: DataQuality.Root,
      action: Shared.ReduxAction<Partial<DataQuality.Root> & { skipStoreUpdate: true | undefined }>
    ): DataQuality.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchIssuesAction,
  setIssueIgnoredAction,
  fetchCustomersConnectedAction,
  fetchMetersMissingInMDMAction,
  fetchMetersMissingInGISAction,
  fetchMissingAgeAction,
  getAgeDistributionChartData,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
