import React, { useState, useCallback, useEffect, memo } from 'react';
import { components, OptionProps } from 'react-select';
import { useDispatch } from 'react-redux';
import { useToggle } from 'hooks';
import { deleteSubComponentAction } from 'modules/assets';
import { Button } from 'components/_common';
import { IconClose, IconDelete } from '@utiligize/shared/resources';
import './styles.scss';

interface Props extends OptionProps<{ value: number; label: string; __isNew__?: boolean; synthetic?: boolean }, false> {
  readOnly: boolean;
  assetCategoryCode: Type.AssetCategories | null;
}

const MenuOption: React.FC<Props> = memo(props => {
  const { data, readOnly = false, assetCategoryCode } = props;
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, toggleShowConfirmation] = useToggle();

  useEffect(() => {
    if (showConfirmation && !props.isFocused) {
      toggleShowConfirmation();
    }
  }, [showConfirmation, props.isFocused, toggleShowConfirmation]);

  const handleDeleteButtonClick = useCallback(
    async (event: React.SyntheticEvent): Promise<void> => {
      setLoading(true);
      event.preventDefault();
      event.stopPropagation();
      dispatch(deleteSubComponentAction(data.value, assetCategoryCode!)).catch(() => setLoading(false));
    },
    [dispatch, data.value, assetCategoryCode]
  );

  return (
    <components.Option {...props}>
      <span className="w-100 d-flex justify-content-between align-items-center">
        {data.label}
        {!data.__isNew__ && !readOnly && !data.synthetic && (
          <span>
            <Button
              icon={showConfirmation ? <IconClose /> : <IconDelete />}
              size="small"
              onClick={toggleShowConfirmation}
            />
            {showConfirmation && (
              <Button
                labelKey="Delete"
                variant="danger"
                size="small"
                onClick={handleDeleteButtonClick}
                className="ml-2"
                loading={loading}
              />
            )}
          </span>
        )}
      </span>
    </components.Option>
  );
});

export default MenuOption;
