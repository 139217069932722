import { mapColorGradient, paintLayer, setMapSourceField } from 'utils/map';

const getHeatmapColor = (filter: Map.LayerFilter, settings: Map.Settings, theme?: Map.ColorTheme) => {
  const t: Map.ColorTheme = theme ?? 'Responsive';
  const maxValue = settings.heatmapMaxValues?.[filter.list[0]] ?? 100;
  const colors = mapColorGradient(t, 'default', 0, maxValue || 1);
  return colors;
};

export const updateHeatmapLayer = (
  map: Map.MapboxMap,
  heatmapLayerId: string,
  scenario: Map.Scenario,
  filter: Map.LayerFilter,
  settings: Map.Settings,
  theme?: Map.ColorTheme
) => {
  const prop = `v_${scenario.key}`;
  paintLayer(map, heatmapLayerId, 'fill-color', [
    'case',
    ['==', ['typeof', ['get', prop]], 'string'],
    ['interpolate', ['linear'], ['to-number', ['get', prop]], ...getHeatmapColor(filter, settings, theme)],
    '#918f86',
  ]);
};

export const updateHeatmapSource = (map: Map.MapboxMap, heatmapLayerId: string, scenario?: Map.Scenario) => {
  if (!scenario) return;
  setMapSourceField(map, [heatmapLayerId], { scenario_id: String(scenario.id) });
};
