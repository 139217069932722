import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import {
  detailedPlanCountSelector,
  detailedPlanMetaCurrencySelector,
  detailedPlanItemsSelector,
} from 'modules/investment/selectors';
import { fetchDetailedPlanAction } from 'modules/investment';
import { DataTable } from 'components/_common';
import { getStorageItem, setStorageItem } from 'utils';
import {
  PaginationType,
  TableHeaders,
  Routes,
  StorageKeys,
  TableHeadersKeys,
  MapThemes,
  MapThemeGroups,
} from 'constants/index';

const TableDetailedInvestments: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, numberFormat } = useLocale();
  const type = PaginationType.DETAILED_INVESTMENTS;
  const Keys = TableHeadersKeys[type]!;
  const { columns }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(detailedPlanItemsSelector);
  const count = useSelector(detailedPlanCountSelector);
  const currency = useSelector(detailedPlanMetaCurrencySelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchDetailedPlanAction(params)).then(
        (action: Shared.ReduxAction<{ detailedPlanItems: Investment.Root['detailedPlanItems'] }>) =>
          action.payload?.detailedPlanItems || []
      ),
    [dispatch]
  );

  const handleMapLinkClick = useCallback(() => {
    setStorageItem({
      [StorageKeys.MAP_CACHE]: {
        ...(getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || {}),
        theme: MapThemes.YEARLY_REPLACEMENTS,
        themeGroup: MapThemeGroups.REINVESTMENT,
      },
    });
  }, []);

  const customHeaders = useMemo(() => {
    const isCurrencyExist = (key: string) => (key || '').includes('{{currency}}');
    return [
      ...TableHeaders[type].map(header => ({
        ...header,
        ...(isCurrencyExist(header.titleKey!) ? { title: getIntl(header.titleKey!, { currency }) } : {}),
        subTitles: header.subTitles?.map(i => ({
          ...i,
          ...(isCurrencyExist(i.titleKey!) ? { title: getIntl(i.titleKey!, { currency }) } : {}),
        })),
      })),
    ];
  }, [type, currency, getIntl]);

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      customHeaders={customHeaders}
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      maxHeight="calc(100vh - 342px)"
      isResetFiltersButtonEnabled
      isSettingsButtonEnabled
    >
      {items?.map?.((item: Investment.DetailedInvestmentsItem) => (
        <tr key={item.asset_name}>
          {columns?.includes(Keys.asset_name) && (
            <td>
              <Link
                onClick={handleMapLinkClick}
                to={{
                  pathname: generatePath(Routes.Map, { uuid: item.id }),
                  state: {
                    keepMapTheme: true,
                    year: item.suggested_replacement_year,
                    theme: MapThemes.YEARLY_REPLACEMENTS,
                  },
                }}
              >
                {item.asset_name}
              </Link>
            </td>
          )}
          {columns?.includes(Keys.asset_category) && <td>{item.asset_category || '-'}</td>}
          {columns?.includes(Keys.installation_year) && <td>{item.installation_year || '-'}</td>}
          {columns?.includes(Keys.current_type) && <td>{item.current_type || '-'}</td>}
          {columns?.includes(Keys.future_type) && <td>{item.future_type || '-'}</td>}
          {columns?.includes(Keys.current_capacity) && <td>{item.current_capacity || '-'}</td>}
          {columns?.includes(Keys.future_capacity) && <td>{item.future_capacity || '-'}</td>}
          {columns?.includes(Keys.replacement_reason) && (
            <td>{item.replacement_reason ? getIntl(item.replacement_reason) : '-'}</td>
          )}
          {columns?.includes(Keys.replacement_cost) && (
            <td className="text-right">
              {numberFormat(item.replacement_cost, { minimumFractionDigits: 2, fallback: '-' })}
            </td>
          )}
          {columns?.includes(Keys.suggested_replacement_year) && <td>{item.suggested_replacement_year || '-'}</td>}
          {columns?.includes(Keys.latest_replacement_year) && <td>{item.latest_replacement_year || '-'}</td>}
          {columns?.includes(Keys.savings_over_remaining_lifetime_current_asset) && (
            <td>
              {numberFormat(item.savings_over_remaining_lifetime_current_asset, {
                minimumFractionDigits: 2,
                fallback: '-',
              })}
            </td>
          )}
          {columns?.includes(Keys.primary_substation_name) && <td>{item.primary_substation_name || '-'}</td>}
          {columns?.includes(Keys.grid_zone) && <td>{item.grid_zone || '-'}</td>}
          {columns?.includes(Keys.voltage_level) && <td>{item.voltage_level || '-'}</td>}
          {columns?.includes(Keys.department) && <td>{item.department || '-'}</td>}
          {columns?.includes(Keys.co2e_t_per_year) && <td>{numberFormat(item.co2e_t_per_year, { fallback: '-' })}</td>}
        </tr>
      ))}
    </DataTable>
  );
};

export default TableDetailedInvestments;
