import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchComponentPriceScenarioOptionsAction } from 'modules/options';
import { componentPriceScenarioOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { portfolioIdSelector } from 'modules/layouts/selectors';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectComponentPriceScenario: React.FC<Props> = ({
  value,
  labelKey = 'Component price scenario',
  mutedTextLabelKey = 'Component price scenario',
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const options = useSelector(componentPriceScenarioOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  useEffect(() => {
    if (!portfolioId || options) return;
    setLoading(true);
    dispatch(fetchComponentPriceScenarioOptionsAction(portfolioId)).finally(() => setLoading(false));
  }, [dispatch, portfolioId, options]);

  const selectedOption = useMemo(() => options?.find(i => i.value === value) || null, [options, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      mutedTextLabelKey={mutedTextLabelKey}
      value={selectedOption}
      options={options || []}
      isLoading={loading}
      isDisabled={!options?.length}
      isSearchable={false}
    />
  );
};

export default SelectComponentPriceScenario;
