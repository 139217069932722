import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapCheckedFiltersSelectorFactory, mapLoadingSelector } from 'modules/map/selectors';
import {
  mapFilterPrimarySubstationsIdSelector,
  portfolioIdSelector,
  singleDiagramTypeSelector,
} from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { fetchSingleLineDiagramAction } from 'modules/map';
import Diagram from './Diagram';
import DiagramLegend from './DiagramLegend';
import { ErrorBoundary, Spinner } from 'components/_common';
import { ThemeContext } from './contexts/theme-context';
import { IconFile } from '@utiligize/shared/resources';
import { VoltageColorsMap } from 'constants/index';

const DiagramContainer: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const mapLoading = useSelector(mapLoadingSelector);
  const [data, setData] = useState<SingleLineDiagram.ResponseData | null>(null);

  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const portfolioId = useSelector(portfolioIdSelector);
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);
  const mapFilterPrimarySubstationsId = useSelector(mapFilterPrimarySubstationsIdSelector);
  const mapFilterVoltageCheckedFilters = useSelector(mapCheckedFiltersSelectorFactory('filter_voltage'));
  const mapFilterVoltageCheckedFiltersIds = mapFilterVoltageCheckedFilters?.map(i => i.id).join(',');
  const singleDiagramType = useSelector(singleDiagramTypeSelector);

  const getDefaultTheme = (): string => {
    const localStorageTheme = localStorage.getItem('default-theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    return localStorageTheme || browserDefault;
  };

  const [theme, setTheme] = useState(getDefaultTheme());

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };

  useEffect(() => {
    if (!portfolioId || !mapFilterPrimarySubstationsId || !simulationVersionId || !mapFilterVoltageCheckedFiltersIds) {
      return;
    }
    setData(null);
    dispatch(
      fetchSingleLineDiagramAction({
        type: singleDiagramType,
        portfolioId,
        primary_substation_id: mapFilterPrimarySubstationsId!,
        simulationVersionId: simulationVersionId!,
        mapFilterVoltageCheckedFiltersIds: mapFilterVoltageCheckedFiltersIds!,
      })
    )
      .then(action => setData(action.payload))
      .catch(console.error);
  }, [
    dispatch,
    singleDiagramType,
    portfolioId,
    mapFilterPrimarySubstationsId,
    mapFilterVoltageCheckedFiltersIds,
    simulationVersionId,
  ]);

  const renderContent = () => {
    if (mapLoading || data === null) return <Spinner isInFullHeightContainer />;
    if (!data?.vertices) {
      return (
        <StyledAlertContainer>
          <IconFile />
          <small>{getIntl('No data to display')}</small>
        </StyledAlertContainer>
      );
    }

    return (
      <ErrorBoundary>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <StyledSingleLineDiagram className={`theme-${theme}`}>
            <div className="layout-wrapper">
              <div className="toggle-btn-section">
                <div className="toggle-checkbox">
                  <small>{getIntl('Light theme')}</small>
                  <input
                    className="toggle-btn__input"
                    type="checkbox"
                    name="checkbox"
                    onChange={handleThemeChange}
                    checked={theme === 'dark'}
                  />
                  <button type="button" className="toggle-btn__input-label" onClick={handleThemeChange}></button>
                  <small>{getIntl('Dark theme')}</small>
                </div>
              </div>

              <DiagramLegend />
              {Boolean(data) && <Diagram data={data!} type={singleDiagramType} />}
            </div>
          </StyledSingleLineDiagram>
        </ThemeContext.Provider>
      </ErrorBoundary>
    );
  };

  return <StyledSection>{renderContent()}</StyledSection>;
};

const StyledSection = styled.section`
  position: relative;
  height: calc(100vh - ${props => props.theme.heights.topNavigation});
`;

const StyledAlertContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    fill: ${props => props.theme.colors.grey300};
  }

  > small {
    color: ${props => props.theme.colors.grey300};
  }
`;

const StyledSingleLineDiagram = styled.div`
  height: 100%;
  /* Layout wrapper */
  .layout-wrapper {
    height: 100%;
    /* Toggle mode button */
    .toggle-btn-section {
      margin-left: 17px;
      margin-top: 5px;
      position: absolute;
      z-index: 1;

      .toggle-checkbox {
        justify-content: center;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .toggle-btn__input {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        .toggle-btn__input-label {
          cursor: pointer;
          width: 46px;
          height: 20px;
          display: block;
          border-radius: 23px;
          position: relative;
          border: none;
          margin: 0 5px;

          &::after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            transition: 0.3s;
            box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
            background-color: #fff;
          }
        }

        .toggle-btn__input:checked ~ .toggle-btn__input-label {
          &:after {
            background: #fff;
            left: calc(100% - 3px);
            transform: translateX(-100%);
          }
        }

        .toggle-btn__input:checked ~ .toggle-btn__theme {
          &:after {
            // theme switch circle background in light theme
            background: #fff;
            left: calc(100% - 3px);
            transform: translateX(-100%);
          }
        }
      }

      .toggle-btn__input:checked ~ .toggle-btn__input-label {
        background-color: #82deff;
      }
    }

    /* Buttons and legend */
    .single-line-legend-wrapper {
      position: absolute;
      top: 35px;
      left: 10px;
      z-index: 1000;
      border-radius: 8px;
      padding: 10px;
      font-size: 12px;
      box-shadow: 0px 2px 2px #44366630;
    }

    /* Single line diagram / Legend */
    .single-line-chart,
    .single-line-legend {
      /* Chart */
      svg {
        /* Nodes */
        .nodes-group {
          .node {
            cursor: pointer;
          }

          .selected-node {
            circle {
              stroke: #ff0000;
              stroke-width: 10px;
              stroke-opacity: 0.3;
            }
          }

          .voltages-used-1 {
            fill: ${VoltageColorsMap[1]};
          }
          .voltages-used-2 {
            fill: ${VoltageColorsMap[2]};
          }
          .voltages-used-3 {
            fill: ${VoltageColorsMap[3]};
          }
          .voltages-used-4 {
            fill: ${VoltageColorsMap[4]};
          }
        }

        /* Links */
        .links-group {
          .link {
            cursor: pointer;
          }

          .voltages-used-1 {
            stroke: ${VoltageColorsMap[1]};
          }
          .voltages-used-2 {
            stroke: ${VoltageColorsMap[2]};
          }
          .voltages-used-3 {
            stroke: ${VoltageColorsMap[3]};
          }
          .voltages-used-4 {
            stroke: ${VoltageColorsMap[4]};
          }

          #switch-closed circle,
          #switch-open circle {
            fill: #7f7f7f;
          }

          .selected-link {
            stroke-width: 5px !important;
            stroke-opacity: 0.3;
          }
        }

        .node-label.legend {
          font-size: 11px;
          stroke-linejoin: round;
          paint-order: stroke;
        }
      }
    }
  }
`;

export default DiagramContainer;
