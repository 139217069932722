import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, tableSettingsModalSelector } from 'modules/layouts/selectors';
import { setLayoutAction, setPaginationAction } from 'modules/layouts';
import { Modal, Checkbox } from 'components/_common';
import { TableHeaders } from 'constants/index';

const TableSettingsModal: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { type, customHeaders } = useSelector(tableSettingsModalSelector) || {};
  const { columns }: Layouts.Pagination = useSelector(paginationSelectorFactory(type!)) || {};

  useEffect(() => {
    if (type) setShow(true);
  }, [type]);

  const { firstColumnItems, secondColumnItems } = useMemo(() => {
    const items =
      (customHeaders || TableHeaders[type!])
        ?.flatMap(header =>
          header.subTitles ? header.subTitles.map(i => ({ ...i, parentTitleKey: header.titleKey })) : header
        )
        .filter(i => i.key !== 'actions') || [];
    const midIndex = Math.ceil(items.length / 2);
    const firstColumnItems = items.slice(0, midIndex);
    const secondColumnItems = items.slice(midIndex);
    return { firstColumnItems, secondColumnItems };
  }, [customHeaders, type]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ tableSettingsModal: null })), 500);
  }, [dispatch]);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!columns) return;
      const key = event.currentTarget.name;
      const nextColumns = event.currentTarget.checked ? [...columns, key] : columns.filter(i => i !== key);
      dispatch(setPaginationAction({ type, modifier: { columns: nextColumns } }));
    },
    [type, dispatch, columns]
  );

  const renderFormGroupItem = (item: Type.DataTableHeader & { parentTitleKey?: string }) => {
    const parentTitle = item.parentTitleKey ? `${getIntl(item.parentTitleKey)} - ` : '';
    const title = (item.title as string) || getIntl(item.titleKey!);
    return (
      <Form.Group key={item.key}>
        <Checkbox
          labelKey={!item.parentTitleKey ? (item.title as string) || item.titleKey! : undefined}
          label={`${parentTitle}${title}`}
          name={item.key}
          className="icheck-primary"
          checked={columns?.includes(item.key!)}
          onChange={handleCheckboxClick}
          disabled={columns?.includes(item.key!) && columns?.length! <= 3}
        />
      </Form.Group>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      titleKey="Edit table columns"
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <Form.Row>
        <Col>{firstColumnItems.map(renderFormGroupItem)}</Col>
        <Col>{secondColumnItems.map(renderFormGroupItem)}</Col>
      </Form.Row>
    </Modal>
  );
};

export default TableSettingsModal;
