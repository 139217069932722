import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mapStateThemeGroupSelector, mapStateThemeSelector, settingsSelector } from 'modules/map/selectors';
import { mapStateAction } from 'modules/map';
import { _omit } from '@utiligize/shared/utils';
import { MapThemes } from 'constants/index';

const SetupWarnings: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const history = useHistory<{ warning: DataQuality.Issue['positiononthemap'] }>();
  const settings = useSelector(settingsSelector);
  const themeGroup = useSelector(mapStateThemeGroupSelector);
  const theme = useSelector(mapStateThemeSelector);

  useEffect(() => {
    if (!history.location.state?.warning || theme !== MapThemes.DATA_QUALITY) return;
    history.replace({ ..._omit(history.location, 'state') });
  }, [dispatch, theme, history]);

  useEffect(() => {
    if (!history.location.state?.warning) return;
    if (settings.isDataQualityThemeDisabled) return;
    dispatch(mapStateAction({ themeGroup: 'forecast_and_investment', theme: MapThemes.DATA_QUALITY }));
  }, [dispatch, themeGroup, history]); // eslint-disable-line

  return null;
};

export default React.memo(SetupWarnings);
