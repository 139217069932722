import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { taskHardwareCountSelector, taskHardwareSelector } from 'modules/tasks/selectors';
import { fetchTaskHardwareAction } from 'modules/tasks';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  taskId: number;
}

const TableHardware: React.FC<Props> = ({ taskId }) => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(taskHardwareCountSelector);
  const items = useSelector(taskHardwareSelector);

  const sendRequest = useCallback(() => dispatch(fetchTaskHardwareAction(taskId)), [taskId, dispatch]);

  return (
    <DataTable
      maxHeight="50vh"
      containerClassName="p-0 border-top-0"
      paginationType={PaginationType.TASK_HARDWARE}
      totalAmount={count}
      sendRequest={sendRequest}
    >
      {items?.map((item: Tasks.Hardware) => (
        <tr key={item.id}>
          <td>{item.date ? dateFormat(item.date) : '-'}</td>
          <td>{numberFormat(item.quantity, { fallback: '-' })}</td>
          <td className="text-right">
            {item.currency ? `${item.currency} ` : ''}
            {numberFormat(item.cost, { minimumFractionDigits: 2, fallback: '-' })}
          </td>
          <td>{item.type || '-'}</td>
          <td>{item.unit || '-'}</td>
          <td>{item.description || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableHardware;
