import axios, { Axios, AxiosError, AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAppErrorAction } from 'modules/app';
import { setErrorToastAction } from 'modules/layouts';
import { muteErrorToastActionSelector } from 'modules/layouts/selectors';
import { getStorageItem, setStorageItem } from 'utils';
import { plumberAPI, AssetLifeAPI, StorageKeys, AppErrorsTypes, isDevelopment } from 'constants/index';
import { UserService } from '@utiligize/shared/services';

const APIs = [axios, plumberAPI, AssetLifeAPI];

const APIresponseCodesMap: { [key: number]: string } = {
  60400001: "Comment field can't be empty!",
  60422007: "This task doesn't exist",
  60400014: "Responsible users can't be on the assigned users list",
  20400003: 'Asset by provided asset code not found',
  20400002: 'Invalid asset code',
  60400006: "Finished task can't be edited",
  60422015: 'Repeated tasks section with the same name already exists',
  60422012: 'Repeated task with the same task number already exists',

  10400010: 'Tenant is undefined.',
  10400011: 'Access is denied to the requested tenant.',
  10400012: 'Requested tenant is not supported.', // TenantNotFound
  10400013: 'No assigned tenants.', // NoAssignedTenants
  10400014: 'Email is not set.',
};

const useRequestId = () => {
  // State to store the requestId
  const [requestId, setRequestId] = useState(uuidv4());
  const history = useHistory();

  useEffect(() => {
    // Add event listener for location change
    const unListen = history.listen(() => setRequestId(uuidv4()));

    // Clean up the event listener
    return unListen;
  }, [history]);

  return requestId;
};

interface Props {
  children: React.ReactNode[];
}

const SetupAxios: React.FC<Props> = ({ children }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const muteErrorToastAction = useSelector(muteErrorToastActionSelector);
  const requestId = useRequestId();

  const handleResponseError = useCallback(
    (error: AxiosError<{ errorCode: number; error: any; errorMessage: string; detail?: { error: string } }>) => {
      const isErrorOnlyForModal = Boolean((error.config as any)?.isErrorOnlyForModal);
      // Handle request network errors
      if (error.code === 'ERR_NETWORK' && (!muteErrorToastAction || isErrorOnlyForModal)) {
        dispatch(setErrorToastAction({ message: `${error.message} (${error.config?.url})`, isErrorOnlyForModal }));
      }

      if (error.response?.request) {
        const { status } = error.response.request;

        if (status === 500 && error.config?.url === 'api/admin/v1/secure/permissions/users/current') {
          return dispatch(setAppErrorAction(AppErrorsTypes.FetchUserError));
        }

        if (status === 400 && error.response?.data?.errorCode === 10400012) {
          return dispatch(setAppErrorAction(AppErrorsTypes.TenantNotFound));
        }

        if (status === 400 && error.response?.data?.errorCode === 10400013) {
          return dispatch(setAppErrorAction(AppErrorsTypes.NoAssignedTenants));
        }

        const message =
          error.response?.data?.error?.errorMessage ||
          error.response?.data?.error ||
          error.response?.data?.detail?.error ||
          APIresponseCodesMap[error.response?.data?.errorCode as number] ||
          error.response?.data?.errorMessage ||
          error.message;

        if (!muteErrorToastAction || isErrorOnlyForModal) {
          dispatch(setErrorToastAction({ message, isErrorOnlyForModal }));
        }

        if (isDevelopment) {
          console.error('Interceptor: ', message);
        }
      }
      return Promise.reject(error);
    },
    [muteErrorToastAction, dispatch]
  );

  useEffect(() => {
    const [id, plumberId, assetLifeId] = APIs.map((i: Axios) => {
      i.interceptors.request.use((req: AxiosRequestConfig) => {
        if (req.headers) req.headers['X-Request-ID'] = requestId;

        if (UserService.isLoggedIn()) {
          return UserService.updateToken((refreshed: boolean) => {
            if (refreshed) processToken();
            return Promise.resolve(req);
          });
        }
      });
      return i.interceptors.response.use(response => response, handleResponseError);
    });

    return () => {
      axios.interceptors.request.eject(id);
      axios.interceptors.response.eject(id);
      plumberAPI.interceptors.request.eject(plumberId);
      plumberAPI.interceptors.response.eject(plumberId);
      AssetLifeAPI.interceptors.request.eject(assetLifeId);
      AssetLifeAPI.interceptors.response.eject(assetLifeId);
    };
  }, [handleResponseError, requestId]);

  useEffect(() => {
    if (!UserService.isLoggedIn()) return;
    if (UserService.getToken()) return processToken();

    APIs.forEach((i: Axios) => {
      delete i.defaults.headers.common.Authorization;
      delete i.defaults.headers.common.tenant;
    });
  }, []);

  return <>{children}</>;
};

export const processToken = () => {
  APIs.forEach((i: Axios) => {
    i.defaults.headers.common.Authorization = `Bearer ${UserService.getToken()}`;
  });

  const parsedAccessToken: any = UserService.getParsedToken();
  const tenants = parsedAccessToken?.tenants;
  setStorageItem({
    [StorageKeys.USER_CLAIMS]: {
      firstName: parsedAccessToken.given_name,
      lastName: parsedAccessToken.family_name,
      email: parsedAccessToken.email,
      tenants: parsedAccessToken.tenants,
    },
  });

  if (tenants?.length) {
    const selectedTenant = getStorageItem(StorageKeys.SELECTED_TENANT);
    const tenantExist = tenants.includes(selectedTenant);
    const nextTenant = (tenantExist && selectedTenant) || tenants?.[0] || '';
    if (selectedTenant !== nextTenant) setStorageItem({ [StorageKeys.SELECTED_TENANT]: nextTenant });
    APIs.forEach((i: Axios): void => (i.defaults.headers.common.tenant = nextTenant));
  }
};

export default SetupAxios;
