import { getLineStyle, voltageColors, hasAdditionalRegex } from 'components/Map/core/layers/assets';
import { MapParams } from 'constants/index';

const renderLine = (minZoom: number) => (legendItem: Map.LegendItem, settings: Map.Settings) => {
  const hasAdditional = hasAdditionalRegex.test(legendItem.name);
  return getLineStyle({
    legendItem,
    iconClass: 'legend-filter-line',
    color: voltageColors,
    settings,
    hasAdditional,
    minZoom,
  });
};

export const renderCables = renderLine(MapParams.minZoom);

export const renderOverheadLines = renderLine(MapParams.minZoom);
