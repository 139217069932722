import styled from 'styled-components';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction, setSelectedInvestmentScenarioAction } from 'modules/map';
import {
  settingsSelector,
  createMapStateSelectorFactory,
  investmentScenariosSelector,
  selectedInvestmentScenarioSelector,
} from 'modules/map/selectors';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import {
  CollapseGroup,
  Section,
  MultiSwitch,
  ScaleContainer,
  Loading,
  RangeSlider,
  ControlBox,
  Scale,
} from 'components/Map/common';
import ForecastInvestmentMaxLoadSummary from './ForecastInvestmentMaxLoadSummary';

const ForecastInvestmentMaxLoad: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const selectedInvestmentScenario = useSelector(selectedInvestmentScenarioSelector);
  const investmentScenarios = useSelector(investmentScenariosSelector);
  const scenario = useSelector(createMapStateSelectorFactory('scenario'));
  const showMaxLoadVoltage = useSelector(createMapStateSelectorFactory('showMaxLoadVoltage'));

  const scaleItems = useMemo<Map.MultiSwitchItem[]>(
    () => [
      { id: 0, value: 0, label: getIntl('Current • %') },
      { id: 1, value: 1, label: `${getIntl('Voltage')} • p.u.` },
    ],
    [getIntl]
  );

  const [selectedItem, setSelectedItem] = useState(scaleItems.find(i => i.value === Number(showMaxLoadVoltage))!);

  useEffect(() => {
    dispatch(mapStateAction({ showMaxLoadVoltage: Boolean(selectedItem.value) }));
  }, [dispatch, selectedItem]);

  const handleScenarioYearChange = useCallback(
    (index: number) => {
      const year = scenario!.years[Number(index)];
      const id = scenario?.years0.includes(year) ? 0 : scenario?.selectedId;
      const key = `${id}_${year}`;
      dispatch(mapStateAction({ scenario: { ...scenario, id, year, key } as Map.Scenario }));
    },
    [dispatch, scenario]
  );

  const handleInvestmentScenarioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(event.target.id);
      const scenario = investmentScenarios?.find(i => i.value === id);
      if (scenario) dispatch(setSelectedInvestmentScenarioAction(scenario));
    },
    [dispatch, investmentScenarios]
  );

  const NoSectionData = <StyledNoSectionData>{getIntl('No data available')}</StyledNoSectionData>;

  const renderScenarioRangeSlider = () => {
    if (!scenario?.years?.length) return null;
    if (scenario.years.length === 1) {
      return (
        <div className="my-2 map-text text-center">
          {getIntl('Data on the map is only available for the year')} {scenario.years[0]}
        </div>
      );
    }
    return (
      <RangeSlider
        min={0}
        max={scenario.years.length - 1}
        value={scenario.years.findIndex(y => y === scenario.year)}
        onChange={handleScenarioYearChange}
        tooltipLabel={i => `${scenario.years[i] ?? getIntl('Unknown')}`}
        resetOn={scenario.selectedId}
        className="mt-1"
        dataMarker="max_load_track"
      />
    );
  };

  const renderInvestmentScenariosSection = () => (
    <StyledSection title={getIntl('Investment scenario')}>
      {investmentScenarios?.length
        ? investmentScenarios.map(s => (
            <ControlBox
              key={s.value}
              type="radio"
              id={String(s.value)}
              name={String(s.value)}
              checked={selectedInvestmentScenario?.value === s.value}
              labelKey={s.label}
              onChange={handleInvestmentScenarioChange}
            />
          ))
        : NoSectionData}
    </StyledSection>
  );

  return (
    <CollapseGroup
      id="max_load"
      dataMarker="panel_max_load"
      titleKey={settings.themeTitles?.ml!}
      disabled={settings.isMaxLoadThemeDisabled || !settings.scenarios}
      stateLoc="theme"
    >
      <ScaleContainer
        title={
          <>
            {getIntl(
              (!scenario?.years0.includes(scenario.year) ? 'Forecasted ' : 'Historical ') +
                (showMaxLoadVoltage ? 'voltage' : 'utilization')
            )}
            {showMaxLoadVoltage ? ' • p.u.' : ' • %'}
          </>
        }
        scale={
          <>
            {showMaxLoadVoltage ? (
              <Scale theme={selectedMapTheme} values={[0.9, 0.95, 1, 1.05, 1.1]} centered disablePostfix />
            ) : (
              <Scale theme={selectedMapTheme} values={[0, 20, 40, 60, 80, 100]} />
            )}
          </>
        }
        controls={<MultiSwitch name="max-load" items={scaleItems} selected={selectedItem} onChange={setSelectedItem} />}
      />
      {scenario ? (
        <>
          {renderScenarioRangeSlider()}
          {renderInvestmentScenariosSection()}
          {settings.hasMaxLoadSummary && <ForecastInvestmentMaxLoadSummary NoSectionData={NoSectionData} />}
        </>
      ) : (
        <Loading style={{ padding: '50px 0' }} />
      )}
    </CollapseGroup>
  );
};

const StyledSection = styled(Section)`
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: var(--map-text-color-secondary);
`;

const StyledNoSectionData = styled.div`
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 11px;
  color: var(--map-text-color-inactive);
  padding: 3px;
`;

export default ForecastInvestmentMaxLoad;
