import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Form, Row, Col, ListGroup } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { checklistsModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { checklistSelectorFactory } from 'modules/tasks/selectors';
import {
  Modal,
  Button,
  FormInput,
  SelectAssetCategories,
  HiddenFormSubmit,
  SelectTaskAppAction,
} from 'components/_common';
import { NonAssetTaskAppActions, TaskAppActions } from 'constants/index';
import useConfiguredFormik from './useConfiguredFormik';
import { IconPlus } from '@utiligize/shared/resources';

const ChecklistsModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const id: number | null = useSelector(checklistsModalIdSelector);
  const checklist: Tasks.Checklist | undefined = useSelector(checklistSelectorFactory(id));
  const isEditMode: boolean = Boolean(id);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ checklistsModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue,
    setValues,
    setFieldTouched,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!checklist) return;
    setValues({
      name: checklist.name,
      appAction: checklist.appAction,
      assetCategoryCode: checklist.assetCategoryCode,
      questionInput: '',
      questions: checklist.questions,
    });
    setShow(true);
  }, [checklist, setValues]);

  const handleAddButtonClick = useCallback(() => {
    setFieldValue('questions', values.questions.concat(values.questionInput));
    setFieldValue('questionInput', '');
  }, [values.questions, values.questionInput, setFieldValue]);

  const handleDeleteButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const question: string = event.currentTarget.getAttribute('data-question') as string;
      setFieldValue(
        'questions',
        values.questions.filter(q => q !== question)
      );
    },
    [values.questions, setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create checklist" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit checklist' : 'Create checklist'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectTaskAppAction
              actions={values.appAction}
              onChange={(v: any) => setValues({ ...values, appAction: v.value, assetCategoryCode: null })}
              onBlur={() => setFieldTouched('appAction')}
              errorKey={Boolean(touched.appAction && errors.appAction) ? (errors.appAction as string) : ''}
              excludedOptions={[TaskAppActions.ToolInspection]}
            />
          </Form.Group>
          <Form.Group>
            <SelectAssetCategories
              value={values.assetCategoryCode}
              onChange={(v: any) => setFieldValue('assetCategoryCode', v.value)}
              onBlur={() => setFieldTouched('assetCategoryCode')}
              errorKey={
                Boolean(touched.assetCategoryCode && errors.assetCategoryCode)
                  ? (errors.assetCategoryCode as string)
                  : ''
              }
              excludeSyntheticOptions
              isDisabled={!values.appAction || NonAssetTaskAppActions.includes(values.appAction)}
            />
          </Form.Group>
          <Row
            className={classNames('my-3 align-items-end justify-content-start', {
              'is-invalid': Boolean(touched.questions && errors.questions),
            })}
          >
            <Col>
              <FormInput
                labelKey="Add question"
                name="questionInput"
                value={values.questionInput}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
            <Col xs="auto">
              <button className="btn btn-default w-100" disabled={!values.questionInput} onClick={handleAddButtonClick}>
                <i className="fa fa-plus" />
              </button>
            </Col>
          </Row>
          <Form.Control.Feedback type="invalid">{getIntl(errors.questions as string)}</Form.Control.Feedback>
          <ListGroup>
            {values.questions.map((question: string) => (
              <ListGroup.Item key={question}>
                <Row className="align-items-center">
                  <Col>{question}</Col>
                  <Col xs={1}>
                    <button
                      type="button"
                      className="btn btn-default btn-sm w-100"
                      data-question={question}
                      onClick={handleDeleteButtonClick}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ChecklistsModal;
