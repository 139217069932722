import React from 'react';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'modules/map/selectors';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
// Forecast & Investment
import ForecastInvestmentDataQuality from './ForecastInvestmentDataQuality';
import ForecastInvestmentMaxLoad from './ForecastInvestmentMaxLoad';
import ForecastInvestmentYearlyConsumption from './ForecastInvestmentYearlyConsumption';
import ForecastInvestmentYearlyLosses from './ForecastInvestmentYearlyLosses';
import ForecastInvestmentN1Routes from './ForecastInvestmentN1Routes';
import ForecastInvestmentN1YearlyMaxLoad from './ForecastInvestmentN1YearlyMaxLoad';
// Maintenance & planning
import TasksPanelFilters from 'components/Map/_tasks/TasksPanelFilters';
// Reinvestment
import ReinvestmentYearlyReplacements from './ReinvestmentYearlyReplacements';
//
import GroupContainer from './GroupContainer';
import GroupTitle from './GroupTitle';
import GroupSection from './GroupSection';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import { MapThemeGroups } from 'constants/index';
import { ReactComponent as ForecastIcon } from 'components/Map/icons/icon • map panel section • forecast and investment.svg';
import { ReactComponent as MaintenanceIcon } from 'components/Map/icons/icon • map panel section • maintenance.svg';
import { ReactComponent as ReinvestmentIcon } from 'components/Map/icons/icon • map panel section • reinvestment.svg';

const getGroupDescription = (group: Map.ThemeGroups, settings: Map.Settings) => {
  if (group === MapThemeGroups.FORECAST_AND_INVESTMENT) {
    return [
      settings.hasDataQuality && settings.themeTitles?.['dq'],
      settings.hasMaxLoad && settings.themeTitles?.['ml'],
      settings.hasConsumption && settings.themeTitles?.['ec'],
      settings.hasLosses && settings.themeTitles?.['yl'],
      settings.hasN1 && settings.themeTitles?.['n_1'],
      settings.hasN1MaxLoad && settings.themeTitles?.['n_1_ml'],
    ].filter(Boolean) as string[];
  } else if (group === MapThemeGroups.MAINTENANCE_AND_PLANNING) {
    return [settings.hasTasks && settings.themeTitles?.['ts']].filter(Boolean) as string[];
  } else if (group === MapThemeGroups.REINVESTMENT) {
    return [settings.hasReplacements && settings.themeTitles?.['yr']].filter(Boolean) as string[];
  }
};

const GroupDataThemes: React.FC = () => {
  const settings = useSelector(settingsSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  const showForecastInvestment =
    settings.hasDataQuality ||
    settings.hasMaxLoad ||
    settings.hasConsumption ||
    settings.hasLosses ||
    settings.hasN1 ||
    settings.hasN1MaxLoad;
  const showMaintenanceAndPlanning = settings.hasTasks;
  const showReinvestments = settings.hasReplacements;

  if ((!showForecastInvestment && !showMaintenanceAndPlanning && !showReinvestments) || singleDiagramEnabled) {
    return null;
  }

  return (
    <GroupContainer>
      <GroupTitle titleKey="Data themes" />
      {showForecastInvestment && (
        <GroupSection>
          <CollapseGroup
            id={MapThemeGroups.FORECAST_AND_INVESTMENT}
            titleKey="Forecast and Investment"
            dataMarker="panel_forecast_and_investment"
            stateLoc="themeGroup"
            nested
            icon={<ForecastIcon className="group-icon" />}
            disabled={settings.isForecaseGroupDisabled}
            description={
              <GroupHiddenDescription
                values={getGroupDescription(MapThemeGroups.FORECAST_AND_INVESTMENT, settings) ?? []}
                showMoreItems
              />
            }
          >
            {settings.hasDataQuality && <ForecastInvestmentDataQuality />}
            {settings.hasMaxLoad && <ForecastInvestmentMaxLoad />}
            {settings.hasConsumption && <ForecastInvestmentYearlyConsumption />}
            {settings.hasLosses && <ForecastInvestmentYearlyLosses />}
            {settings.hasN1 && <ForecastInvestmentN1Routes />}
            {settings.hasN1MaxLoad && <ForecastInvestmentN1YearlyMaxLoad />}
          </CollapseGroup>
        </GroupSection>
      )}
      {showMaintenanceAndPlanning && (
        <GroupSection>
          <CollapseGroup
            id={MapThemeGroups.MAINTENANCE_AND_PLANNING}
            titleKey="Maintenance and planning"
            dataMarker="panel_maintenance_and_planning"
            stateLoc="themeGroup"
            nested
            icon={<MaintenanceIcon className="group-icon" />}
            disabled={settings.isMaintenanceGroupDisabled}
            description={
              <GroupHiddenDescription
                values={getGroupDescription(MapThemeGroups.MAINTENANCE_AND_PLANNING, settings) ?? []}
                showMoreItems
              />
            }
          >
            {settings.hasTasks && <TasksPanelFilters />}
          </CollapseGroup>
        </GroupSection>
      )}
      {showReinvestments && (
        <GroupSection>
          <CollapseGroup
            id={MapThemeGroups.REINVESTMENT}
            titleKey="Reinvestment"
            stateLoc="themeGroup"
            dataMarker="panel_reinvestment"
            nested
            icon={<ReinvestmentIcon className="group-icon" />}
            disabled={settings.isReinvestmentGroupDisabled}
            description={
              <GroupHiddenDescription
                values={getGroupDescription(MapThemeGroups.REINVESTMENT, settings) ?? []}
                showMoreItems
              />
            }
          >
            {settings.hasReplacements && <ReinvestmentYearlyReplacements />}
          </CollapseGroup>
        </GroupSection>
      )}
    </GroupContainer>
  );
};

export default GroupDataThemes;
