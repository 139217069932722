import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewSaifiCMLplots } from 'components/Reliability';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageReliability: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isReliabilityEnabled}>
    <Switch>
      <Route exact path={Routes.Reliability} component={ViewSaifiCMLplots} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageReliability;
