import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useLocale } from 'hooks';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import { isSidebarOpenSelector } from 'modules/layouts/selectors';
import { closeSidebarAction, toggleSidebarAction } from 'modules/layouts';
import { BaseSidebar, SidebarAccordion, Spinner } from '@utiligize/shared/components';
import { Routes, DefaultRoute } from 'constants/index';
import {
  IconLayers,
  IconHistory,
  IconDataflow,
  IconClipboardCheck,
  IconMap,
  IconUsers,
  IconSliders,
  IconArrowBrokenDown,
  IconCurrencyEuroCircle,
  IconMeter,
  IconTool,
  IconList,
  IconFilePlus,
  IconSettings,
  IconShieldCheck,
  IconCoinsHand,
  IconBarChart2,
  IconTool2,
  IconSwap,
  IconActivity,
  IconHardDrive,
  IconUtiligizeWhite,
  IconCO2,
} from '@utiligize/shared/resources';

interface Props {
  isMobile: boolean;
  appCurrentUserFetched: boolean | null;
}

const Sidebar: React.FC<Props> = ({ isMobile, appCurrentUserFetched }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  const toggleSidebar = useCallback((): Promise<void> => dispatch(toggleSidebarAction()), [dispatch]);

  const closeSidebar = useCallback((): Promise<void> => dispatch(closeSidebarAction()), [dispatch]);

  const renderMenu = () => {
    if (appCurrentUserFetched === null) return <Spinner isInFullHeightContainer />;
    return (
      <>
        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          labelKey="General"
          dataMarker="sidebar_general"
          icon={<IconLayers />}
          isFlatMenu
          items={[
            {
              to: Routes.Map,
              labelKey: 'Map',
              icon: <IconMap />,
              disabled: !appCurrentUserPermissions.isMapEnabled,
              dataMarker: 'sidebar_map',
            },
            {
              to: Routes.CO2e,
              labelKey: 'CO₂e',
              icon: <IconCO2 />,
              disabled: !appCurrentUserPermissions.isCO2eEnabled,
              dataMarker: 'sidebar_co2e',
            },
          ]}
          getIntl={getIntl}
        />
        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          labelKey="Forecast & Investment"
          dataMarker="sidebar_forecast_investment"
          icon={<IconBarChart2 />}
          items={[
            {
              to: Routes.NetworkLoading,
              labelKey: 'Network loading',
              dataMarker: 'sidebar_network_loading',
              icon: <IconDataflow />,
              disabled: !appCurrentUserPermissions.isNetworkLoadingEnabled,
            },
            {
              to: Routes.N1,
              labelKey: 'N-1',
              dataMarker: 'sidebar_n_1',
              icon: <IconActivity />,
              disabled: !appCurrentUserPermissions.isN1Available,
            },
            {
              to: Routes.Customers,
              labelKey: 'Customers',
              icon: <IconUsers />,
              disabled: !appCurrentUserPermissions.isCustomersEnabled,
              dataMarker: 'sidebar_customers',
            },
            {
              to: Routes.Investment,
              labelKey: 'Investment',
              dataMarker: 'sidebar_investment',
              icon: <IconCurrencyEuroCircle />,
              disabled: !appCurrentUserPermissions.isInvestmentEnabled,
            },
            {
              to: Routes.DataQuality,
              labelKey: 'Data quality',
              dataMarker: 'sidebar_data_quality',
              icon: <IconHardDrive />,
              disabled: !appCurrentUserPermissions.isDataQualityEnabled,
            },
            {
              to: Routes.Setup,
              labelKey: 'Setup',
              dataMarker: 'sidebar_setup',
              icon: <IconSliders />,
              disabled: !appCurrentUserPermissions.isSetupEnabled,
            },
            {
              to: Routes.NewLoad,
              labelKey: 'New load',
              dataMarker: 'sidebar_new_load',
              icon: <IconArrowBrokenDown />,
              disabled: !appCurrentUserPermissions.isNewLoadEnabled,
            },
          ].filter(Boolean)}
          getIntl={getIntl}
        />
        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          labelKey="Maintenance & planning"
          dataMarker="sidebar_maintenance_planning"
          icon={<IconTool2 />}
          items={[
            {
              to: generatePath(Routes.Tasks),
              labelKey: 'Tasks',
              dataMarker: 'sidebar_tasks',
              icon: <IconClipboardCheck />,
              disabled: !appCurrentUserPermissions.isTasksEnabled,
            },
            {
              to: generatePath(Routes.Meters),
              labelKey: 'Meters',
              dataMarker: 'sidebar_meters',
              icon: <IconMeter />,
              disabled: !appCurrentUserPermissions.isSmartMetersEnabled,
            },
            {
              to: Routes.Maintenance,
              labelKey: 'Maintenance',
              dataMarker: 'sidebar_maintenance',
              icon: <IconTool />,
              disabled: !appCurrentUserPermissions.isMaintenanceEnabled,
            },
            {
              to: Routes.AssetModels,
              labelKey: 'Asset models',
              dataMarker: 'sidebar_asset_models',
              icon: <IconList />,
              disabled: !appCurrentUserPermissions.isAssetModelsEnabled,
            },
            {
              to: Routes.Forms,
              labelKey: 'Forms',
              dataMarker: 'sidebar_forms',
              icon: <IconFilePlus />,
              disabled: !appCurrentUserPermissions.isFormsEnabled,
            },
            {
              to: generatePath(Routes.History),
              labelKey: 'History',
              dataMarker: 'sidebar_history',
              icon: <IconHistory />,
              disabled: !appCurrentUserPermissions.isHistoryEnabled,
            },
            {
              to: Routes.DataManuals,
              labelKey: 'Data manuals',
              dataMarker: 'sidebar_data_manuals',
              icon: <IconSettings />,
              disabled: !appCurrentUserPermissions.isDataManualsEnabled,
            },
          ]}
          getIntl={getIntl}
        />
        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          labelKey="Reinvestment"
          dataMarker="sidebar_reinvestment"
          icon={<IconSwap />}
          items={[
            {
              to: Routes.Reliability,
              labelKey: 'Reliability',
              dataMarker: 'sidebar_reliability',
              icon: <IconShieldCheck />,
              disabled: !appCurrentUserPermissions.isReliabilityEnabled,
            },
            {
              to: Routes.MonetizedRisk,
              labelKey: 'Monetized risk',
              dataMarker: 'sidebar_monetized_risk',
              icon: <IconCoinsHand />,
              disabled: !appCurrentUserPermissions.isMonetizedRiskEnabled,
            },
          ]}
          getIntl={getIntl}
        />
      </>
    );
  };

  return (
    <BaseSidebar
      isMobile={isMobile}
      isSidebarOpen={isSidebarOpen}
      DefaultRoute={DefaultRoute}
      toggleSidebar={toggleSidebar}
      closeSidebar={closeSidebar}
      renderMenu={renderMenu}
      topLogo={<IconUtiligizeWhite />}
    />
  );
};

export default Sidebar;
