import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { mapLoadingSelector } from 'modules/map/selectors';
import { isMapConnectionsCalculationInProgressSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';

const MapNotifications: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const mapLoading = useSelector(mapLoadingSelector);
  const isMapConnectionsCalculationInProgress = useSelector(isMapConnectionsCalculationInProgressSelector);
  const [diffStr, setDiffStr] = useState('');

  useEffect(() => {
    if (!isMapConnectionsCalculationInProgress) return;
    const start = Date.now();
    const timeout = 120000; // 120 sec

    const intervalId = setInterval(() => {
      const diff = Date.now() - start;

      if (diff >= timeout) {
        dispatch(setLayoutAction({ isMapConnectionsCalculationInProgress: false }));
        return;
      }

      const nextDiff = Math.ceil((timeout - diff) / 1000);
      const formattedNextDiff = String(nextDiff).padStart(2, '0');
      setDiffStr(formattedNextDiff);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isMapConnectionsCalculationInProgress, dispatch]);

  if (!mapLoading && !isMapConnectionsCalculationInProgress) return null;
  return (
    <StyledContainer>
      {mapLoading && (
        <StyledMessageContainer>
          <span className="mr-2">{getIntl('Loading, please wait')}</span>
          <i className="fas fa-spinner fa-pulse" />
        </StyledMessageContainer>
      )}
      {isMapConnectionsCalculationInProgress && (
        <StyledMessageContainer>
          <span className="mr-2">
            {getIntl('Calculation is in progress, please wait {{seconds}} sec', { seconds: diffStr })}
          </span>
          <i className="fas fa-spinner fa-pulse" />
        </StyledMessageContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
  user-select: none;
  opacity: 0.9;

  > div:not(:last-child) {
    margin-bottom: 10px;
    display: inline-block;
  }
`;

const StyledMessageContainer = styled.div`
  padding: 7px 10px;
  box-shadow:
    0px 4px 4px rgb(50 50 71 / 8%),
    0px 4px 8px rgb(50 50 71 / 6%);
  border: 0.5px solid #d4d4d4;
  border-radius: 8px;
  background: #fff;
  color: var(--map-text-color-primary);
  font-size: 12px;
`;

export default MapNotifications;
