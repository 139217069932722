import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchDERsProfilesAction, deleteDERsProfileAction } from 'modules/newLoad';
import { DERsProfilesCountSelector, DERsProfilesSelector } from 'modules/newLoad/selectors';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconDetails, IconDelete } from '@utiligize/shared/resources';
import ModalProfile from './ModalProfile';

const TableDERsProfiles: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(DERsProfilesCountSelector);
  const items = useSelector(DERsProfilesSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchDERsProfilesAction(portfolioId)), [dispatch, portfolioId]);

  const handleViewProfileButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ newLoadDERProfileModalId: id }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      const description: string | null = event.currentTarget.getAttribute('data-description');
      dispatch(
        setModalConfirmationMetaAction({
          title: getIntl('Do you want to delete {{description}}?', { description: description || '' }),
          onConfirm: () => dispatch(deleteDERsProfileAction(Number(id))),
        })
      );
    },
    [dispatch, getIntl]
  );

  return (
    <>
      <DataTable
        paginationType={PaginationType.NEW_LOAD_DERS_PROFILES}
        totalAmount={count}
        sendRequest={sendRequest}
        waitForDependencies={!portfolioId}
        triggerTableUpdateDeps={[portfolioId]}
      >
        {(items || []).map((item: NewLoad.DERProfile) => (
          <tr key={item.id}>
            <td>{dateFormat(item.upload_date)}</td>
            <td>{item.company || '-'}</td>
            <td>{item.description || '-'}</td>
            <td>{item.user || '-'}</td>
            <td className="text-center">{getIntl(item.shared ? 'Yes' : 'No')}</td>
            <td className="text-right">
              <Button
                tooltipKey="Profile"
                icon={<IconDetails />}
                data-id={item.id}
                onClick={handleViewProfileButtonClick}
                size="small"
                variant="primary"
              />
              <Button
                marginLeft
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                data-description={item.description}
                onClick={handleDeleteBtnClick}
                size="small"
                variant="primary"
              />
            </td>
          </tr>
        ))}
      </DataTable>
      <ModalProfile />
    </>
  );
};

export default TableDERsProfiles;
