import React, { useCallback, useState, useEffect, memo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchSubComponentsAction, createSubComponentAction } from 'modules/assets';
import { subComponentsSelector } from 'modules/assets/selectors';
import MenuOption from './MenuOption';
import { AssetCategories } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'onChange' | 'options'> {
  labelKey?: string;
  errorKey?: string;
  disabled?: boolean;
  value: number | 'unassigned' | null;
  assetCategoryCode: Type.AssetCategories | null;
  setFieldValue?: (id: number | null, label: string) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
  customOptions?: Type.SelectOption[];
  isReadOnly?: boolean;
}

const SelectSubComponents: React.FC<Props> = memo(
  ({
    labelKey = 'Sub-component type',
    assetCategoryCode,
    errorKey = '',
    disabled = false,
    value,
    setFieldValue,
    customOptions = [],
    isReadOnly = false,
    ...props
  }) => {
    const readOnly = assetCategoryCode !== AssetCategories.BD || isReadOnly;
    const isAutoAssignDisabled = Boolean(customOptions.length);
    const [loading, setLoading] = useState(true);
    const { getIntl } = useLocale();
    const dispatch: Shared.CustomDispatch = useDispatch();
    const subComponents = useSelector(subComponentsSelector);

    const subComponentsOptions = [
      ...(subComponents?.map((item: Asset.SubComponentType) => ({ value: item.id, label: getIntl(item.name) })) || []),
      ...customOptions.map(option => ({ ...option, synthetic: true })),
    ];

    useEffect(() => {
      if (!assetCategoryCode) return setLoading(false);
      setLoading(true);
      dispatch(fetchSubComponentsAction(assetCategoryCode)).then(
        (action: Shared.ReduxAction<Pick<Asset.Root, 'subComponents'>>) => {
          const { subComponents } = action.payload;
          if (!isAutoAssignDisabled && subComponents?.length === 1 && subComponents[0]?.id !== value) {
            setFieldValue?.(subComponents[0]?.id, subComponents[0]?.name);
          }
          setLoading(false);
        }
      );
    }, [assetCategoryCode, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    // clear form value if option was deleted
    const selectValue = subComponentsOptions.find(i => i.value === value) || null;
    useEffect(() => {
      if (!loading && value && !selectValue) setFieldValue?.(null, '');
    }, [value, selectValue, loading, setFieldValue]);

    const handleSelectChange = useCallback(
      async (subComponentsOption: Type.SelectOption) => {
        const isNew = subComponentsOption?.__isNew__;
        if (isNew) setLoading(true);
        const id = isNew
          ? await dispatch(
              createSubComponentAction({ name: subComponentsOption!.label, assetCategoryCode: AssetCategories.BD })
            ).then((action: Shared.ReduxAction<{ id: number }>) => action.payload.id)
          : subComponentsOption?.value;
        setFieldValue?.((id as number) || null, subComponentsOption?.label || '');
        if (isNew) setLoading(false);
      },
      [dispatch, setFieldValue]
    );

    const selectProps = {
      isClearable: true,
      onChange: handleSelectChange,
      options: subComponentsOptions,
      isDisabled: loading || disabled,
      isLoading: loading,
      value: selectValue,
      classNamePrefix: 'react-form-sub-components-select',
      components: {
        Option: (props: any) => <MenuOption readOnly={readOnly} assetCategoryCode={assetCategoryCode} {...props} />,
      },
      placeholder: getIntl('Select'),
      isCreatableSelect: !readOnly,
      labelKey,
      errorKey,
    };

    return <FormReactSelect {...props} {...selectProps} />;
  }
);

export default SelectSubComponents;
