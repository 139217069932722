import { useCallback } from 'react';
import { useLocale } from 'hooks';
import moment from 'moment';
import { keyToReadableProp } from 'utils/map';

export default function useReadableTable() {
  const { getIntl, dateFormat, numberFormat } = useLocale();

  const formatProp = useCallback(
    (prop: string, options?: Map.FormatPropOptions) => {
      const translate = options?.translate ?? true;
      const formatted = keyToReadableProp(prop.trim(), options?.toSnake, options?.upper);
      return translate ? getIntl(formatted) : formatted;
    },
    [getIntl]
  );

  const formatValue = useCallback(
    (key: string, val: string | number | boolean, { translate }: Map.FormatValueOptions = { translate: true }) => {
      if (
        /date|utc/i.test(key) &&
        new Date(val as string | number).toString() !== 'Invalid Date' &&
        moment(val as string | number).isValid()
      ) {
        return dateFormat(val as string);
      }

      switch (typeof val) {
        case 'boolean':
          return val ? 'Yes' : 'No';
        case 'number':
          // Don't format years
          if (['installation_year', 'suggested_replacement_year'].includes(key)) return val;
          return numberFormat(val);
        case 'string':
          return translate && val ? getIntl(val) : val || '—';
        default:
          return val ?? '—';
      }
    },
    [dateFormat, getIntl, numberFormat]
  );

  return { formatProp, formatValue };
}
