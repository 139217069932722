import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { createMapStateSelectorFactory, settingsSelector } from 'modules/map/selectors';
import RangeSlider from 'components/Map/common/RangeSlider';
import Loading from 'components/Map/common/Loading';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import MultiSwitch from 'components/Map/common/MultiSwitch';
import ScaleContainer from 'components/Map/common/ScaleContainer';
import ScaleGroup from 'components/Map/common/ScaleGroup';
import { Scale } from 'components/Map/common';
import { N1MaxLoadScales } from 'constants/index';
import { mapColorGradient } from 'utils/map';

const ForecastInvestmentN1YearlyMaxLoad: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const scenario = useSelector(createMapStateSelectorFactory('n1MaxLoadScenario'));
  const type = useSelector(createMapStateSelectorFactory('n1MaxLoadValueType'));

  const scaleItemsHash = useMemo<Record<typeof type, Map.MultiSwitchItem>>(
    () => ({
      percent: { id: 0, value: 'percent', label: getIntl('Current • %') },
      voltage: { id: 1, value: 'voltage', label: `${getIntl('Voltage')} • p.u.` },
      capacity: { id: 2, value: 'capacity', label: `${getIntl('Spare capacity')} • kVA` },
    }),
    [getIntl]
  );

  const scaleItems = useMemo<Map.MultiSwitchItem[]>(() => Object.values(scaleItemsHash), [scaleItemsHash]);

  const handleScaleModeChange = useCallback(
    (item: Map.MultiSwitchItem) => {
      dispatch(mapStateAction({ n1MaxLoadValueType: item.value as typeof type }));
    },
    [dispatch]
  );

  const handleScenarioYearChange = (year: number) => {
    const id = scenario?.years0.includes(year) ? 0 : scenario?.selectedId;
    const key = `${id}_${year}`;
    const s = { ...scenario, id, year, key } as Map.Scenario;
    dispatch(mapStateAction({ n1MaxLoadScenario: s }));
  };

  const renderThemeScale = () => (
    <ScaleGroup
      scales={N1MaxLoadScales.filter(i => i.name === type).map(i => {
        const [min, max] = i.range;
        const colors = mapColorGradient(selectedMapTheme, i.type, min, max);
        const values = colors.filter(c => typeof c === 'number') as number[];
        return (
          <Scale
            theme={selectedMapTheme}
            values={values}
            centered={i.type === 'centered'}
            reversed={i.type === 'reversed'}
            disablePostfix={type === 'voltage'}
          />
        );
      })}
    />
  );

  return (
    <CollapseGroup
      id="n_1_ml"
      dataMarker="panel_n_1_max_load"
      titleKey={settings.themeTitles?.n_1_ml!}
      disabled={settings.isN1MaxLoadThemeDisabled || !settings.n1MaxLoadScenarios}
      stateLoc="theme"
    >
      <ScaleContainer
        title={
          <div>
            {getIntl(
              (!scenario?.years0.includes(scenario.year) ? 'Forecasted ' : 'Historical ') +
                (type === 'percent' ? 'utilization' : type === 'voltage' ? 'voltage' : 'spare capacity')
            )}
            {type === 'percent' ? ' • %' : type === 'voltage' ? ' • p.u.' : ` • kVA`}
          </div>
        }
        scale={renderThemeScale()}
        controls={
          <MultiSwitch
            name="n-1-max-load"
            items={scaleItems}
            selected={scaleItemsHash[type]}
            onChange={handleScaleModeChange}
          />
        }
      />
      {scenario ? (
        <div className="d-flex flex-column">
          {scenario.years.length > 1 ? (
            <RangeSlider
              min={0}
              max={scenario.years.length - 1}
              value={scenario.years.findIndex(y => y === scenario.year)}
              onChange={value => handleScenarioYearChange(scenario.years[+value])}
              tooltipLabel={i => `${scenario.years[i] ?? getIntl('Unknown')}`}
              resetOn={scenario.selectedId}
              className="mt-1"
            />
          ) : (
            scenario.years.length > 0 && (
              <div className="my-2 map-text text-center">
                {getIntl('Data on the map is only available for the year')} {scenario.years[0]}
              </div>
            )
          )}
        </div>
      ) : (
        <Loading style={{ padding: '50px 0' }} />
      )}
    </CollapseGroup>
  );
};

export default ForecastInvestmentN1YearlyMaxLoad;
