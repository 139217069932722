import { combineActions, handleActions } from 'redux-actions';
import {
  fetchTransformersAction,
  fetchAssetCommentsAction,
  setSolvedAction,
  getTimeSeriesChartData,
  getProfileChartData,
  getDurationChartData,
  fetchAssetCustomersAction,
  getTotalLoadingAggregatedMeterChartsDataAction,
  fetchScenarioRunInfoAction,
  resetScenarioRunInfoAction,
  fetchTransformersForecastAction,
  getTransformersHistoricalLoadingChartData,
  getTransformersVoltageChartData,
  getTotalLoadingProfileChartData,
  fetchFutureChartData,
  getReliabilityChartData,
} from './actions';
import {
  fetchCablesAction,
  fetchCablesForecastAction,
  getCablesHistoricalLoadingChartData,
  getCablesVoltageChartData,
} from './cables.actions';
import { fetchFusesAction, fetchFusesForecastAction } from './fuses.actions';

const initialState: NetworkLoading.Root = {
  transformersHash: {} as NetworkLoading.Root['transformersHash'],
  transformersCount: 0,
  transformersForecastsHash: {} as NetworkLoading.Root['transformersForecastsHash'],
  transformersForecastsCount: 0,
  ignoreMessagesHash: {} as NetworkLoading.Root['ignoreMessagesHash'],
  ignoreMessagesCount: 0,
  cablesHash: {} as NetworkLoading.Root['cablesHash'],
  cablesCount: 0,
  cablesForecastsHash: {} as NetworkLoading.Root['cablesForecastsHash'],
  cablesForecastsCount: 0,
  fusesHash: {} as NetworkLoading.Root['fusesHash'],
  fusesCount: 0,
  fusesForecastsHash: {} as NetworkLoading.Root['fusesForecastsHash'],
  fusesForecastsCount: 0,
  customersTypesHash: {} as NetworkLoading.Root['customersTypesHash'],
  customersTypesInstallations: [],
  scenarioRunInfo: null,
  futureChartDataHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchTransformersAction,
    fetchAssetCommentsAction,
    fetchCablesAction,
    fetchAssetCustomersAction,
    fetchFusesAction,
    fetchScenarioRunInfoAction,
    resetScenarioRunInfoAction,
    fetchTransformersForecastAction,
    fetchCablesForecastAction,
    fetchFusesForecastAction,
    fetchFutureChartData
  ) as any]: {
    next: (
      state: NetworkLoading.Root,
      action: Shared.ReduxAction<Partial<NetworkLoading.Root> & { skipStoreUpdate: true | undefined }>
    ): NetworkLoading.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchTransformersAction,
  fetchAssetCommentsAction,
  fetchCablesAction,
  setSolvedAction,
  getTimeSeriesChartData,
  getProfileChartData,
  getDurationChartData,
  fetchAssetCustomersAction,
  fetchFusesAction,
  getTotalLoadingAggregatedMeterChartsDataAction,
  fetchScenarioRunInfoAction,
  fetchTransformersForecastAction,
  fetchCablesForecastAction,
  fetchFusesForecastAction,
  getTransformersHistoricalLoadingChartData,
  getTransformersVoltageChartData,
  getCablesHistoricalLoadingChartData,
  getCablesVoltageChartData,
  getTotalLoadingProfileChartData,
  fetchFutureChartData,
  getReliabilityChartData,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
