import BoltBlueIcon from 'components/Map/icons/icon • map layer • circle • bolt • light blue.png';
import BoltGrayIcon from 'components/Map/icons/icon • map layer • circle • bolt • light gray.png';
import BoltPurpleIcon from 'components/Map/icons/icon • map layer • circle • bolt • light purple.png';
import BoltRedIcon from 'components/Map/icons/icon • map layer • circle • bolt • light red.png';
import BoltYellowIcon from 'components/Map/icons/icon • map layer • circle • bolt • light yellow.png';
//
import CustomerBlueIcon from 'components/Map/icons/icon • map layer • circle • customer • light blue.png';
import CustomerGrayIcon from 'components/Map/icons/icon • map layer • circle • customer • light gray.png';
import CustomerPurpleIcon from 'components/Map/icons/icon • map layer • circle • customer • light purple.png';
import CustomerRedIcon from 'components/Map/icons/icon • map layer • circle • customer • light red.png';
import CustomerYellowIcon from 'components/Map/icons/icon • map layer • circle • customer • light yellow.png';
//
import LightBlueIcon from 'components/Map/icons/icon • map layer • circle • light • light blue.png';
import LightGrayIcon from 'components/Map/icons/icon • map layer • circle • light • light gray.png';
import LightPurpleIcon from 'components/Map/icons/icon • map layer • circle • light • light purple.png';
import LightRedIcon from 'components/Map/icons/icon • map layer • circle • light • light red.png';
import LightYellowIcon from 'components/Map/icons/icon • map layer • circle • light • light yellow.png';
//
import LinkBlueIcon from 'components/Map/icons/icon • map layer • circle • link • light blue.png';
import LinkGrayIcon from 'components/Map/icons/icon • map layer • circle • link • light gray.png';
import LinkPurpleIcon from 'components/Map/icons/icon • map layer • circle • link • light purple.png';
import LinkRedIcon from 'components/Map/icons/icon • map layer • circle • link • light red.png';
import LinkYellowIcon from 'components/Map/icons/icon • map layer • circle • link • light yellow.png';
//
import Investment3dIcon from 'components/Map/icons/icon • map layer • investment • purple.png';
import Flexibility3dIcon from 'components/Map/icons/icon • map layer • flexibility • purple.png';
//
import IconLayerDERBatteryPurple from 'components/Map/icons/icon • map layer • DER • battery • purple.png';
import IconLayerDERElectricVehiclePurple from 'components/Map/icons/icon • map layer • DER • electric vehicle • purple.png';
import IconLayerDERHeatPumpPurple from 'components/Map/icons/icon • map layer • DER • heat pump • purple.png';
import IconLayerDEROtherGenerationPurple from 'components/Map/icons/icon • map layer • DER • other generation • purple.png';
import IconLayerDERSolarPanelPurple from 'components/Map/icons/icon • map layer • DER • solar panel • purple.png';
import IconLayerDERWindTurbinePurple from 'components/Map/icons/icon • map layer • DER • wind turbine • purple.png';
import IconLayerDERElectricVehicleGreen from 'components/Map/icons/icon • map layer • DER • electric vehicle • green.png';
import IconLayerDERHeatPumpGreen from 'components/Map/icons/icon • map layer • DER • heat pump • green.png';
import IconLayerDERSolarPanelGreen from 'components/Map/icons/icon • map layer • DER • solar panel • green.png';
//
import IconExtendedGridTransformer from 'components/Map/icons/icon • extended grid • transformer.png';
import IconExtendedGridTransformerLow from 'components/Map/icons/icon • extended grid • transformer-low.png';
import IconExtendedGridTransformerMedium from 'components/Map/icons/icon • extended grid • transformer-medium.png';
import IconExtendedGridTransformerHigh from 'components/Map/icons/icon • extended grid • transformer-high.png';

export type IconType =
  | 'icon-bolt-blue'
  | 'icon-bolt-gray'
  | 'icon-bolt-purple'
  | 'icon-bolt-red'
  | 'icon-bolt-yellow'
  | 'icon-customer-blue'
  | 'icon-customer-gray'
  | 'icon-customer-purple'
  | 'icon-customer-red'
  | 'icon-customer-yellow'
  | 'icon-light-blue'
  | 'icon-light-gray'
  | 'icon-light-purple'
  | 'icon-light-red'
  | 'icon-light-yellow'
  | 'icon-link-blue'
  | 'icon-link-gray'
  | 'icon-link-purple'
  | 'icon-link-red'
  | 'icon-link-yellow'
  | 'icon-investment-3d'
  | 'icon-flexibility-3d'
  | 'icon-customer-battery'
  | 'icon-customer-electric-vehicle'
  | 'icon-customer-heat-pumps'
  | 'icon-customer-other-generation'
  | 'icon-customer-solar-panel'
  | 'icon-customer-wind-turbine'
  | 'icon-customer-electric-vehicle-ai'
  | 'icon-customer-heat-pumps-ai'
  | 'icon-customer-solar-panel-ai'
  | 'icon-extended-grid-transformer'
  | 'icon-extended-grid-transformer-low'
  | 'icon-extended-grid-transformer-medium'
  | 'icon-extended-grid-transformer-high';

const IconsMap: Record<IconType, string> = {
  'icon-bolt-blue': BoltBlueIcon,
  'icon-bolt-gray': BoltGrayIcon,
  'icon-bolt-purple': BoltPurpleIcon,
  'icon-bolt-red': BoltRedIcon,
  'icon-bolt-yellow': BoltYellowIcon,
  //
  'icon-customer-blue': CustomerBlueIcon,
  'icon-customer-gray': CustomerGrayIcon,
  'icon-customer-purple': CustomerPurpleIcon,
  'icon-customer-red': CustomerRedIcon,
  'icon-customer-yellow': CustomerYellowIcon,
  //
  'icon-light-blue': LightBlueIcon,
  'icon-light-gray': LightGrayIcon,
  'icon-light-purple': LightPurpleIcon,
  'icon-light-red': LightRedIcon,
  'icon-light-yellow': LightYellowIcon,
  //
  'icon-link-blue': LinkBlueIcon,
  'icon-link-gray': LinkGrayIcon,
  'icon-link-purple': LinkPurpleIcon,
  'icon-link-red': LinkRedIcon,
  'icon-link-yellow': LinkYellowIcon,
  //
  'icon-investment-3d': Investment3dIcon,
  'icon-flexibility-3d': Flexibility3dIcon,
  //
  'icon-customer-battery': IconLayerDERBatteryPurple,
  'icon-customer-electric-vehicle': IconLayerDERElectricVehiclePurple,
  'icon-customer-heat-pumps': IconLayerDERHeatPumpPurple,
  'icon-customer-other-generation': IconLayerDEROtherGenerationPurple,
  'icon-customer-solar-panel': IconLayerDERSolarPanelPurple,
  'icon-customer-wind-turbine': IconLayerDERWindTurbinePurple,
  'icon-customer-electric-vehicle-ai': IconLayerDERElectricVehicleGreen,
  'icon-customer-heat-pumps-ai': IconLayerDERHeatPumpGreen,
  'icon-customer-solar-panel-ai': IconLayerDERSolarPanelGreen,
  'icon-extended-grid-transformer': IconExtendedGridTransformer,
  'icon-extended-grid-transformer-low': IconExtendedGridTransformerLow,
  'icon-extended-grid-transformer-medium': IconExtendedGridTransformerMedium,
  'icon-extended-grid-transformer-high': IconExtendedGridTransformerHigh,
};

export default IconsMap;
