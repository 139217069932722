import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchTaskCommentsAction, postTaskCommentsAction, deleteTaskCommentsAction } from 'modules/tasks';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { FormInput, Button } from 'components/_common';
import { IconDelete, IconNavigation } from '@utiligize/shared/resources';

interface Props {
  taskId: number;
}

const TaskDetailsComments: React.FC<Props> = ({ taskId }) => {
  const { dateFormat } = useLocale();
  const [message, setMessage] = useState<string>('');
  const [commentsHash, setCommentsHash] = useState<Type.Hash<Tasks.Comment> | null>(null);
  const dispatch: Shared.CustomDispatch = useDispatch();

  useEffect(() => {
    if (!taskId) return;
    dispatch(fetchTaskCommentsAction(taskId)).then((action: Shared.ReduxAction<Type.Hash<Tasks.Comment>>) =>
      setCommentsHash(action.payload)
    );
  }, [taskId, dispatch]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setMessage(value);
  }, []);

  const handleAddCommentButtonClick = useCallback(() => {
    dispatch(postTaskCommentsAction(taskId, message))
      .then((action: Shared.ReduxAction<Tasks.Comment>) => {
        setCommentsHash({ ...commentsHash, [action.payload.id]: action.payload });
        setMessage('');
      })
      .catch(console.error);
  }, [dispatch, taskId, message, commentsHash]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent) => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: () => {
            return dispatch(deleteTaskCommentsAction(taskId, id)).then(() => {
              const nextCommentsHash = { ...commentsHash };
              delete (nextCommentsHash as any)[id];
              setCommentsHash(nextCommentsHash);
            });
          },
        })
      );
    },
    [dispatch, taskId, commentsHash]
  );

  return (
    <div className="timeline">
      <div>
        <i className="fas fa-user bg-green" />
        <div className="timeline-item">
          <div className="timeline-body">
            <div className="d-flex align-items-center">
              <FormInput placeholderKey="Type comment" value={message} onChange={handleInputChange} isNew size="lg" />
              <Button
                marginLeft
                icon={<IconNavigation />}
                onClick={handleAddCommentButtonClick}
                variant="primary"
                size="large"
              />
            </div>
          </div>
        </div>
      </div>
      {commentsHash &&
        Object.values(commentsHash)
          .reverse()
          .map((comment: Tasks.Comment) => (
            <div key={comment.id}>
              <i className="fas fa-comments bg-yellow" />
              <div className="timeline-item">
                <span className="time">
                  <i className="fas fa-clock" /> {dateFormat(comment.createdAt, 'L LT')}
                </span>
                <h3 className="timeline-header text-primary">{comment.createdBy.fullName}</h3>
                <div className="timeline-body">{comment.message}</div>
                <div className="timeline-footer text-right">
                  <Button icon={<IconDelete />} data-id={comment.id} onClick={handleDeleteBtnClick} />
                </div>
              </div>
            </div>
          ))}
      <div>
        <i className="fas fa-clock bg-gray" />
      </div>
    </div>
  );
};

export default TaskDetailsComments;
