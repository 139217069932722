import { createSelector } from 'reselect';
import { portfolioIdSelector, singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import { _pickBy } from '@utiligize/shared/utils';
import { mapDefaultState } from './index';
import { MapThemes, ThemeDisabledSettingsKey, MapThemeGroupsConfig } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------

export const rootSelector = (state: State.Root) => state.map;

export const mapLoadingSelector = createSelector(rootSelector, map => map.loading);

export const legendSelector = createSelector(rootSelector, map => map.legendData);

export const mapStateSelector = createSelector(rootSelector, map => map.mapState);

export const mapStateThemeGroupSelector = createSelector(mapStateSelector, mapState => mapState.themeGroup || null);

export const mapStateThemeSelector = createSelector(mapStateSelector, mapState => mapState?.theme || null);

export const mapStateCollapseGroupStateSelector = createSelector(
  mapStateSelector,
  mapState => mapState?.collapseGroupState || null
);

// {[ layerId: true | false ]}
export const mapStateEnabledLayersSelector = createSelector(mapStateSelector, mapState => mapState?.enabledLayers);

export const enabledLayersListSelector = createSelector(mapStateEnabledLayersSelector, enabledLayers =>
  Object.keys(_pickBy(enabledLayers || {}))
);

export const settingsSelector = createSelector(rootSelector, map => map.settings);

export const isMapConfigFetchedSelector = createSelector(rootSelector, map => map.isConfigFetched);

export const mapLayersSelector = createSelector(rootSelector, map => map.mapLayers);

export const dataQualityWarningSelector = createSelector(rootSelector, map => map.dataQualityWarning);

export const n1RouteSelector = createSelector(rootSelector, map => map.n1Route);

export const selectedN1RoutesSelector = createSelector(rootSelector, map => map.selectedN1Routes);

export const selectedN1RouteIdSelector = createSelector(rootSelector, map => map.selectedN1RouteId);

export const showAddressSearchSelector = createSelector(rootSelector, map => map.showAddressSearch);

export const simulationsSelector = createSelector(
  [rootSelector, portfolioIdSelector],
  (map, portfolioId) => map.simulationsHash[String(portfolioId)] || null
);

export const investmentScenariosSelector = createSelector(rootSelector, map => map.investmentScenarios);

export const selectedSimulationSelector = createSelector(
  [rootSelector, simulationsSelector],
  // selectedSimulation stored locally, so fetchSimulationsAction can trigger selectedSimulation update on tenant changed event
  (map, simulations) => simulations && map.selectedSimulation
);

export const selectedInvestmentScenarioSelector = createSelector(rootSelector, map => map.selectedInvestmentScenario);

export const showCableAnimationSelector = createSelector(rootSelector, map => map.showCableAnimation);

export const showLabelsSelector = createSelector(rootSelector, map => map.showLabels);

export const createMapStateSelectorFactory = <K extends keyof Map.MapState>(prop: K) => {
  return createSelector(rootSelector, (map: Map.Root): Map.MapState[K] => {
    const state = map.mapState;
    return state?.[prop] ?? (mapDefaultState[prop]?.(map) as Map.MapState[K]);
  });
};

export const mapCheckedFiltersSelectorFactory = (filterKey: 'filter_voltage') =>
  createSelector([rootSelector, settingsSelector], (map, settings) => {
    const { layerFilters = {} } = map.mapState || {};

    const assetFilters = Object.keys(layerFilters).reduce(
      (acc, k) => {
        if (k.startsWith('asset__')) acc[k] = layerFilters[k];
        return acc;
      },
      {} as Record<string, Map.LayerFilter>
    );

    const getFilterState = (id: number | string) => {
      const keys = Object.keys(assetFilters);
      const f = (k: string) => assetFilters[k];
      const isChecked = keys.some(k => f(k)?.list.includes(id));
      const isDisabled = !keys.some(k => f(k)?.initList.some(i => i.id === id));
      return { isChecked, isDisabled };
    };

    return settings?.globalFilters?.[filterKey]?.list.filter(filter => getFilterState(filter.id).isChecked);
  });

export const primarySubstationsListSelector = createSelector(
  [settingsSelector, singleDiagramEnabledSelector],
  (settings, singleDiagramEnabled): Map.StyleLayerFilterItem[] => {
    const list = settings?.globalFilters?.filter_primary_substations?.list;
    if (list && singleDiagramEnabled) return list.filter(option => option.name !== '150_top_node');
    return list || [];
  }
);

export const bottomTabsChartsNextYearValueSelector = createSelector(mapStateSelector, (mapState): number | null => {
  if (mapState.theme === MapThemes.YEARLY_MAX_LOAD) return mapState.scenario?.year || null;
  if (mapState.theme === MapThemes.YEARLY_CONSUMPTION) return mapState.consumptionScenario?.year || null;
  if (mapState.theme === MapThemes.YEARLY_LOSSES) return mapState.lossesScenario?.year || null;
  return null;
});

export const otherLegendSelector = createSelector(
  [legendSelector, settingsSelector, enabledLayersListSelector, mapLayersSelector],
  (legend, settings, enabledLayersList, mapLayers) => {
    const checkedFilters = [
      ...new Set(
        enabledLayersList
          .filter(id => settings.otherLayers!.includes(id))
          .map(id => settings.layerTitle?.[id])
          .filter(Boolean)
      ),
    ];

    return { legend: legend.other, checkedFilters, disabled: settings.isOtherGroupDisabled, mapLayers };
  }
);

export const cnaimLegendSelector = createSelector(
  [legendSelector, settingsSelector, enabledLayersListSelector, mapLayersSelector],
  (legend, settings, enabledLayersList, mapLayers) => {
    const checkedFilters = [
      ...new Set(
        enabledLayersList
          .filter(id => settings.cnaimLayers!.includes(id))
          .map(id => settings.layerTitle?.[id])
          .filter(Boolean)
      ),
    ];

    return { legend: legend.cnaim, checkedFilters, disabled: settings.isCNAIMGroupDisabled, mapLayers };
  }
);

export const firstAvailableThemeInTheGroupSelectorFactory = (themeGroup: Map.ThemeGroups | null) =>
  createSelector(settingsSelector, settings => {
    if (!themeGroup) return null;
    const activeThemes = (themeGroup ? MapThemeGroupsConfig[themeGroup] : []).filter(
      t => !settings[ThemeDisabledSettingsKey[t]]
    );
    return activeThemes[0] || null;
  });

export const mapDrawAssetFeaturesSelector = createSelector(rootSelector, map => map.mapDrawAssetFeatures);

export const mapDrawAssetFeatureSelectorFactory = (id: string | null) =>
  createSelector(mapDrawAssetFeaturesSelector, mapDrawAssetFeatures =>
    mapDrawAssetFeatures?.find(feature => feature.id === id)
  );
