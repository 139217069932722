import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchDERsCategoriesAction, deleteDERsSectionCategoryAction } from 'modules/newLoad';
import { DERsCountSelector, DERsCategoriesSplitBySectionHashSelector } from 'modules/newLoad/selectors';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const TableDERsCategories: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(DERsCountSelector);
  const DERsCategoriesSplitBySectionHash = useSelector(DERsCategoriesSplitBySectionHashSelector);
  const keys = Object.keys(DERsCategoriesSplitBySectionHash);

  const sendRequest = useCallback(() => dispatch(fetchDERsCategoriesAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setLayoutAction({ newLoadDERsCategoriesModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteDERsSectionCategoryAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.NEW_LOAD_DERS_CATEGORIES}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 207px)"
    >
      {keys?.map((key: string) => {
        const DERCategories: NewLoad.DERCategory[] = DERsCategoriesSplitBySectionHash[key];
        return (
          <React.Fragment key={key}>
            <tr>
              <td colSpan={2}>
                <b>
                  {getIntl('Section')} - {key}
                </b>
              </td>
            </tr>
            {DERCategories.map((item: NewLoad.DERCategory) => (
              <tr key={item.id}>
                <td>{item.category || '-'}</td>
                <td className="text-right">
                  <Button
                    tooltipKey="Edit"
                    icon={<IconEdit />}
                    data-id={item.id}
                    onClick={handleEditBtnClick}
                    size="small"
                    variant="primary"
                  />
                  <Button
                    marginLeft
                    tooltipKey="Delete"
                    icon={<IconDelete />}
                    data-id={item.id}
                    onClick={handleDeleteBtnClick}
                    size="small"
                    variant="primary"
                  />
                </td>
              </tr>
            ))}
          </React.Fragment>
        );
      })}
    </DataTable>
  );
};

export default TableDERsCategories;
