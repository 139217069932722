import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { newLoadDERProfileModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { DERSelectorFactory, individualCustomersSelectorFactory } from 'modules/newLoad/selectors';
import { Modal, Tabs, Tab } from 'components/_common';
import ChartDERProfile from './ChartDERProfile';
import ChartDERPhaseIn from './ChartDERPhaseIn';
import { Routes } from 'constants/index';

const ModalProfile: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [show, setShow] = useState(false);

  const id: number | null = useSelector(newLoadDERProfileModalIdSelector);
  const isConnectionManagerTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadConnectionManager));
  const isProfilesTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadProfiles));
  const isPhaseInTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadPhaseIn));
  const DER: NewLoad.DER | undefined = useSelector(DERSelectorFactory(id));
  const IndividualCustomer: NewLoad.IndividualCustomer | undefined = useSelector(
    individualCustomersSelectorFactory(id)
  );

  const { infoId, phaseInId } = useMemo(() => {
    if (isConnectionManagerTabActive) {
      return { infoId: IndividualCustomer?.der_profile_info_id, phaseInId: null };
    }
    if (isProfilesTabActive) {
      return { infoId: id, phaseInId: null };
    }
    if (isPhaseInTabActive) {
      return { infoId: null, phaseInId: id };
    }
    return { infoId: DER?.der_profile_info_id, phaseInId: DER?.der_phase_in_id };
  }, [
    isConnectionManagerTabActive,
    IndividualCustomer?.der_profile_info_id,
    isProfilesTabActive,
    isPhaseInTabActive,
    DER?.der_profile_info_id,
    DER?.der_phase_in_id,
    id,
  ]);

  useEffect(() => {
    if (id && (infoId || phaseInId)) setShow(true);
  }, [id, infoId, phaseInId]);

  const handleCancelBtnClick = useCallback((): void => {
    setShow(!show);
    setTimeout(() => dispatch(setLayoutAction({ newLoadDERProfileModalId: null })), 500);
  }, [show, dispatch]);

  const renderTabs = () => {
    if (infoId && !phaseInId) {
      return <ChartDERProfile id={infoId} />;
    }
    if (phaseInId && !infoId) {
      return <ChartDERPhaseIn id={phaseInId} />;
    }
    return (
      <Tabs mountOnEnter defaultActiveKey="profile">
        <Tab eventKey="profile" title={getIntl('Profile')}>
          <ChartDERProfile id={infoId!} />
        </Tab>
        <Tab eventKey="phase-in" title={getIntl('Phase-in')}>
          <ChartDERPhaseIn id={phaseInId!} />
        </Tab>
      </Tabs>
    );
  };

  return (
    <Modal
      size="xl"
      show={show}
      backdrop
      onHide={handleCancelBtnClick}
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCancelBtnClick }}
    >
      {renderTabs()}
    </Modal>
  );
};

export default ModalProfile;
