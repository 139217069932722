import React, { useState, useEffect } from 'react';
import { useLocale } from 'hooks';
import { AssetLifeAPI } from 'constants/index';
import { useSelector } from 'react-redux';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';

const DutyFactor: React.FC<{ uuid: string | null; className?: string }> = ({ uuid, className }) => {
  const { getIntl, lng } = useLocale();
  const [data, setData] = useState<{ duty_factor_1: number; duty_factor_2: number } | null>(null);

  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  useEffect(() => {
    if (!uuid || !portfolioId || !scenarioId) return;
    AssetLifeAPI.get('/load/duty_factor', {
      params: {
        portfolio_id: portfolioId,
        scenario_id: scenarioId,
        ...(Boolean(simulationId) && { simulation_id: simulationId }),
        id: uuid,
      },
    })
      .then((res: any) => setData(res.data))
      .catch(console.error);
  }, [uuid, lng, portfolioId, scenarioId, simulationId]);

  if (!data || (!data.duty_factor_1 && !data.duty_factor_2)) return null;
  return (
    <div className={className}>
      {data.duty_factor_1 && (
        <small>
          {getIntl('Duty factor 1')} - <b>{String(data.duty_factor_1)}</b>;{' '}
        </small>
      )}
      {data.duty_factor_2 && (
        <small>
          {getIntl('Duty factor 2')} - <b>{String(data.duty_factor_2)}</b>;
        </small>
      )}
    </div>
  );
};

export default DutyFactor;
