import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { Routes } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.assets;

export const categoriesSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Asset.Root['categories'] => assets.categories
);

export const subComponentsSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Asset.Root['subComponents'] => assets.subComponents
);

export const smartMetersHashFetchedSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): boolean => assets.smartMetersHashFetched
);

const smartMetersHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.SmartMeter> => assets.smartMetersHash
);

export const smartMetersSelector = createSelector(
  smartMetersHashSelector,
  (smartMetersHash: Type.Hash<Asset.SmartMeter>): Asset.SmartMeter[] => Object.values(smartMetersHash)
);

export const smartMetersSelectorFactory = (id: number | null) =>
  createSelector(
    smartMetersHashSelector,
    (smartMetersHash: Type.Hash<Asset.SmartMeter>): Asset.SmartMeter => smartMetersHash[`_${id}_`]
  );

export const taskCompletionsSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Tasks.TaskCompletion[] => assets.taskCompletions
);

export const assetMetaSelector = createSelector(
  [createMatchSelector({ path: Routes.Map }) as any, rootSelector],
  (match: any, assets: Asset.Root): Asset.Root['assetMeta'] => {
    // UAV, must reset asset meta immediately on uuid change event
    return match?.params?.uuid === assets.assetMeta?.id ? assets.assetMeta : null;
  }
);
