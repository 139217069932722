import { createAction } from 'redux-actions';
import queryString from 'query-string';
import { appLangSelector, userNameSurnameEmailStringSelector } from 'modules/app/selectors';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { PaginationType, AssetLifeAPI, plumberAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchIssuesAction: any = createAction(
  'data-quality/FETCH_ISSUES',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<DataQuality.Root, 'issuesCount' | 'issues' | 'issuesLastUpdate'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ISSUES)(state);
      return AssetLifeAPI.get('data_quality/issues', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          issue_status: filters?.dataQualityIssuesStatus,
          voltage_level_ids: filters?.voltageLevels || undefined,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then((res: any) => ({
        issuesCount: res.data.count,
        issues: res.data.rows,
        issuesLastUpdate: res.data.meta.timestamp,
        skipStoreUpdate,
      }));
    }
);

export const setIssueIgnoredAction = createAction(
  'data-quality/SET_ISSUE_IGNORED',
  async ({ issueId, ignored, explanation }: { issueId: string; explanation: string; ignored: boolean }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      const userNameSurnameEmailString = userNameSurnameEmailStringSelector(state);
      return AssetLifeAPI.put('data_quality/issue', null, {
        params: {
          issue_id: issueId,
          new_status: Number(ignored),
          username: userNameSurnameEmailString,
          explanation,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
        },
      }).then(async (res: any) => {
        await dispatch(fetchIssuesAction());
        dispatch(setSuccessToastAction('Action has been saved'));
      });
    }
);

// ------------------------------------
// CUSTOMERS CONNECTED
// ------------------------------------
export const fetchCustomersConnectedAction: any = createAction(
  'data-quality/FETCH_CUSTOMERS_CONNECTED',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<DataQuality.Root, 'customersConnectedCount' | 'customersConnected'>> => {
      const state: State.Root = getState();
      const simulationId = simulationIdSelector(state);
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.CUSTOMERS_CONNECTED)(
        state
      );
      return plumberAPI
        .get('installations_connected_by_primary_substation', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? '' : query,
            lang: appLangSelector(state).toLowerCase(),
            simulation_id: simulationId,
          },
        })
        .then((res: any) => ({
          customersConnectedCount: res.data.count,
          customersConnected: res.data.rows,
          skipStoreUpdate,
        }));
    }
);

// ------------------------------------
// METERS
// ------------------------------------
export const fetchMetersMissingInMDMAction: any = createAction(
  'data-quality/FETCH_METERS_MISSING_IN_MDM',
  async (
    {
      skipPagination,
      skipStoreUpdate,
      portfolioId,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
      portfolioId: Layouts.Root['portfolioId'];
    } = {} as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<DataQuality.Root, 'missingInMDMCount' | 'missingInMDM'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.METERS_MISSING_IN_MDM)(
        state
      );
      return AssetLifeAPI.get('data_quality/meters_missing_in_mdm', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          portfolio_id: portfolioId,
        },
      }).then((res: any) => ({
        missingInMDMCount: res.data.count,
        missingInMDM: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchMetersMissingInGISAction: any = createAction(
  'data-quality/FETCH_METERS_MISSING_IN_GIS',
  async (
    {
      skipPagination,
      skipStoreUpdate,
      portfolioId,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
      portfolioId: Layouts.Root['portfolioId'];
    } = {} as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<DataQuality.Root, 'missingInGISCount' | 'missingInGIS'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.METERS_MISSING_IN_GIS)(
        state
      );
      return AssetLifeAPI.get('data_quality/meters_missing_in_gis', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          portfolio_id: portfolioId,
        },
      }).then((res: any) => ({
        missingInGISCount: res.data.count,
        missingInGIS: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchMissingAgeAction: any = createAction(
  'data-quality/FETCH_MISSING_AGE',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<DataQuality.Root, 'missingAgeItemsCount' | 'missingAgeItems'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.DATA_QUALITY_MISSING_AGE
      )(state);
      return AssetLifeAPI.get('/data_quality/missing_age', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          cnaim_id: filters?.cnaim_id,
          portfolio_id: portfolioIdSelector(state),
          version_id: simulationSelectedOptionVersionIdSelector(state),
        },
      }).then((res: any) => ({
        missingAgeItemsCount: res.data.count,
        missingAgeItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const getAgeDistributionChartData = createAction(
  'data-quality/GET_AGE_DISTRIBUTION_CHART_DATA',
  ({ portfolioId, versionId, cnaim_id }: { portfolioId: number; versionId: number; cnaim_id: number }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x' | 'categories'> | null> => {
      return AssetLifeAPI.get('data_quality/age_distribution_plot', {
        params: { portfolio_id: portfolioId, version_id: versionId, cnaim_id },
      }).then(res => res.data);
    }
);
