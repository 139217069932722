import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { formsItemsSelector, builderDataSelector, formsCountSelector } from 'modules/forms/selectors';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { fetchFormsAction, fetchFormSnapshotAction, deleteFormAction } from 'modules/forms';
import { DataTable, Button } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformBlocks, getFieldTypesArray } from 'utils';
import { Routes, PaginationType } from 'constants/index';
import { IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const FormsTable: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: any = useDispatch();

  const count = useSelector(formsCountSelector);
  const items = useSelector(formsItemsSelector);
  const builderData: Builder.Data = useSelector(builderDataSelector);

  const sendRequest = useCallback(() => dispatch(fetchFormsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const builderDataCopy: Builder.Data = _cloneDeep(builderData);

      const formItem = items.find((elem: Forms.Item) => elem.id === id);
      if (!formItem) throw Error('formItem not found');

      const { formtypeId } = formItem;
      const fieldTypes = getFieldTypesArray(builderDataCopy, formtypeId);

      const formtype = builderDataCopy.formTypes.find((elem: Type.FormType) => elem.id === formtypeId);
      if (!formtype) throw Error('formtype not found');

      const selectValue: Builder.SelectValue = {
        id: formItem.id,
        name: formItem.name,
        assetCategories: formItem.assetcategory,
        assetCodes: formItem.assetCodes,
        modelIds: formItem.assignedModels.map(i => i.modelId),
        formSubcomponents: formItem.formsubcomponent,
        formtype,
        timestampOptions: formItem.timestampOptions || [],
      };

      dispatch(fetchFormSnapshotAction(formItem.id)).then((action: any) => {
        dispatch(
          push(Routes.FormBuilder, {
            isNew: false,
            isDuplicate: false,
            selectValue,
            blocks: transformBlocks(action.payload.lastSnapshot.blocks),
            snapshots: action.payload.snapshots,
            fieldTypes,
            formTypes: builderDataCopy.formTypes,
          } as Builder.LocationState)
        );
      });
    },
    [items, builderData, dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ formsModalId: id }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteFormAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.FORMS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 239px)"
    >
      {items?.map((item: Forms.Item) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>{getIntl(item.formtype.name)}</td>
          <td>
            {[
              item.assetcategory?.name && getIntl(item.assetcategory?.name),
              ...item.assetCodes,
              ...item.assignedModels.map(i => i.modelName),
            ]
              .filter(Boolean)
              .join(', ') || '-'}
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.id}
              onClick={handleDuplicateBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default FormsTable;
