import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { settingsSelector } from 'modules/map/selectors';
import { scenarioIdSelector } from 'modules/layouts/selectors';
import { mapStateAction } from 'modules/map';
import { MapThemes } from 'constants/index';

const SetupScenarios: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const settings = useSelector(settingsSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const history = useHistory<{ year: number; theme: MapThemes.YEARLY_REPLACEMENTS | MapThemes.YEARLY_MAX_LOAD }>();
  const { year, theme } = history.location.state || {};
  const { scenarioYear, replacementsScenarioYear } = useMemo(
    () => ({
      scenarioYear: (theme === MapThemes.YEARLY_MAX_LOAD && year) || null,
      replacementsScenarioYear: (theme === MapThemes.YEARLY_REPLACEMENTS && year) || null,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const generateMapScenario = useCallback(
    (scenarioId: Layouts.ScenarioId, scenarios: Exclude<typeof settings.scenarios, undefined>) => {
      const selectedId = scenarioId ?? -1;
      const years0 = scenarios[0] ?? [];
      const yearsSelected = scenarios[selectedId] ?? [];
      const years = [...years0, ...yearsSelected];
      const year = years[0] ?? 0;
      const yearEnd = years[years.length - 1] ?? 0;
      const yearRange = [year, yearEnd];
      const id = years0.includes(year) ? 0 : selectedId;
      const key = `${id}_${year}`;
      return { id, year, yearRange, years, years0, yearsSelected, key, selectedId };
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    // Setup scenarios only once on scenarioId selector change
    if (!scenarioId) return;
    const modifier = {} as Partial<Map.MapState>;
    if (settings.scenarios) {
      const s = generateMapScenario(scenarioId, settings.scenarios);
      // Note. TableIndividualCustomers can pass year to preselect the slider
      modifier.scenario = scenarioYear
        ? {
            ...s,
            key: `${s.id}_${scenarioYear}`,
            year: scenarioYear,
          }
        : s;
    }
    if (settings.consumptionScenarios) {
      modifier.consumptionScenario = generateMapScenario(scenarioId, settings.consumptionScenarios);
    }
    if (settings.lossesScenarios) {
      modifier.lossesScenario = generateMapScenario(scenarioId, settings.lossesScenarios);
    }
    if (settings.n1MaxLoadScenarios) {
      modifier.n1MaxLoadScenario = generateMapScenario(scenarioId, settings.n1MaxLoadScenarios);
    }
    if (settings.replacementsScenarios) {
      const s = generateMapScenario(scenarioId, settings.replacementsScenarios);
      // Note. TableDetailedInvestments can pass replacement year to preselect the range slider
      modifier.replacementsScenario = replacementsScenarioYear
        ? {
            ...s,
            yearRange: [replacementsScenarioYear, replacementsScenarioYear],
            key: `${s.id}_${replacementsScenarioYear}`,
            year: replacementsScenarioYear,
          }
        : s;
    }
    if (settings.heatmapScenarios) {
      modifier.heatmapScenario = generateMapScenario(scenarioId, settings.heatmapScenarios);
    }
    if (Object.keys(modifier).length) dispatch(mapStateAction(modifier));
  }, [
    scenarioId,
    settings.scenarios,
    settings.consumptionScenarios,
    settings.lossesScenarios,
    settings.n1MaxLoadScenarios,
    settings.replacementsScenarios,
    settings.heatmapScenarios,
    dispatch,
    generateMapScenario,
    scenarioYear,
    replacementsScenarioYear,
  ]);

  return null;
};

export default SetupScenarios;
