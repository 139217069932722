import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mapStateAction } from 'modules/map';
import { MapThemes } from 'constants/index';

const SetupN1Route: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  useEffect(() => {
    dispatch(mapStateAction({ themeGroup: 'forecast_and_investment', theme: MapThemes.N1_ROUTES }));
  }, [dispatch]);

  return null;
};

export default SetupN1Route;
