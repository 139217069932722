import styled from 'styled-components';
import classNames from 'classnames';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import {
  mapStateCollapseGroupStateSelector,
  mapStateThemeGroupSelector,
  mapStateThemeSelector,
} from 'modules/map/selectors';

interface Props {
  id: string;
  titleKey: string;
  description?: React.ReactNode;
  stateLoc?: 'global' | 'theme' | 'themeGroup';
  nested?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  selectAllNode?: React.ReactNode;
  disabled?: boolean;
  alwaysOpen?: boolean;
  onStateChange?: (state: boolean) => void;
  dataMarker?: string;
}

const CollapseGroup: React.FC<Props> = ({
  id,
  titleKey,
  description,
  stateLoc: loc = 'global',
  nested = false,
  icon,
  children,
  selectAllNode,
  disabled,
  alwaysOpen = false,
  onStateChange,
  dataMarker,
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const collapseGroupState = useSelector(mapStateCollapseGroupStateSelector);
  const theme = useSelector(mapStateThemeSelector);
  const themeGroup = useSelector(mapStateThemeGroupSelector);

  const state = useMemo(() => {
    const states: Record<typeof loc, boolean> = {
      theme: theme === (id as Map.Themes),
      themeGroup: themeGroup === id,
      global: collapseGroupState[id],
    };
    return alwaysOpen || states[loc];
  }, [id, loc, theme, themeGroup, collapseGroupState, alwaysOpen]);

  const updateValue = useCallback(() => {
    const v = !state;
    if (disabled) return;
    if (loc === 'theme') {
      dispatch(mapStateAction({ theme: (v ? id : null) as Map.Themes }));
    } else if (loc === 'global') {
      dispatch(mapStateAction({ collapseGroupState: { [id]: v } }));
    } else if (loc === 'themeGroup') {
      dispatch(mapStateAction({ themeGroup: v ? (id as Map.ThemeGroups) : null }));
    }
  }, [dispatch, id, loc, state, disabled]);

  useEffect(() => {
    onStateChange?.(state);
  }, [onStateChange, state]);

  return (
    <>
      <StyledCollapseGroupTitle
        $alwaysOpen={alwaysOpen}
        className={classNames('group-title', { open: state, disabled })}
        onClick={updateValue}
      >
        <div className="w-100 d-flex align-items-center justify-content-between" data-marker={dataMarker}>
          {icon}
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              {Boolean(selectAllNode) && (
                <StyledSelectAllNodeContainer $isOpen={Boolean(state)}>{selectAllNode}</StyledSelectAllNodeContainer>
              )}
              <span className="legend-collapse-text">{getIntl(titleKey)}</span>
            </div>
            {Boolean(description) && (
              <div className={classNames('group-title-description', { open: !state })}>
                <div className="group-title-description-inner">
                  <div className="description">{description}</div>
                </div>
              </div>
            )}
          </div>
          {!alwaysOpen && (
            <i className={classNames('fa fa-angle-left legend-collapse-icon', { open: state })} aria-hidden="true" />
          )}
        </div>
      </StyledCollapseGroupTitle>
      <div className={classNames('legend-collapse-group', { nested, open: state })}>
        <div className="legend-collapse-group-inner">
          <div>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledCollapseGroupTitle = styled.div<{ $alwaysOpen: boolean }>`
  user-select: none;
  cursor: ${props => (props.$alwaysOpen ? 'default' : 'pointer')};
  position: relative;
  transition:
    color 0.2s linear,
    background-color 0.2s linear;

  &.open {
    color: var(--map-active-color);

    svg path {
      fill: var(--map-active-color);
    }
  }

  &:hover:not(.open) {
    &:not(.disabled) {
      color: var(--map-active-color);

      svg path {
        fill: var(--map-active-color);
      }

      .legend-collapse-icon {
        color: var(--map-active-color);

        & span {
          background-color: var(--map-active-color) !important;
        }
      }
    }

    &.disabled {
      color: var(--map-text-color-secondary-dark);

      svg path {
        fill: var(--map-text-color-secondary-dark);
      }

      .legend-collapse-icon {
        color: var(--map-text-color-secondary-dark);

        & span {
          background-color: var(--map-text-color-secondary-dark) !important;
        }
      }
    }
  }
`;

const StyledSelectAllNodeContainer = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'inline-flex' : 'none')};
  margin-right: 10px;
`;

export default React.memo(CollapseGroup);
