import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.app;

export const appLangSelector = createSelector(rootSelector, (app: App.Root): App.Languages => app.lang);

export const userNameSurnameEmailStringSelector = createSelector(
  rootSelector,
  (app: App.Root): string => `${app.firstName} ${app.lastName} (${app.email})`
);

export const appCurrentUserIdSelector = createSelector(rootSelector, (app: App.Root): number | null => app.id);

export const appCurrentUserFetchedSelector = createSelector(
  rootSelector,
  (app: App.Root): boolean | null => app.appCurrentUserFetched
);

export const appSidebarHelpMenuPageSelectorFactory = (pageId: number | null) =>
  createSelector(
    rootSelector,
    (app: App.Root): App.SidebarHelpMenuPage | null => (pageId && app.sidebarHelpMenuPagesHash?.[pageId]) || null
  );

export const appIsAdminUserSelector = createSelector(rootSelector, (app: App.Root): boolean => app.isAdmin);

export const appIsSupervisorUserSelector = createSelector(rootSelector, (app: App.Root): boolean => app.isSupervisor);

export const externalTimeSystemSelector = createSelector(
  rootSelector,
  (app: App.Root): boolean => app.externalTimeSystem
);

export const appCurrentUserPermissionsSelector = createSelector(
  rootSelector,
  (app: App.Root): Permissions.Permissions => {
    return {
      ...app.groupPermissions.reduce(
        (acc, group) => {
          Object.keys(group.permissionsTypes).forEach((key: string) => {
            if (group.permissionsTypes[key as Permissions.PermissionsTypes]) {
              acc[key as Permissions.PermissionsTypes] = group.permissionsTypes[key as Permissions.PermissionsTypes];
            }
          });
          return acc;
        },
        { ...app.userPermissions }
      ),
    };
  }
);

export const isMaintenancePlanningEnabledSelector = createSelector(
  appCurrentUserPermissionsSelector,
  (appCurrentUserPermissions: Permissions.Permissions): boolean =>
    appCurrentUserPermissions.isTasksEnabled ||
    appCurrentUserPermissions.isSmartMetersEnabled ||
    appCurrentUserPermissions.isMaintenanceEnabled ||
    appCurrentUserPermissions.isAssetModelsEnabled ||
    appCurrentUserPermissions.isFormsEnabled ||
    appCurrentUserPermissions.isHistoryEnabled ||
    appCurrentUserPermissions.isDataManualsEnabled
);

export const appErrorSelector = createSelector(rootSelector, (app: App.Root): App.AppErrorsTypes | null => app.error);

export const selectedTimezoneSelector = createSelector(
  rootSelector,
  (app: App.Root): App.Root['selectedTimezone'] => app.selectedTimezone
);

export const currentAppReleaseVersionSelector = createSelector(
  rootSelector,
  (app: App.Root): App.Root['currentAppReleaseVersion'] => app.currentAppReleaseVersion
);
