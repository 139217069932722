import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { metersMissingInMDMCountSelector, metersMissingInGISCountSelector } from 'modules/dataQuality/selectors';
import TableMissingInMDM from './TableMissingInMDM';
import TableMissingInGIS from './TableMissingInGIS';
import { DataTableTemplate, Tabs, Tab } from 'components/_common';
import { Routes } from 'constants/index';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';

const ViewMeters: React.FC = () => {
  const { getIntl } = useLocale();
  const metersMissingInMDMCount = useSelector(metersMissingInMDMCountSelector);
  const metersMissingInGISCount = useSelector(metersMissingInGISCountSelector);
  const isMissingInMDMTabActive = useSelector(isRouteActiveSelectorFactory(Routes.DataQualityMeters));

  return (
    <DataTableTemplate>
      <Row>
        <Col xs="auto" className="d-flex ml-auto">
          <small className="text-muted">
            {getIntl(
              isMissingInMDMTabActive
                ? 'There are {{count}} meters in GIS that have no observations in the metering database over the last 14 days'
                : 'There are {{count}} meters with observations from the last 14 days in the metering database that are not in the GIS database (or marked as inactive)',
              {
                count: (isMissingInMDMTabActive ? metersMissingInMDMCount : metersMissingInGISCount) || '-',
              }
            )}
          </small>
        </Col>
      </Row>
      <Tabs mountOnEnter unmountOnExit>
        <Tab id="tab__meters_missing_in_mdm" eventKey={Routes.DataQualityMeters} title={getIntl('Missing in MDM')}>
          <TableMissingInMDM />
        </Tab>
        <Tab
          id="tab__meters_missing_in_gis"
          eventKey={`${Routes.DataQualityMeters}${Routes.HASH.GIS}`}
          title={getIntl('Missing in GIS')}
        >
          <TableMissingInGIS />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewMeters;
