import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { assetModelsItemsSelector, assetModelsCountSelector } from 'modules/assetModels/selectors';
import { fetchAssetModelsAction, deleteAssetModelAction } from 'modules/assetModels';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import ReplacementPriority from './ReplacementPriority';
import ReplacementOption from './ReplacementOption';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const AssetModelsTable: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(assetModelsCountSelector);
  const items = useSelector(assetModelsItemsSelector);

  const sendRequest = useCallback(() => dispatch(fetchAssetModelsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ assetModelsModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteAssetModelAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.ASSET_MODELS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 239px)"
    >
      {items?.map((item: AssetModels.Item) => (
        <tr key={item.modelId}>
          <td>{dateFormat(item.createdAt)}</td>
          <td>{item.manufacturerName}</td>
          <td>{getIntl(item.modelName)}</td>
          <td className="text-center">{item.assetCount}</td>
          <td className="text-center">{item.instructionCount}</td>
          <td className="text-center">{item.dataManualCount}</td>
          <td className="text-center">{item.lastReplacementDate ? dateFormat(item.lastReplacementDate) : '-'}</td>
          <td className="text-center">
            <ReplacementOption modelId={item.modelId} replacementOption={Boolean(item.replacementOption)} />
          </td>
          <td className="text-center text-nowrap">
            <ReplacementPriority modelId={item.modelId} replacementPriority={item.replacementPriority} />
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.modelId}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.modelId}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default AssetModelsTable;
