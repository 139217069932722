import styled from 'styled-components';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { releaseNotificationModalVersionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';
import { currentAppReleaseVersionSelector } from 'modules/app/selectors';

const ModalReleaseNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const releaseNotificationModalVersion = useSelector(releaseNotificationModalVersionSelector);
  const currentAppReleaseVersion = useSelector(currentAppReleaseVersionSelector);
  const isShow = currentAppReleaseVersion > Number(releaseNotificationModalVersion);

  useEffect(() => {
    if (isShow) setShow(true);
  }, [isShow]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ releaseNotificationModalVersion: currentAppReleaseVersion })), 500);
  }, [dispatch, currentAppReleaseVersion]);

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      title={
        <span>
          &#127881; {getIntl("What's new in version {{version}}", { version: process.env.REACT_APP_VERSION! })}
        </span>
      }
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <>
        <p>{getIntl('Discover the latest enhancements and features in our web app')}:</p>

        <ReleaseNotesList>
          <figcaption>&#128640; {getIntl('Features')}:</figcaption>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Forecast & Investment:</figcaption>
          <ul>
            <li>"New load &gt; Connection manager table" - Show on map - action button.</li>
            <li>"Data quality &gt; Issues table" - New voltage multi select table filter.</li>
          </ul>
        </ReleaseNotesList>

        <ReleaseNotesList>
          <figcaption>&#128736; {getIntl('Improvements')}:</figcaption>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Forecast & Investment:</figcaption>
          <ul>
            <li>"New load &gt; Connection manager" - Individual customer form, improved validation rules.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Map:</figcaption>
          <ul>
            <li>"Unified asset view - Reliability" - migrated to Python API end-points.</li>
            <li>"Single line diagram" - Highlight and fly to selected asset animation.</li>
            <li>"Single line diagram" - Various visual and logical improvements.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Admin:</figcaption>
          <ul>
            <li>"Enable single line diagram" - new permission.</li>
            <li>"Enable CO₂e menu" - new permission.</li>
          </ul>
        </ReleaseNotesList>

        <ReleaseNotesList>
          <figcaption>&#128030; {getIntl('Fixes')}:</figcaption>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Forecast & Investment:</figcaption>
          <ul>
            <li>"Network loading &gt; Transformers / Cables" - Fetch data only on active tab.</li>
            <li>"Data quality &gt; Issues table" - Download excel button error.</li>
          </ul>
          <figcaption style={{ marginTop: '10px', marginLeft: '10px' }}>- Map:</figcaption>
          <ul>
            <li>"Yearly replacements" - Display assets based on suggested replacement year.</li>
          </ul>
        </ReleaseNotesList>

        <p>{getIntl('Thank You for Your Collaboration')}! &#128578;</p>
        <p>
          {getIntl(
            'We appreciate your continued feedback, which help us improve and grow. Stay tuned for more exciting updates and features. Together, we’re making great things happen'
          )}
          !
        </p>
      </>
    </Modal>
  );
};

const ReleaseNotesList = styled.figure`
  margin-left: 20px;
  > ul {
    line-height: 2;
    margin-bottom: 0;
  }
`;

export default ModalReleaseNotification;
