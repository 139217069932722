import * as d3 from 'd3';
import { useEffect, RefObject, useState } from 'react';
import { SingleLineDiagramTypes } from 'constants/index';
import { _snakecase } from '@utiligize/shared/utils';
import { DefaultCircleRadius, VoltageFontSizeMap } from './constants';

interface ContentRect {
  width: number;
  height: number;
}

export const useResizeObserver = (ref: RefObject<SVGSVGElement>) => {
  const [dimensions, setDimensions] = useState<ContentRect | null>(null);
  useEffect(() => {
    if (!ref.current) return;

    const observeTarget = ref.current;

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => setDimensions(entry.contentRect));
    });
    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

export const setupGraph = ({
  data,
  type,
  width,
  height,
}: {
  data: SingleLineDiagram.ResponseData;
  type: SingleLineDiagram.Types;
  width: number;
  height: number;
}) => {
  const linksArray: SingleLineDiagram.Edge[] = [];
  const projection = d3
    .geoMercator()
    .scale(100)
    .translate([width / 2, height / 2]);

  // find double edges and offset them by delta
  const mapOfLinks: { [key: string]: number } = {};

  data.edges.forEach((edge, index) => {
    if (data.vertices_names.includes(edge.from) && data.vertices_names.includes(edge.to)) {
      edge.source = data.vertices.find(v => v.name === edge.from) || null;
      edge.target = data.vertices.find(v => v.name === edge.to) || null;
      linksArray.push(edge);
    }

    const key = [edge.from, edge.to].sort().join('+');
    if (mapOfLinks[key] !== undefined) {
      mapOfLinks[key] = mapOfLinks[key] + 1;
      data.edges[index].indexUnique = mapOfLinks[key];
    } else {
      mapOfLinks[key] = 0;
      data.edges[index].indexUnique = 0;
    }
  });

  // find overlapping nodes (geographically) and offset them by delta
  const mapOfNodes: { [key: string]: number } = {};
  const { xScale, yScale } = setupScales({ vertices: data.vertice_coordinates, width, height, invert: true });

  data.vertices.forEach((vertice, index) => {
    const key = `${vertice.geom.coordinates[0]}-${vertice.geom.coordinates[0]}`;
    if (mapOfNodes[key] !== undefined) {
      mapOfNodes[key] = mapOfNodes[key] + 1;
      data.vertices[index].indexUnique = mapOfNodes[key];
    } else {
      mapOfNodes[key] = 0;
      data.vertices[index].indexUnique = 0;
    }

    if (type === SingleLineDiagramTypes.Tree) {
      data.vertices[index].V1 = xScale(data.vertice_coordinates[index].V1);
      data.vertices[index].V2 = yScale(data.vertice_coordinates[index].V2);
    }

    if (type === SingleLineDiagramTypes.Geospatial) {
      // use projection
      let [V1, V2]: any = projection([vertice.geom.coordinates[0], vertice.geom.coordinates[1]]);

      if (vertice?.indexUnique > 1) {
        // in the same place, arrange nodes radially
        const maxNodesSamePlace = mapOfNodes[key];

        [V1, V2] = transformNodeIndex(
          data.vertices[index].indexUnique as number,
          maxNodesSamePlace as number,
          V1 as number,
          V2 as number
        );
      }

      data.vertices[index].V1 = V1;
      data.vertices[index].V2 = V2;
    }
  });

  // Arrange transformers vertically on map
  if (type === SingleLineDiagramTypes.Geospatial) {
    linksArray.forEach(link => {
      if (link.asset_register_category?.match(/transformer/i)) {
        if (link.source && link.target) {
          link.source.V1 = link.target.V1;

          if ((link.source.V2 = link.target.V2)) {
            link.source.V2 -= 0.0001;
          }
        }
      }
    });
  }

  return {
    nodes: data.vertices,
    links: linksArray,
  };
};

export const setupScales = ({
  vertices,
  width,
  height,
  invert,
}: {
  vertices: SingleLineDiagram.VerticeCoordinate[];
  width: number;
  height: number;
  invert: boolean;
}) => {
  const { minXValue, maxXValue, minYValue, maxYValue } = vertices.reduce(
    (acc: any, p) => {
      acc.minXValue = p.V1 !== undefined && p.V1 < acc.minXValue ? p.V1 : acc.minXValue;
      acc.maxXValue = p.V1 !== undefined && p.V1 > acc.maxXValue ? p.V1 : acc.maxXValue;
      acc.minYValue = p.V2 !== undefined && p.V2 < acc.minYValue ? p.V2 : acc.minYValue;
      acc.maxYValue = p.V2 !== undefined && p.V2 > acc.maxYValue ? p.V2 : acc.maxYValue;
      return acc;
    },
    { minXValue: Infinity, maxXValue: -Infinity, minYValue: Infinity, maxYValue: -Infinity }
  );

  const xScale = d3.scaleLinear().domain([minXValue, maxXValue]).range([0, width]);
  const yScale = d3
    .scaleLinear()
    .domain([maxYValue, minYValue])
    .range([invert ? height : 0, invert ? 0 : height]);

  return { xScale, yScale };
};

const transformIndex = (index: number) => {
  if (index % 2 === 0) {
    if (index === 0) {
      return index;
    }
    if (index > 1) {
      return -index / 2;
    }
  } else {
    if (index === 1) {
      return index;
    }
    if (index > 1) {
      return (index + 1) / 2;
    }
  }
  return 0;
}; // end transformIndex

const transformNodeIndex = (index: number, maxNodesSamePlace: number, V1: number, V2: number) => {
  const ratio = 360 / (maxNodesSamePlace - 1);
  const degrees = ratio * index;

  const x = Math.cos((degrees * Math.PI) / 180) * 0.0002 + V1;
  const y = Math.sin((degrees * Math.PI) / 180) * 0.0002 + V2;

  return [x, y];
}; // end transformNodeIndex

const getOffsetLine = (x1: number, x2: number, y1: number, y2: number, delta: number) => {
  // Handle special case where line is vertical
  if (x1 === x2) {
    return {
      x1: x1 + delta,
      x2: x2 + delta,
      y1: y1,
      y2: y2,
    };
  }

  // Handle special case where line is horizontal
  if (y1 === y2) {
    return {
      x1: x1,
      x2: x2,
      y1: y1 + delta,
      y2: y2 + delta,
    };
  }

  const m = (y2 - y1) / (x2 - x1); // slope of the original line
  const perpM = -1 / m; // slope of the line perpendicular to the original line

  let deltaX = Math.sqrt(Math.abs(delta) ** 2 / (1 + perpM ** 2));
  let deltaY = perpM * deltaX;

  if (m < 0) {
    deltaX = -deltaX;
    deltaY = -deltaY;
  }

  if (delta < 0) {
    deltaX = -deltaX;
    deltaY = -deltaY;
  }

  return {
    x1: x1 + deltaX,
    x2: x2 + deltaX,
    y1: y1 + deltaY,
    y2: y2 + deltaY,
  };
}; // end getOffsetLine

export const drawGraph = (
  graph: SingleLineDiagram.Graph,
  linksGroup: any,
  nodesGroup: any,
  delta: number,
  xScale: any,
  yScale: any,
  handleGraphClick?: any
) => {
  const nodes = nodesGroup
    .selectAll('g')
    .data(graph.nodes)
    .join('g')
    .attr('class', (d: SingleLineDiagram.Vertice) => `node voltages-used-${d.voltage_id}`)
    .attr('transform', (d: SingleLineDiagram.Vertice) => `translate(${xScale(d.V1)}, ${yScale(d.V2)})`)
    .attr('cx', (d: SingleLineDiagram.Vertice) => xScale(d.V1))
    .attr('cy', (d: SingleLineDiagram.Vertice) => yScale(d.V2));

  const nodeCircles = nodes
    .selectAll('circle')
    .data((d: SingleLineDiagram.Vertice) => [d])
    .join('circle')
    .attr('class', 'circle')
    .attr('r', DefaultCircleRadius)
    .on('click', function (this: SVGElement, event: any, d: any) {
      handleGraphClick(d.customer_asset_id);
    });

  const nodeLabels = nodes
    .selectAll('text')
    .data((d: SingleLineDiagram.Vertice) => [d])
    .join('text')
    .attr('class', (d: SingleLineDiagram.Vertice) => `node-label voltage-${_snakecase(d.voltage_level_text)}`)
    .text((d: SingleLineDiagram.Vertice) => d.name)
    .attr('y', -12)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .attr('pointer-events', 'none')
    .style('opacity', (d: SingleLineDiagram.Vertice) =>
      Number(['Medium', 'High', 'Extra high'].includes(d.voltage_level_text))
    )
    .style('font-size', (d: SingleLineDiagram.Vertice) => VoltageFontSizeMap[d.voltage_level_text])
    .style('paint-order', 'stroke')
    .style('stroke-linejoin', 'round');

  const links = linksGroup
    .selectAll('path')
    .data(graph.links)
    .join('path')
    .attr('class', (d: SingleLineDiagram.Edge) => {
      return `link ${d.asset_icon_name} voltages-used-${d.source?.voltage_id}`;
    })
    .attr('d', (d: SingleLineDiagram.Edge) => {
      const x1raw = xScale(d?.source?.V1);
      const y1raw = yScale(d?.source?.V2);
      const x2raw = xScale(d.target?.V1);
      const y2raw = yScale(d.target?.V2);
      const deltaMultiplied = transformIndex(d.indexUnique) * delta;
      const { x1: newX1, x2: newX2, y1: newY1, y2: newY2 } = getOffsetLine(x1raw, x2raw, y1raw, y2raw, deltaMultiplied);

      return `M ${newX1} ${newY1} L ${(newX1 + newX2) / 2} ${(newY1 + newY2) / 2} M ${(newX1 + newX2) / 2} ${
        (newY1 + newY2) / 2
      } L ${newX2} ${newY2}`;
    })
    .attr('marker-mid', (d: SingleLineDiagram.Edge) => {
      switch (d.asset_icon_name) {
        case 'switch':
          if (d.in_operation) {
            if (d.normal_state === 'I') {
              return 'url(#switch-closed)';
            } else {
              return 'url(#switch-open)';
            }
          }
          return '';
        case 'two_circles':
          return 'url(#two_circles)';
        default:
          return '';
      }
    })
    .on('click', function (this: SVGElement, event: any, d: SingleLineDiagram.Edge) {
      handleGraphClick(d.customer_asset_id);
    });

  return { nodes, nodeCircles, nodeLabels, links };
}; // end drawGraph

export const transformChart = (nodes: any, links: any, currentXScale: any, currentYScale: any, delta: number) => {
  // Move dragged node
  nodes.attr(
    'transform',
    (d: SingleLineDiagram.Vertice) => `translate(${currentXScale(d.V1)}, ${currentYScale(d.V2)})`
  );

  // Update links
  links.attr('d', (d: SingleLineDiagram.Edge) => {
    const x1raw = currentXScale(d?.source?.V1 as number);
    const y1raw = currentYScale(d?.source?.V2 as number);
    const x2raw = currentXScale(d.target?.V1 as number);
    const y2raw = currentYScale(d.target?.V2 as number);

    const deltaMultiplied = transformIndex(d.indexUnique as number) * delta;

    const { x1: newX1, x2: newX2, y1: newY1, y2: newY2 } = getOffsetLine(x1raw, x2raw, y1raw, y2raw, deltaMultiplied);

    return `M ${newX1} ${newY1} L ${(newX1 + newX2) / 2} ${(newY1 + newY2) / 2} M ${(newX1 + newX2) / 2} ${
      (newY1 + newY2) / 2
    } L ${newX2} ${newY2}`;
  });
};

export const removeHighlight = () => {
  d3.selectAll('.selected-node').classed('selected-node', false);
  d3.selectAll('.selected-link').classed('selected-link', false);
};
