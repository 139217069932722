import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { categoriesSelector } from 'modules/assets/selectors';
import { fetchAssetCategoriesAction } from 'modules/assets';
import TableAssetServiceTime from './TableAssetServiceTime';

const TabAssetServiceTime: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const categories = useSelector(categoriesSelector);

  useEffect(() => {
    if (categories) return;
    dispatch(fetchAssetCategoriesAction());
  }, [categories, dispatch]);

  return <TableAssetServiceTime />;
};

export default TabAssetServiceTime;
