import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rawDataSelector, rawDataCountSelector } from 'modules/setup/selectors';
import { fetchRawDataAction } from 'modules/setup';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, ButtonDownload } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableRawData: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(rawDataSelector);
  const count = useSelector(rawDataCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchRawDataAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.SETUP_RAW_DATA}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 134px)"
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map((item: Setup.RawData) => (
        <tr key={item.id}>
          <td>{item.file_name}</td>
          <td className="text-right">
            <ButtonDownload
              fileNameLocaleStr={item.file_name}
              link={`scenarios/customer_files/${item.id}`}
              size="small"
              variant="primary-outline"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableRawData;
