import styled from 'styled-components';
import React, { useMemo } from 'react';
import { ColorThemes } from 'constants/index';
import { numberFormat } from '@utiligize/shared/utils';

interface Props {
  values: number[];
  theme?: Map.ColorTheme;
  centered?: boolean;
  reversed?: boolean;
  disablePostfix?: boolean;
}

interface ScaleProps {
  $theme: Map.ColorTheme;
  $centered?: boolean;
  $reversed?: boolean;
}
interface ScaleDotProps extends ScaleProps {
  $index: number;
  $count: number;
}

const Scale: React.FC<Props> = ({ values, centered, reversed, theme, disablePostfix }) => {
  const t: Map.ColorTheme = theme ?? 'Responsive';

  const v = useMemo(
    () =>
      values.map(v => {
        const str = v.toString();
        const r =
          str.length > 6 ? { v: v / 10 ** 6, s: 'm' } : str.length > 3 ? { v: v / 10 ** 3, s: 'k' } : { v, s: '' };
        return `${parseFloat(numberFormat(v))}${disablePostfix ? '' : r.s}`;
      }),
    [values, disablePostfix]
  );

  return (
    <ScaleContainer>
      <ScaleGradient $centered={centered} $reversed={reversed} $theme={theme ?? 'Responsive'}>
        {v.map((value, i) => (
          <ScaleGradientDot key={i} $index={i} $count={v.length} $centered={centered} $reversed={reversed} $theme={t}>
            <span>{value}</span>
          </ScaleGradientDot>
        ))}
      </ScaleGradient>
    </ScaleContainer>
  );
};

const ScaleContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 12px 0 3px;
`;

const ScaleGradient = styled.div<ScaleProps>`
  position: relative;
  height: 4px;
  left: 3px;

  background: ${({ $theme }: ScaleProps) => ColorThemes.background[$theme]};

  background-image: ${({ $theme, $centered, $reversed }: ScaleProps) => {
    const getGradient = (type: string) => ColorThemes.list[5][type].map(c => c[$theme]).join(',');
    return `linear-gradient(to right, ${
      $centered ? getGradient('centered') : $reversed ? getGradient('reversed') : getGradient('default')
    });`;
  }};
`;

const ScaleGradientDot = styled.div<ScaleDotProps>`
  position: absolute;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  transform: translateX(-50%);
  transform: translateY(-50%);
  top: 50%;

  span {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 12px;
    font-size: 10px;
  }

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    opacity: 0.5;
  }

  left: ${({ $index, $count }: ScaleDotProps) => {
    return `calc(100% / ${$count - 1} * ${$index + 1} - 100% / ${$count - 1} - 3px)`;
  }};

  &&,
  &::before {
    background-color: ${({ $index, $count, $theme, $centered, $reversed }: ScaleDotProps) => {
      const group = ColorThemes.list[$count];
      const type = $centered ? group?.centered : $reversed ? group?.reversed : group?.default;
      return type?.[$index]?.[$theme] ?? '#777';
    }};
  }
`;

export default Scale;
