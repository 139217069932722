import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import {
  createMapStateSelectorFactory,
  mapCheckedFiltersSelectorFactory,
  mapLoadingSelector,
  mapStateEnabledLayersSelector,
  mapStateThemeSelector,
  settingsSelector,
} from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import LayerHint from 'components/Map/common/LayerHint';
import { showLayer, hideLayer, layerListToRecord } from 'utils/map';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as VoltageIcon } from 'components/Map/icons/icon • map panel section • voltage.svg';
import { IconVoltage } from '@utiligize/shared/resources';
import { MapThemes } from 'constants/index';

interface Props {
  singleDiagramEnabled: boolean;
  map: Exclude<Map.MapboxMap, null>;
}

const FilterVoltages: React.FC<Props> = ({ singleDiagramEnabled, map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const settings = useSelector(settingsSelector);
  const filterKey = 'filter_voltage';
  const checkedFilters = useSelector(mapCheckedFiltersSelectorFactory(filterKey));
  const theme = useSelector(mapStateThemeSelector);
  const layerFilters = useSelector(createMapStateSelectorFactory('layerFilters'));
  const enabledLayers = useSelector(mapStateEnabledLayersSelector);
  const filterState = useSelector(createMapStateSelectorFactory('voltageFilterState'));
  const mapLoading = useSelector(mapLoadingSelector);
  const activeFilters = useMemo(() => settings.themeActiveFilters?.[theme || '']?.filter_voltage_level, [theme]); // eslint-disable-line

  const assetFilters = useMemo(
    () =>
      Object.keys(layerFilters).reduce(
        (acc, k) => {
          if (k.startsWith('asset__')) acc[k] = layerFilters[k];
          return acc;
        },
        {} as Record<string, Map.LayerFilter>
      ),
    [layerFilters]
  );

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>, value: string | number) => {
    const isChecked = event.target.checked;

    const filteredLayers = Object.keys(assetFilters)
      .map(k => {
        const filter = assetFilters[k];
        if (!settings.assetLayers?.some(i => i.startsWith(k))) return null;
        if (isChecked && filterState?.[value]?.length && !filterState?.[value]?.includes(k)) return null;
        if (!isChecked) return filter?.list.includes(value) ? k : null;
        return filter?.initList.some(i => i.id === value) ? k : null;
      })
      .filter(Boolean) as string[];

    const newState = { layerFilters: { ...layerFilters } } as Partial<Map.MapState>;
    const layers = [] as string[];
    const action = isChecked ? showLayer : hideLayer;

    for (const layer of filteredLayers) {
      const prev = assetFilters[layer];
      const prevList = assetFilters[layer]?.list ?? [];
      const checkedItems = isChecked ? [...prevList, value] : prevList.filter(i => i !== value);
      const filter = { ...prev, list: checkedItems } as Map.LayerFilter;
      const isEnabled = Object.entries(enabledLayers ?? {}).some(([k, v]) => k.startsWith(layer) && v);
      newState.layerFilters![layer] = filter;
      if ((isChecked && !isEnabled && filter.list.length) || (!isChecked && isEnabled && !filter.list.length)) {
        layers.push(...settings.assetLayers!.filter(i => i.startsWith(layer)));
      }
    }

    newState.voltageFilterState = { ...filterState, [value]: isChecked ? [] : filteredLayers };

    if (layers.length) {
      newState.enabledLayers = { ...enabledLayers, ...layerListToRecord(layers, isChecked) };
    }

    if (!theme) layers.forEach(i => action(map, i));
    dispatch(mapStateAction(newState));
  };

  const renderItems = () => {
    if (!settings?.globalFilters?.[filterKey]) {
      return <StyledWarning>{getIntl('Voltage filter is not found')}</StyledWarning>;
    }

    return settings?.globalFilters?.[filterKey].list.map(filter => {
      const keys = Object.keys(assetFilters);
      const f = (k: string) => assetFilters[k];
      const isChecked = keys.some(k => f(k)?.list.includes(filter.id));
      const isDisabled = !keys.some(k => f(k)?.initList.some(i => i.id === filter.id));
      return (
        <StyledVoltageItem key={filter.id}>
          <ControlBox
            type="checkbox"
            id={filter.id + ''}
            name={filter.name ?? ''}
            checked={isChecked && !isDisabled}
            disabled={isDisabled || (singleDiagramEnabled && mapLoading)}
            labelKey={filter.full_name ?? filter.name}
            onChange={e => onFilterChange(e, filter.id)}
            icon={<IconVoltage />}
            suffix={
              activeFilters?.includes(filter.id as number) && (
                <LayerHint text={getIntl('Filter that can affect selected theme')} type="dot" />
              )
            }
          />
        </StyledVoltageItem>
      );
    });
  };

  return (
    <CollapseGroup
      id="voltage"
      titleKey="Voltages"
      alwaysOpen={singleDiagramEnabled}
      icon={<VoltageIcon className="filter-group-icon" />}
      disabled={settings.isAssetGroupDisabled || theme === MapThemes.TASK}
      dataMarker="panel_voltage"
      description={
        <GroupHiddenDescription
          values={(checkedFilters?.map(f => f.full_name ?? f.name) ?? []) as string[]}
          defaultValue="Nothing selected"
          showMoreItems
        />
      }
    >
      <div>{renderItems()}</div>
    </CollapseGroup>
  );
};

const StyledVoltageItem = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWarning = styled.div`
  color: var(--map-text-color-inactive);
`;

export default FilterVoltages;
