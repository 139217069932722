import React, { useEffect, useCallback, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { metersPopulationModalIdSelector } from 'modules/layouts/selectors';
import { metersPopulationItemSelectorFactory } from 'modules/meters/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';

const MetersPopulationModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, dateFormat } = useLocale();
  const id: number | null = useSelector(metersPopulationModalIdSelector);
  const item: Meters.Population | undefined = useSelector(metersPopulationItemSelectorFactory(id));
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!item) return;
    setShow(true);
  }, [item]);

  const closeModal = useCallback((): void => {
    dispatch(setLayoutAction({ metersPopulationModalId: null }));
    setShow(!show);
  }, [show, dispatch]);

  const renderItem = (localeKey: string, value: any) => (
    <ListGroup.Item className="d-flex">
      {getIntl(localeKey)}: <span className="ml-auto">{value}</span>
    </ListGroup.Item>
  );

  return (
    <Modal
      scrollable
      show={show}
      onHide={closeModal}
      titleKey="Meters population details"
      cancelButtonProps={{ labelKey: 'Close', onClick: closeModal }}
    >
      <div>
        <ListGroup variant="flush">
          {renderItem('Meter number', item?.meterNumber || '-')}
          {renderItem('Batch number', item?.batchNumber || '-')}
          {renderItem('Type', item?.type || '-')}
          {renderItem('Installation number', item?.installation || '-')}
          {renderItem('Address', item?.address || '-')}
          {renderItem('Connection', item?.connection || '-')}
          {renderItem('Serial number', item?.serialNumber || '-')}
          {renderItem('Activated', item?.dateActivated ? dateFormat(item.dateActivated) : '-')}
          {renderItem('Discarded', item?.dateDiscarded ? dateFormat(item.dateDiscarded) : '-')}
          {renderItem('Removed', item?.dateRemoved ? dateFormat(item.dateRemoved) : '-')}
          {renderItem('Control', item?.controlDate ? dateFormat(item.controlDate) : '-')}
          {renderItem('Pallet', item?.pallet || '-')}
          {renderItem('Box', item?.box || '-')}
          {renderItem('Manufacturer', item?.manufacturer || '-')}
          {renderItem('Model', item?.model || '-')}
          {renderItem('Manufacture date', item?.manufactureDate ? dateFormat(item.manufactureDate) : '-')}
        </ListGroup>
      </div>
    </Modal>
  );
};

export default MetersPopulationModal;
