import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { scenariosYearsOptionsSelector } from 'modules/options/selectors';
import { fetchScenariosYearsOptionsAction } from 'modules/options';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  labelKey?: string;
  value: number | null;
  setInitialValue?: boolean;
}

const SelectScenariosYear: React.FC<Props> = ({
  labelKey = 'Scenarios year',
  value,
  setInitialValue,
  onChange,
  ...rest
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector(scenariosYearsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || options) return;
    setIsLoading(true);
    dispatch(fetchScenariosYearsOptionsAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [dispatch, portfolioId, scenarioId, options, setInitialValue]);

  const selectValue = useMemo(
    () => options?.find((option: Type.SelectOption) => option.value === value),
    [options, value]
  );

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey={labelKey}
      options={options || []}
      value={selectValue || null}
      isSearchable={false}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SelectScenariosYear;
