import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { SelectSubComponents, Button } from 'components/_common';
import { AssetCategories } from 'constants/index';
import { FormikProps } from './useConfiguredFormik';
import { IconPlus } from '@utiligize/shared/resources';
import Card from '../Card';

interface Props {
  models: Asset.AssetModel[];
  setFieldValue: FormikProps['setFieldValue'];
}

const AddSubComponent: React.FC<Props> = ({ models, setFieldValue }) => {
  const { getIntl } = useLocale();
  const [subComponentType, setSubComponentType] = useState<{ id: number | null; label: string } | null>(null);

  const handleSetFieldValue = useCallback((id: number | null, label: string) => {
    setSubComponentType({ id, label });
  }, []);

  const handleAddSubcomponentButtonClick = useCallback((): void => {
    if (!subComponentType) return;
    setFieldValue('models', [
      ...models,
      {
        manufacturerId: null,
        manufacturerName: '',
        modelId: null,
        modelName: '',
        subComponentTypeId: subComponentType.id,
        subComponentTypeName: subComponentType.label,
        subcomponentId: Date.now(),
        subcomponentPrefix: '',
      },
    ]);
    setSubComponentType(null);
  }, [models, setFieldValue, subComponentType]);

  return (
    <Card>
      <Form.Label className="font-weight-normal">{getIntl('Add sub-component type')}</Form.Label>
      <StyledWrapper>
        <SelectSubComponents
          labelKey=""
          assetCategoryCode={AssetCategories.BD}
          setFieldValue={handleSetFieldValue}
          value={subComponentType?.id || null}
        />
        <Button
          disabled={!subComponentType?.id}
          variant="primary-outline"
          onClick={handleAddSubcomponentButtonClick}
          icon={<IconPlus />}
          size="large"
          marginLeft
        />
      </StyledWrapper>
    </Card>
  );
};

const StyledWrapper = styled.div`
  display: flex;

  > div:first-child {
    flex-grow: 1;
  }
`;

export default AddSubComponent;
