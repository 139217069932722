import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { ButtonDownload, Button } from 'components/_common';
import { IconDownload } from '@utiligize/shared/resources';

const PopoverReports: React.FC = () => {
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <StyledPopover id="popover-reports">
          <Popover.Content>
            <ButtonDownload
              dataMarker="download_report__network_development_plan_docx"
              fileType="docx"
              variant="primary-link"
              labelKey="Network development plan"
              fileNameLocaleStr="Network development plan"
              link="network_development_plan/nup_2025_word"
              className="mt-2"
            />
            <Dropdown.Divider />
            <ButtonDownload
              dataMarker="download_report__network_development_plan_xlsx"
              fileType="xlsx"
              variant="primary-link"
              labelKey="Network development plan"
              fileNameLocaleStr="Network development plan"
              link="network_development_plan/nup_2025_excel"
              className="mb-2"
            />
            <Dropdown.Divider />
            <ButtonDownload
              dataMarker="download_report__co2e"
              fileType="xlsx"
              variant="primary-link"
              labelKey="CO₂e data"
              fileNameLocaleStr="CO₂e data"
              link={`scenarios/co2e_dashboard_data_excel?portfolio_id=${portfolioId}&scenario_id=${scenarioId}`}
              className="mb-2"
            />
          </Popover.Content>
        </StyledPopover>
      }
    >
      <Button
        dataMarker="header__download_reports"
        variant="primary"
        marginLeft
        icon={<IconDownload />}
        aria-label="Download reports"
      />
    </OverlayTrigger>
  );
};

const StyledPopover = styled(Popover)`
  width: 300px;
  z-index: 10001;
`;

export default PopoverReports;
