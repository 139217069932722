import React, { useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import TableRights from './TableRights';
import ModalRightsProfile from './ModalRightsProfile';
import {
  DataTableTemplate,
  FormReactSelect,
  SelectSolved,
  Checkbox,
  SelectCustomerExistingDERs,
} from 'components/_common';
import { PaginationType, SelectCustomersRightsMissingTypes } from 'constants/index';
import SelectLoadType from './SelectLoadType';

const ViewCustomers: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.CUSTOMERS_RIGHTS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const { missingTypeOptions, missingTypeSelectValue } = useMemo(
    () =>
      Object.values<Customers.SelectMissingTypes>(SelectCustomersRightsMissingTypes).reduce(
        (acc: any, type) => {
          const item = { value: type, label: getIntl(type) };
          if (type === filters?.missingType) acc.missingTypeSelectValue = item;
          acc.missingTypeOptions.push(item);
          return acc;
        },
        { missingTypeOptions: [], missingTypeSelectValue: null }
      ),
    [filters, getIntl]
  );

  const { percentileOptions, percentileSelectValue } = useMemo(
    () =>
      [99, 99.5, 99.9, 100].reduce(
        (acc: any, percentile: number) => {
          const item = { value: percentile, label: percentile };
          if (percentile === filters?.percentile) acc.percentileSelectValue = item;
          acc.percentileOptions.push(item);
          return acc;
        },
        { percentileOptions: [], percentileSelectValue: null }
      ),
    [filters]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, actionMeta: any) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [actionMeta.name]: (value as any)?.value } },
        })
      );
    },
    [dispatch, filters, type]
  );

  const handleSolvedSelectChange = useCallback(
    (solvedValue: Type.SelectSolvedValues) => {
      dispatch(setPaginationAction({ type, modifier: { filters: { ...filters, solvedValue } } }));
    },
    [dispatch, filters, type]
  );

  const handleSelectCustomerExistingDERsChange = useCallback(
    (existingDersId: number | null) => {
      dispatch(setPaginationAction({ type, modifier: { filters: { ...filters, existingDersId } } }));
    },
    [dispatch, filters, type]
  );

  const handleOutliersRemovedSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, outliersRemoved: event.currentTarget.checked } },
        })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={1}>
          <FormReactSelect
            name="percentile"
            variant="small"
            mutedTextLabelKey="Percentile"
            value={percentileSelectValue}
            options={percentileOptions}
            onChange={handleSelectChange}
            isSearchable={false}
          />
        </Col>
        <Col xs={2}>
          <SelectLoadType name="loadType" value={filters?.loadType} onChange={handleSelectChange} />
        </Col>
        <Col xs={2}>
          <FormReactSelect
            name="missingType"
            variant="small"
            mutedTextLabelKey="Missing type"
            value={missingTypeSelectValue}
            options={missingTypeOptions}
            onChange={handleSelectChange}
            isSearchable={false}
          />
        </Col>
        <Col xs={2}>
          <SelectCustomerExistingDERs
            variant="small"
            mutedTextLabelKey="Existing DERs"
            value={filters?.existingDersId || null}
            onChange={handleSelectCustomerExistingDERsChange}
            isClearable
            isSearchable={false}
          />
        </Col>
        <Col xs={1}>
          <SelectSolved
            variant="small"
            mutedTextLabelKey="Filter solved"
            value={filters?.solvedValue}
            onChange={handleSolvedSelectChange}
          />
        </Col>
        <Col xs="auto">
          <Checkbox
            labelKey="Outliers removed"
            className="icheck-primary"
            checked={filters?.outliersRemoved}
            onChange={handleOutliersRemovedSwitch}
          />
        </Col>
      </Row>
      <>
        <TableRights />
        <ModalRightsProfile outliersRemoved={Boolean(filters?.outliersRemoved)} />
      </>
    </DataTableTemplate>
  );
};

export default ViewCustomers;
