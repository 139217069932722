import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { profileModalMetaSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import ProfileTabs from 'components/_modals/ProfileModal/ProfileTabs';
import { Modal } from 'components/_common';

const ProfileModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [uuid, setUUID] = useState<string | null>(null);
  const { id = null, type } = useSelector(profileModalMetaSelector) || ({} as Layouts.ProfileModalMeta);

  useEffect(() => {
    if (id) return setUUID(id);
    setTimeout(() => setUUID(null), 500);
  }, [id]);

  const handleCancelBtnClick = useCallback(() => dispatch(setLayoutAction({ profileModalMeta: null })), [dispatch]);

  return (
    <Modal
      size="xl"
      show={Boolean(id)}
      backdrop
      onHide={handleCancelBtnClick}
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCancelBtnClick }}
    >
      <ProfileTabs uuid={uuid} type={type} />
    </Modal>
  );
};

export default ProfileModal;
