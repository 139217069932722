import React, { useCallback, useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { DERModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { DERSelectorFactory } from 'modules/newLoad/selectors';
import {
  Modal,
  Button,
  HiddenFormSubmit,
  FormInput,
  SelectDERsCategory,
  SelectDERsProfile,
  SelectGridZone,
} from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalDER: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(DERModalIdSelector);
  const DER: NewLoad.DER | undefined = useSelector(DERSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && DER);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ DERModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setValues,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!DER) return;
    setValues({
      description: DER.description,
      der_categories_id: DER.der_categories_id,
      max_per_installation: DER.max_per_installation,
      grid_zones_id: DER.grid_zones_id,
      profileId: DER.der_profile_info_id,
    });
    setShow(true);
  }, [DER, setValues]);

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Add new DER" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit new DER' : 'Add new DER'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>
          <SelectDERsCategory
            name="der_categories_id"
            value={values.der_categories_id}
            setFieldValue={setFieldValue}
            onBlur={() => setFieldTouched('der_categories_id')}
            errorKey={
              Boolean(touched.der_categories_id && errors.der_categories_id) ? (errors.der_categories_id as string) : ''
            }
            show={show}
          />
          <Form.Group>
            <SelectGridZone
              value={values.grid_zones_id}
              onChange={(option: Type.SelectOption[] | null) =>
                setFieldValue('grid_zones_id', option?.map(i => i.value) || null)
              }
              onBlur={() => setFieldTouched('grid_zones_id')}
              errorKey={Boolean(touched.grid_zones_id && errors.grid_zones_id) ? (errors.grid_zones_id as string) : ''}
              isMulti
            />
          </Form.Group>
          <Row>
            <Form.Group as={Col} xs={7}>
              <SelectDERsProfile
                value={values.profileId}
                onChange={(value: number | null) => setFieldValue('profileId', value)}
                onBlur={() => setFieldTouched('profileId')}
                errorKey={Boolean(touched.profileId && errors.profileId) ? (errors.profileId as string) : ''}
              />
            </Form.Group>
            <Form.Group as={Col} xs={5}>
              <FormInput
                isNew
                size="lg"
                type="number"
                name="max_per_installation"
                labelKey="Max number per installation"
                value={values.max_per_installation || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={
                  Boolean(touched.max_per_installation && errors.max_per_installation)
                    ? (errors.max_per_installation as string)
                    : ''
                }
              />
            </Form.Group>
          </Row>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalDER;
