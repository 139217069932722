import { combineActions, handleActions } from 'redux-actions';
import {
  fetchAssetCategoriesAction,
  fetchAssetAction,
  updateAssetAction,
  deleteAssetPhotosAction,
  fetchModelsByAssetCategoryAction,
  fetchSubComponentsAction,
  createSubComponentAction,
  deleteSubComponentAction,
  fetchSmartMetersAction,
  fetchAssetTaskCompletionsAction,
  fetchAssetCodeAutoCompleteAction,
  fetchAssetCodeAutoCompleteNewAction,
  fetchInstallationNumberAutoCompleteAction,
} from './actions';

const initialState: Asset.Root = {
  subComponents: null,
  smartMetersHashFetched: false,
  smartMetersHash: {} as Type.Hash<Asset.SmartMeter>,
  categories: null,
  taskCompletions: [],
  assetMeta: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(
    fetchAssetCategoriesAction,
    fetchSubComponentsAction,
    fetchAssetTaskCompletionsAction,
    fetchSmartMetersAction
  ).toString()]: {
    next: (
      state: Asset.Root,
      action: Shared.ReduxAction<Asset.Root & { skipStoreUpdate: true | undefined }>
    ): Asset.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  [fetchAssetAction.toString()]: {
    next: (state: Asset.Root, action: Shared.ReduxAction<Asset.Root['assetMeta']>): Asset.Root => ({
      ...state,
      assetMeta: action.payload,
    }),
  },
};

export {
  fetchAssetCategoriesAction,
  fetchAssetAction,
  updateAssetAction,
  deleteAssetPhotosAction,
  fetchModelsByAssetCategoryAction,
  fetchSubComponentsAction,
  createSubComponentAction,
  deleteSubComponentAction,
  fetchSmartMetersAction,
  fetchAssetTaskCompletionsAction,
  fetchAssetCodeAutoCompleteAction,
  fetchAssetCodeAutoCompleteNewAction,
  fetchInstallationNumberAutoCompleteAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
