import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';
import { useLocale, useReadableTable } from 'hooks';
import { rootSelector } from 'modules/map/selectors';
import { MapPopup } from 'components/Map/common/Popup';
import { getPopupCoords, formatPopupProps, addEvent } from 'utils/map';

interface Props {
  map: Map.MapboxMap | null;
  styles: Map.Style[];
  isMapStyleLoaded: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const SetupEvents: React.FC<Props> = ({ children, map, isMapStyleLoaded, styles }) => {
  const { getIntl } = useLocale();
  const { formatProp, formatValue } = useReadableTable();
  const [isEventAdded, setIsEventAdded] = useState(false);
  const popup = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false, className: 'utiligize-map-popup' })
  );
  const hooverState = useRef({} as Record<string, any>);
  const popupNode = useRef(document.createElement('div'));
  const popupNodeRoot = useMemo(() => createRoot(popupNode.current), []);
  const popupNodeRootRef = useRef(popupNodeRoot);
  const isElementHovered = useRef(false);
  const isPointHovered = useRef(false);

  const mapRoot = useSelector(rootSelector);
  const mapRootRef = useRef(mapRoot);

  useEffect(() => {
    mapRootRef.current = mapRoot;
  }, [mapRoot]);

  const hoverableHandler = useCallback(
    (sourceId: string, sourceLayer: string) => {
      hooverState.current[sourceId] = null;
      return {
        move: (e: mapboxgl.MapLayerMouseEvent) => {
          if (!map || isElementHovered.current) return;
          if (e.features && e.features.length > 0) {
            if (hooverState.current[sourceId]) {
              map.removeFeatureState({
                source: sourceId,
                sourceLayer: sourceLayer,
                id: hooverState.current[sourceId],
              });
            }
            hooverState.current[sourceId] = e.features[0].id;
            map.setFeatureState(
              {
                source: sourceId,
                sourceLayer: sourceLayer,
                id: hooverState.current[sourceId],
              },
              {
                hover: true,
              }
            );
          }
        },
        leave: () => {
          if (!map) return;
          if (hooverState.current[sourceId]) {
            map.setFeatureState(
              {
                source: sourceId,
                sourceLayer: sourceLayer,
                id: hooverState.current[sourceId],
              },
              {
                hover: false,
              }
            );
          }
          hooverState.current[sourceId] = null;
        },
      };
    },
    [map]
  );

  const handleMouseMoveEventFactory = useCallback(
    (layer: Map.StyleLayer) => (e: mapboxgl.MapLayerMouseEvent) => {
      if (!map) return;
      const feature = e.features?.[0];
      if (!feature) return;
      const featurePaint = feature.layer.paint as Record<string, string | number>;
      const opacityKey = Object.keys(featurePaint ?? {}).find(k => k.endsWith('opacity'));
      const isPoint = feature.geometry.type === 'Point';
      if (featurePaint && opacityKey && featurePaint[opacityKey] === 0) return;
      if (!isPoint && isPointHovered.current) return;
      if (isPoint) isPointHovered.current = true;
      const coords = getPopupCoords(feature, layer, e.lngLat.toArray());
      if (!coords) return;
      const popupData = formatPopupProps(feature, layer, mapRootRef.current);
      isElementHovered.current = true;

      popupNodeRootRef.current.render(
        <MapPopup data={popupData} getIntl={getIntl} formatProp={formatProp} formatValue={formatValue} />
      );

      popup.current
        .setLngLat(coords as mapboxgl.LngLatLike)
        .setDOMContent(popupNode.current)
        .addTo(map);
    },
    [map, getIntl, formatProp, formatValue]
  );

  const onLayerMouseLeave = useCallback(() => {
    popup.current.remove();
    isElementHovered.current = false;
    isPointHovered.current = false;
  }, []);

  const setupLayerHoverInfo = useCallback((styles: Map.Style[]) => {
    const hoverLayers = [] as string[];
    const moveLayers = [] as string[];

    styles.forEach(style => {
      const layer = style.layer;
      layer.sublayers.forEach(sublayer => {
        if (sublayer.hoverable && !moveLayers.includes(sublayer.id)) {
          moveLayers.push(sublayer.id);
          const handler = hoverableHandler(sublayer.source, sublayer['source-layer']!);
          addEvent(sublayer.id, 'mousemove', handler.move);
          addEvent(sublayer.id, 'mouseleave', handler.leave);
        }
        if (sublayer.showPopup && !hoverLayers.includes(sublayer.id)) {
          hoverLayers.push(sublayer.id);
          addEvent(sublayer.id, 'mousemove', handleMouseMoveEventFactory(layer));
          addEvent(sublayer.id, 'mouseleave', onLayerMouseLeave);
        }
      });
    });

    setIsEventAdded(true);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!map || !isMapStyleLoaded) return;
    setupLayerHoverInfo(styles);
  }, [map, isMapStyleLoaded]); // eslint-disable-line

  return isEventAdded ? <>{children}</> : null;
};

export default React.memo(SetupEvents);
