import React from 'react';
import { ViewCustomers } from 'components/Customers';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageCustomers: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isCustomersEnabled}>
    <ViewCustomers />
  </PagePermissionsProvider>
);

export default PageCustomers;
