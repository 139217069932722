import React from 'react';
import { fetchTaskChecklistAction, updateTaskChecklistAction } from 'modules/tasks';
import { Checklist } from 'components/_common';
import { TaskStatus } from 'constants/index';

interface Props {
  id: Tasks.Task['id'];
  status: Tasks.Task['status'];
  labelKey?: string;
}

const TaskDetailsChecklist: React.FC<Props> = ({ id, status, labelKey }) => (
  <Checklist<Tasks.ChecklistAnswer>
    isTaskChecklist
    id={id}
    fetchChecklistAction={fetchTaskChecklistAction}
    updateChecklistAction={updateTaskChecklistAction}
    actionDisabledMessage={status !== TaskStatus.Finished ? "You can't update checklist for unfinished task" : ''}
    labelKey={labelKey}
  />
);

export default TaskDetailsChecklist;
