import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDERsPreviewYearsAction } from 'modules/newLoad';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DERsPreviewYearsSelector } from 'modules/newLoad/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  onChange: (value: number) => void;
  isClearable?: boolean;
  isInCustomerRightsProfileModal?: boolean;
}

const SelectDERsPreviewYear: React.FC<Props> = ({
  value = null,
  onChange,
  isInCustomerRightsProfileModal = false,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const years = useSelector(DERsPreviewYearsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (years || !portfolioId || !scenarioId) return;
    dispatch(fetchDERsPreviewYearsAction({ portfolioId, scenarioId }));
  }, [dispatch, years, portfolioId, scenarioId]);

  const handleSelectChange = useCallback((value: Type.SelectOption) => onChange((value as any)?.value), [onChange]);

  const { options, selectValue } = useMemo(
    () =>
      (years || []).reduce(
        (acc: { options: Type.SelectOption[]; selectValue: Type.SelectOption | null }, key: number) => {
          if (isInCustomerRightsProfileModal && key > new Date().getFullYear()) return acc;
          const item = { value: key, label: String(key) };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, years, isInCustomerRightsProfileModal]
  );

  return (
    <FormReactSelect
      value={selectValue}
      options={options}
      onChange={handleSelectChange}
      isDisabled={!years}
      isLoading={!years}
      {...props}
    />
  );
};

export default SelectDERsPreviewYear;
