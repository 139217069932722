import React, { useEffect, useMemo, useState } from 'react';
import { useLocale } from 'hooks';
import Chart from 'components/_charts/Chart';
import { AssetLifeAPI } from 'constants/index';

interface Props {
  title: string;
  yAxisTitle: string;
  src: string;
}

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle?: string;
  yAxisTitle: string;
}

interface SaifiCMLProps {
  'historic data': {
    map: (
      callback: (item: {
        ci_lower: number;
        ci_upper: number;
        lin_reg: number;
        data_points: number;
        year: number;
      }) => number
    ) => number[];
  };
  'calculated data': {
    map: (
      callback: (item: {
        ci_lower: number;
        ci_upper: number;
        lin_reg: number;
        data_points: number;
        year: number;
      }) => number
    ) => number[];
  };
  'calculated data before investment': {
    map: (
      callback: (item: {
        ci_lower: number;
        ci_upper: number;
        lin_reg: number;
        data_points: number;
        year: number;
      }) => number
    ) => number[];
  };
  'calculated data after investment': {
    map: (
      callback: (item: {
        ci_lower: number;
        ci_upper: number;
        lin_reg: number;
        data_points: number;
        year: number;
      }) => number
    ) => number[];
  };
}

const ChartSaifiCML: React.FC<Props> = ({ title, yAxisTitle, src }) => {
  const { getIntl } = useLocale();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  useEffect(() => {
    if (!src) return;
    AssetLifeAPI.get(src)
      .then((res: { data: SaifiCMLProps }) => {
        const historic_data = res.data['historic data'];
        const calculated_data = res.data['calculated data'];
        const calculated_data_before_investment = res.data['calculated data before investment'];
        const calculated_data_after_investment = res.data['calculated data after investment'];

        const { lin_reg, data_points, ci_upper, ci_lower } = Object.values(
          historic_data?.map(item => item.year) || {}
        ).reduce(
          (
            acc: {
              lin_reg: [number, number][];
              data_points: [number, number][];
              ci_upper: [number, number][];
              ci_lower: [number, number][];
            },
            item,
            index
          ) => {
            acc.lin_reg.push([item, historic_data?.map(item => item.lin_reg)[index]]);
            acc.data_points.push([item, historic_data?.map(item => item.data_points)[index]]);
            acc.ci_upper.push([item, historic_data?.map(item => item.ci_upper)[index]]);
            acc.ci_lower.push([item, historic_data?.map(item => item.ci_lower)[index]]);
            return acc;
          },
          { lin_reg: [], data_points: [], ci_upper: [], ci_lower: [] }
        );

        const { calculated_data_points, calculated_ci_upper, calculated_ci_lower } = Object.values(
          calculated_data?.map(item => item.year) || {}
        ).reduce(
          (
            acc: {
              calculated_data_points: [number, number][];
              calculated_ci_upper: [number, number][];
              calculated_ci_lower: [number, number][];
            },
            item,
            index
          ) => {
            acc.calculated_data_points.push([item, calculated_data?.map(item => item.data_points)[index]]);
            acc.calculated_ci_upper.push([item, calculated_data?.map(item => item.ci_upper)[index]]);
            acc.calculated_ci_lower.push([item, calculated_data?.map(item => item.ci_lower)[index]]);
            return acc;
          },
          { calculated_data_points: [], calculated_ci_upper: [], calculated_ci_lower: [] }
        );

        const {
          calculated_data_points_before_investment,
          calculated_ci_upper_before_investment,
          calculated_ci_lower_before_investment,
        } = Object.values(calculated_data_before_investment?.map(item => item.year) || {}).reduce(
          (
            acc: {
              calculated_data_points_before_investment: [number, number][];
              calculated_ci_upper_before_investment: [number, number][];
              calculated_ci_lower_before_investment: [number, number][];
            },
            item,
            index
          ) => {
            acc.calculated_data_points_before_investment.push([
              item,
              calculated_data_before_investment?.map(item => item.data_points)[index],
            ]);
            acc.calculated_ci_upper_before_investment.push([
              item,
              calculated_data_before_investment?.map(item => item.ci_upper)[index],
            ]);
            acc.calculated_ci_lower_before_investment.push([
              item,
              calculated_data_before_investment?.map(item => item.ci_lower)[index],
            ]);
            return acc;
          },
          {
            calculated_data_points_before_investment: [],
            calculated_ci_upper_before_investment: [],
            calculated_ci_lower_before_investment: [],
          }
        );

        const {
          calculated_data_points_after_investment,
          calculated_ci_upper_after_investment,
          calculated_ci_lower_after_investment,
        } = Object.values(calculated_data_after_investment?.map(item => item.year) || {}).reduce(
          (
            acc: {
              calculated_data_points_after_investment: [number, number][];
              calculated_ci_upper_after_investment: [number, number][];
              calculated_ci_lower_after_investment: [number, number][];
            },
            item,
            index
          ) => {
            acc.calculated_data_points_after_investment.push([
              item,
              calculated_data_after_investment?.map(item => item.data_points)[index],
            ]);
            acc.calculated_ci_upper_after_investment.push([
              item,
              calculated_data_after_investment?.map(item => item.ci_upper)[index],
            ]);
            acc.calculated_ci_lower_after_investment.push([
              item,
              calculated_data_after_investment?.map(item => item.ci_lower)[index],
            ]);
            return acc;
          },
          {
            calculated_data_points_after_investment: [],
            calculated_ci_upper_after_investment: [],
            calculated_ci_lower_after_investment: [],
          }
        );

        setChartData({
          series: [
            data_points?.length && {
              type: 'scatter',
              data: data_points,
              name: getIntl('Historic data'),
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x}</span><br />',
                // eslint-disable-next-line no-template-curly-in-string
                pointFormat: '<span style="color:${this.color}">●</span> {series.name}: <b>{point.y}</b>',
              },
              marker: {
                radius: 4,
                fillColor: '#1b10f5',
                symbol: 'circle',
              },
            },
            calculated_data_points?.length && {
              type: 'line',
              data: calculated_data_points,
              name: getIntl('Expected data'),
              color: '#544FC5',
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x}</span><br />',
                // eslint-disable-next-line no-template-curly-in-string
                pointFormat: '<span style="color:${this.color}">●</span> {series.name}: <b>{point.y}</b>',
              },
              lineWidth: 5,
            },
            lin_reg?.length && {
              type: 'line',
              data: lin_reg,
              name: getIntl('Linear regression'),
              color: '#000',
              marker: {
                radius: 2,
                fillColor: '#000',
                symbol: 'circle',
              },
            },
            ci_upper?.length && {
              type: 'line',
              id: 'ci_upper',
              data: ci_upper,
              name: `95% ${getIntl('Expected interval')}`,
              color: '#1C9BEF',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#1C9BEF',
                symbol: 'circle',
              },
            },
            ci_lower?.length && {
              type: 'line',
              id: 'ci_lower',
              data: ci_lower,
              linkedTo: 'ci_upper',
              name: `95% ${getIntl('Expected interval')}`,
              color: '#1C9BEF',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#1C9BEF',
                symbol: 'circle',
              },
            },
            calculated_ci_upper?.length && {
              type: 'line',
              id: 'calculated_ci_upper',
              data: calculated_ci_upper,
              name: getIntl('Expected interval'),
              color: '#D73034',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#D73034',
                symbol: 'circle',
              },
            },
            calculated_ci_lower?.length && {
              type: 'line',
              data: calculated_ci_lower,
              linkedTo: 'calculated_ci_upper',
              name: getIntl('Expected interval'),
              color: '#D73034',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#D73034',
                symbol: 'circle',
              },
            },
            calculated_data_points_before_investment?.length && {
              type: 'line',
              data: calculated_data_points_before_investment,
              name: getIntl('Expected data wo/ investment'),
              color: '#544FC5',
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x}</span><br />',
                // eslint-disable-next-line no-template-curly-in-string
                pointFormat: '<span style="color:${this.color}">●</span> {series.name}: <b>{point.y}</b>',
              },
              lineWidth: 5,
            },
            calculated_ci_upper_before_investment?.length && {
              type: 'line',
              id: 'calculated_ci_upper_before_investment',
              data: calculated_ci_upper_before_investment,
              name: getIntl('Expected interval wo/ investment'),
              color: '#D73034',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#D73034',
                symbol: 'circle',
              },
            },
            calculated_ci_lower_before_investment?.length && {
              type: 'line',
              data: calculated_ci_lower_before_investment,
              linkedTo: 'calculated_ci_upper_before_investment',
              name: getIntl('Expected interval wo/ investment'),
              color: '#D73034',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#D73034',
                symbol: 'circle',
              },
            },
            calculated_data_points_after_investment?.length && {
              type: 'line',
              data: calculated_data_points_after_investment,
              color: '#1C9BEE',
              name: getIntl('Expected data w/ investment'),
              tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x}</span><br />',
                // eslint-disable-next-line no-template-curly-in-string
                pointFormat: '<span style="color:${this.color}">●</span> {series.name}: <b>{point.y}</b>',
              },
              lineWidth: 5,
            },
            calculated_ci_upper_after_investment?.length && {
              type: 'line',
              id: 'calculated_ci_upper_after_investment',
              data: calculated_ci_upper_after_investment,
              name: getIntl('Expected interval w/ Investment'),
              color: '#028759',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#028759',
                symbol: 'circle',
              },
            },
            calculated_ci_lower_after_investment?.length && {
              type: 'line',
              data: calculated_ci_lower_after_investment,
              linkedTo: 'calculated_ci_upper_after_investment',
              name: getIntl('Expected interval w/ Investment'),
              color: '#028759',
              dashStyle: 'Dash',
              marker: {
                radius: 2,
                fillColor: '#028759',
                symbol: 'circle',
              },
            },
          ].filter(Boolean) as Shared.SeriesOptionsType[],
          title,
          yAxisTitle,
        });
      })
      .catch(console.error);
  }, [title, yAxisTitle, src, getIntl]);

  const options: Highcharts.Options = useMemo(
    () => ({
      title: {
        text: chartData?.title,
      },
      yAxis: {
        title: {
          text: chartData?.yAxisTitle,
        },
      },
      plotOptions: {
        line: { marker: { enabled: false } },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        itemMarginBottom: 15,
        padding: 15,
        y: 30,
      },
      series: chartData?.series,
    }),
    [chartData]
  );

  return <Chart dataMarker="saifi_cml_chart" options={chartData ? options : null} height="calc(50vh - 116px)" />;
};

export default ChartSaifiCML;
