import React, { useState, useEffect, useCallback } from 'react';
import { ListGroup } from 'react-bootstrap';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { transformerSelectorFactory, cableSelectorFactory } from 'modules/networkLoading/selectors';
import { NetworkLoadingIgnoreMessagesTable, NetworkLoadingCustomersTypesTable } from 'components/_tables';
import {
  AssetDurationChart,
  AssetProfileChart,
  AssetTimeSeriesChart,
  ChartProbabilityOfFailure,
} from 'components/_charts';
import { selectedChartYearSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Tabs, Tab, SelectDERsPreviewYear } from 'components/_common';
import { AssetLifeAPI, ProfileModalTypes } from 'constants/index';

export enum TimeSeriesTypes {
  POWER = 'power',
  VOLTAGE = 'voltage',
  LOSSES = 'losses',
}

const ProfileTabs: React.FC<{
  uuid: string | null;
  type: Type.ProfileModalTypes;
}> = ({ uuid, type }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<TimeSeriesTypes>(TimeSeriesTypes.POWER);
  const transformer = useSelector(transformerSelectorFactory(uuid));
  const cable = useSelector(cableSelectorFactory(uuid));
  const year = useSelector(selectedChartYearSelector);

  const handleSelectYear = useCallback(
    (selectedChartYear: number) => dispatch(setLayoutAction({ selectedChartYear })),
    [dispatch]
  );

  if (!uuid) return null;
  return (
    <Tabs
      mountOnEnter
      unmountOnExit
      activeKey={activeKey}
      onSelect={((nextActiveKey: TimeSeriesTypes) => setActiveKey(nextActiveKey)) as any}
      inModal
    >
      <Tab id="profile_modal__power" eventKey={TimeSeriesTypes.POWER} title={getIntl('Power')}>
        <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.POWER} />
      </Tab>
      <Tab id="profile_modal__voltage" eventKey={TimeSeriesTypes.VOLTAGE} title={getIntl('Voltage')}>
        <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.VOLTAGE} />
      </Tab>
      {type !== ProfileModalTypes.Fuse && (
        <Tab id="profile_modal__losses" eventKey={TimeSeriesTypes.LOSSES} title={getIntl('Losses')}>
          <AssetTimeSeriesChart uuid={uuid} type={TimeSeriesTypes.LOSSES} />
          <ListGroup variant="flush">
            <ListGroup.Item className="d-flex align-items-center p-2">
              {getIntl('Real')}:{' '}
              <span className="ml-auto">
                {type === ProfileModalTypes.Transformer ? transformer?.real_losses : cable?.real_losses}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-center p-2">
              {getIntl('Reactive')}:{' '}
              <span className="ml-auto">
                {type === ProfileModalTypes.Transformer ? transformer?.reactive_losses : cable?.reactive_losses}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Tab>
      )}
      {type !== ProfileModalTypes.TransformerForecast && (
        <Tab id="profile_modal__comments" eventKey="ignoreMessages" title={getIntl('Comments')}>
          <NetworkLoadingIgnoreMessagesTable />
        </Tab>
      )}
      {![ProfileModalTypes.TransformerForecast, ProfileModalTypes.Fuse].includes(type) && (
        <Tab id="profile_modal__profile" eventKey="profile" title={getIntl('Profile')}>
          <AssetProfileChart uuid={uuid} />
        </Tab>
      )}
      {![ProfileModalTypes.TransformerForecast, ProfileModalTypes.Fuse].includes(type) && (
        <Tab id="profile_modal__duration" eventKey="duration" title={getIntl('Duration')}>
          <AssetDurationChart uuid={uuid} />
        </Tab>
      )}
      {![ProfileModalTypes.TransformerForecast, ProfileModalTypes.Fuse].includes(type) && (
        <Tab id="profile_modal__reliability" eventKey="Reliability" title={getIntl('Reliability')}>
          <ChartProbabilityOfFailure
            uuid={uuid}
            cnaim_id={type === ProfileModalTypes.Transformer ? transformer?.cnaim_id : cable?.cnaim_id}
          />
        </Tab>
      )}
      {![ProfileModalTypes.TransformerForecast, ProfileModalTypes.Fuse].includes(type) && (
        <Tab id="profile_modal__customers" eventKey="customers" title={getIntl('Customers')}>
          <div className="d-flex mb-2">
            <SelectDERsPreviewYear
              labelKey=""
              value={year}
              onChange={handleSelectYear}
              variant="small"
              isSearchable={false}
            />
          </div>
          <NetworkLoadingCustomersTypesTable />
        </Tab>
      )}
    </Tabs>
  );
};

export const DutyFactor: React.FC<{
  uuid: string | null;
  portfolioId: Layouts.Root['portfolioId'];
  scenarioId: Layouts.ScenarioId;
  simulationId: Layouts.Root['simulationId'];
  flex: boolean;
}> = ({ uuid, portfolioId, scenarioId, simulationId, flex }) => {
  const { getIntl, lng } = useLocale();
  const [data, setData] = useState<{ duty_factor_1: number; duty_factor_2: number } | null>(null);

  useEffect(() => {
    if (!uuid) return;
    AssetLifeAPI.get(
      `/load/duty_factor?${queryString.stringify({
        portfolio_id: portfolioId,
        scenario_id: scenarioId,
        ...(Boolean(simulationId) && { simulation_id: simulationId }),
        lang: lng.toLowerCase(),
        id: uuid,
        flex,
      })}`
    )
      .then((res: any) => setData(res.data))
      .catch(console.error);
  }, [uuid, lng, portfolioId, scenarioId, simulationId, flex]);

  if (!data) return null;
  return (
    <ListGroup className="ml-2">
      <ListGroup.Item className="border-node p-2">
        {getIntl('Duty factor 1')} - <span className="ml-auto">{String(data.duty_factor_1)}</span>
      </ListGroup.Item>
      <ListGroup.Item className="border-node p-2">
        {getIntl('Duty factor 2')} - <span className="ml-auto">{String(data.duty_factor_2)}</span>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default ProfileTabs;
