import React from 'react';
import { Accordion, Card, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { Button, OverlayTriggerTooltip, ContentCard } from 'components/_common';
import { Routes, BuilderFormTypeNames } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

interface Props {
  className?: string;
  task: Tasks.Root['taskDetails'];
}

const TaskDetailsInfo: React.FC<Props> = ({ className = '', task }) => {
  const { getIntl } = useLocale();

  const renderAccordionToggle = ({
    eventKey,
    titleKey,
    title,
    length,
  }: {
    eventKey: string;
    titleKey?: string;
    title?: string;
    length: number;
  }) => {
    const text = `${title || (titleKey && getIntl(titleKey))}: ${length}`;
    return length ? (
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        className="d-flex align-items-center cursor-pointer border-bottom-0"
      >
        {text} <i className="fas fa-chevron-down ml-auto" />
      </Accordion.Toggle>
    ) : (
      <Card.Header className="border-bottom-0">{text}</Card.Header>
    );
  };

  const renderCompletion = (index: number, completion: Tasks.TaskCompletion) => {
    const assetCode = completion.answer?.assetNumber;
    const assetUuid = completion.answer?.assetUuid;
    return (
      <li key={index} className="d-flex align-items-center justify-content-between">
        <span>
          {completion.date}
          {Boolean(assetCode) && (
            <>
              {' - '}
              {assetUuid ? (
                <Link
                  to={{
                    pathname: generatePath(Routes.Map, { uuid: assetUuid }),
                    state: { prevRoute: window.location.pathname },
                  }}
                >
                  {assetCode}
                </Link>
              ) : (
                assetCode
              )}
            </>
          )}
          {' - '}
          {completion.user.fullName} - {completion.user.email}
          {completion.notActual && (
            <>
              {' '}
              <Badge variant="warning" className="m-0">
                {getIntl('Not actual')}
              </Badge>
            </>
          )}
        </span>
        {completion.answer?.id && (
          <Link
            to={{
              pathname: generatePath(Routes.HistoryAnswers, { id: completion.answer.id }),
              state: { prevRoute: window.location.pathname },
            }}
          >
            <OverlayTriggerTooltip
              placement="top"
              overlayId={String(index)}
              overlayChildren={getIntl('Answer details')}
            >
              <Button icon={<IconDetails />} />
            </OverlayTriggerTooltip>
          </Link>
        )}
      </li>
    );
  };

  if (!task) return null;
  return (
    <Accordion className={className}>
      <ContentCard className="p-0 border-bottom-0">
        {renderAccordionToggle({
          eventKey: 'other-completions',
          titleKey: 'Task completions',
          length: task.taskCompletionsOther.length,
        })}
        <Accordion.Collapse eventKey="other-completions">
          <ul className="todo-list">
            {task.taskCompletionsOther.map((completion, index) => renderCompletion(index, completion))}
          </ul>
        </Accordion.Collapse>
      </ContentCard>

      {Boolean(task.taskCompletionsFinalChecks.length) && (
        <ContentCard className="p-0 border-bottom-0">
          {renderAccordionToggle({
            eventKey: BuilderFormTypeNames.FinalCheck,
            titleKey: BuilderFormTypeNames.FinalCheck,
            length: task.taskCompletionsFinalChecks.length,
          })}
          <Accordion.Collapse eventKey={BuilderFormTypeNames.FinalCheck}>
            <ul className="todo-list">
              {task.taskCompletionsFinalChecks.map((completion, index) => renderCompletion(index, completion))}
            </ul>
          </Accordion.Collapse>
        </ContentCard>
      )}
      {Boolean(task.taskCompletionsRelatedForms.length) && (
        <ContentCard className="p-0 border-bottom-0">
          {renderAccordionToggle({
            eventKey: BuilderFormTypeNames.TaskRelatedForm,
            titleKey: BuilderFormTypeNames.TaskRelatedForm,
            length: task.taskCompletionsRelatedForms.length,
          })}
          <Accordion.Collapse eventKey={BuilderFormTypeNames.TaskRelatedForm}>
            <ul className="todo-list">
              {task.taskCompletionsRelatedForms.map((completion, index) => renderCompletion(index, completion))}
            </ul>
          </Accordion.Collapse>
        </ContentCard>
      )}
      {Boolean(task.taskCompletionsInspections.length) && (
        <ContentCard className="p-0 border-bottom-0">
          {renderAccordionToggle({
            eventKey: BuilderFormTypeNames.Inspection,
            titleKey: BuilderFormTypeNames.Inspection,
            length: task.taskCompletionsInspections.length,
          })}
          <Accordion.Collapse eventKey={BuilderFormTypeNames.Inspection}>
            <ul className="todo-list">
              {task.taskCompletionsInspections.map((completion, index) => renderCompletion(index, completion))}
            </ul>
          </Accordion.Collapse>
        </ContentCard>
      )}

      <ContentCard className="p-0 border-bottom-0">
        {renderAccordionToggle({
          eventKey: 'uploaded-documents',
          titleKey: 'Uploaded documents',
          length: task.taskAttachments?.length || 0,
        })}
        <Accordion.Collapse eventKey="uploaded-documents">
          <ul className="todo-list user-select-none">
            {task.taskAttachments?.map?.((taskAttachment: Tasks.TaskAttachment) => (
              <li key={taskAttachment.id} className="d-flex flex-column">
                <a
                  // className="text"
                  href={taskAttachment.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {taskAttachment.name}
                </a>

                {taskAttachment.description ? <small>{taskAttachment.description}</small> : ''}
              </li>
            ))}
          </ul>
        </Accordion.Collapse>
      </ContentCard>
      <ContentCard className="p-0 border-bottom-0">
        {renderAccordionToggle({
          eventKey: 'responsible-users',
          titleKey: 'Responsible users',
          length: task.responsibleUsers?.length || 0,
        })}
        <Accordion.Collapse eventKey="responsible-users">
          <ul className="todo-list">
            {task.responsibleUsers.map(user => (
              <li key={user.fullName}>
                {user.fullName} - {user.email}
              </li>
            ))}
          </ul>
        </Accordion.Collapse>
      </ContentCard>
      <ContentCard className="p-0 border-bottom-0">
        {renderAccordionToggle({
          eventKey: 'assigned-users',
          titleKey: 'Assigned users',
          length: task.assignedUsers?.length || 0,
        })}
        <Accordion.Collapse eventKey="assigned-users">
          <ul className="todo-list">
            {task.assignedUsers.map(user => (
              <li key={user.fullName}>
                {user.fullName} - {user.email}
              </li>
            ))}
          </ul>
        </Accordion.Collapse>
      </ContentCard>
      <ContentCard className="p-0">
        {renderAccordionToggle({
          eventKey: 'erp-registered-hours',
          titleKey: 'ERP registered hours',
          length: task.usersLogs?.length ? task.usersLogs.reduce((acc, item) => acc + item.registeredTime, 0) : 0,
        })}
        <Accordion.Collapse eventKey="erp-registered-hours">
          <ul className="todo-list">
            {task.usersLogs.map((user, index: number) => (
              <li key={index}>
                {user.registeredTime} - {user.fullName} - {user.email}
              </li>
            ))}
          </ul>
        </Accordion.Collapse>
      </ContentCard>
    </Accordion>
  );
};

export default TaskDetailsInfo;
