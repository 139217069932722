import React, { useMemo, useEffect, useCallback } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { categoriesSelector } from 'modules/assets/selectors';
import { fetchAssetCategoriesAction } from 'modules/assets';
import { SyntheticAssetCategories } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'onChange' | 'options'> {
  fullObject?: boolean;
  isIdValue?: boolean;
  value: number | string | string[] | null;
  excludeSyntheticOptions?: boolean;
  showOptions?: Type.AssetCategories[];
  onChange?: (value: Type.SelectOption | Asset.Category | null, actionMeta: Type.SelectActionMetaBase) => void;
}

const SelectAssetCategories: React.FC<Props> = ({
  labelKey = 'Asset category',
  fullObject = false,
  isDisabled,
  isIdValue = false,
  value,
  excludeSyntheticOptions = false,
  showOptions = null,
  onChange,
  ...props
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const categories = useSelector(categoriesSelector);

  useEffect(() => {
    if (categories) return;
    dispatch(fetchAssetCategoriesAction());
  }, [categories, dispatch]);

  const { options, values } = useMemo(
    () =>
      (categories || []).reduce(
        (acc: any, { id, code, name }: Asset.Category) => {
          if (showOptions && !showOptions.includes(code)) return acc;
          if (excludeSyntheticOptions && SyntheticAssetCategories.includes(code)) return acc;
          const item = { value: isIdValue ? id : code, label: getIntl(name) };
          if ((Array.isArray(value) ? value : [value]).includes(isIdValue ? id : code)) acc.values.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], values: [] }
      ),
    [value, categories, getIntl, isIdValue, excludeSyntheticOptions, showOptions]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, actionMeta: Type.SelectActionMetaBase) => {
      if (!onChange) return;
      if (!fullObject) return onChange(value, actionMeta);
      if (!value?.value) return onChange(null, actionMeta);
      const category = categories?.find(({ id, code }) => id === value?.value || code === value?.value);
      if (!category) throw Error("SelectAssetCategories can't find category object");
      return onChange(category, actionMeta);
    },
    [fullObject, categories, onChange]
  );

  return (
    <FormReactSelect
      {...props}
      isMulti={Array.isArray(value)}
      labelKey={labelKey}
      isLoading={!categories}
      isDisabled={!categories || isDisabled}
      value={values.length ? values : null}
      options={options}
      onChange={handleSelectChange as any}
    />
  );
};

export default SelectAssetCategories;
