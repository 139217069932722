import styled from 'styled-components';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, ListGroup, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { fetchAnswerAction, deleteAnswerAction } from 'modules/answers';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { AnswerDeleteAlert } from 'components';
import {
  Button,
  BackToTableButton,
  DataTableTemplate,
  Gallery,
  Checkbox,
  FormInput,
  ContentContainer,
  ContentCard,
  Spinner,
} from 'components/_common';
import { getGalleryImages } from 'utils';
import { Routes, LegacyBuilderFieldsTypesMap, BuilderFieldsTypes } from 'constants/index';
import { IconDownload, IconDelete } from '@utiligize/shared/resources';

const HistoryAnswer: React.FC<RouteComponentProps<{ id: string }>> = ({ match, location }) => {
  const state = location.state as { prevRoute?: string } | undefined;
  const id: number = Number(match.params.id);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, dateFormat } = useLocale();
  const [actionData, setActionData] = useState<Answers.Answer | null>(null);

  useEffect(() => {
    setActionData(null);
    dispatch(fetchAnswerAction(id)).then((action: Shared.ReduxAction<Answers.Answer>) => setActionData(action.payload));
  }, [dispatch, id]);

  const handleDeleteBtnClick = useCallback(() => {
    dispatch(
      setModalConfirmationMetaAction({
        bodyComponent: <AnswerDeleteAlert name={actionData?.name || ''} />,
        onConfirm: () => dispatch(deleteAnswerAction({ id, redirectUrl: generatePath(Routes.HistoryAnswers) })),
      })
    );
  }, [dispatch, id, actionData?.name]);

  return (
    <DataTableTemplate paddingBottom>
      <StyledControlsRow>
        <Col>
          <Link to={state?.prevRoute || generatePath(Routes.HistoryAnswers)}>
            <BackToTableButton />
          </Link>
        </Col>
        <Col xs="auto" className="ml-auto">
          <Button icon={<IconDownload />} labelKey="Download" className="mr-2" onClick={window.print} />
          <Button icon={<IconDelete />} onClick={handleDeleteBtnClick} />
        </Col>
      </StyledControlsRow>
      <StyledContentContainer>
        <ContentCard marginBottom>
          <Row>
            <Col className="border-right border-muted text-center">
              <span className="text-uppercase text-muted">{getIntl('Form')}</span>
              <br />
              {actionData?.name}
            </Col>
            <Col className="border-right border-muted text-center">
              <span className="text-uppercase text-muted">{getIntl('Username')}</span>
              <br />
              {actionData?.userName}
            </Col>
            <Col className="border-right border-muted text-center">
              <span className="text-uppercase text-muted">{getIntl('Answer date')}</span>
              <br />
              {actionData && dateFormat(actionData.dateCreated)}
            </Col>
            <Col className="border-right border-muted text-center">
              <span className="text-uppercase text-muted">{getIntl('Asset code')}</span>
              <br />
              {actionData?.assetCode || '-'}
            </Col>
            <Col className="text-center">
              <span className="text-uppercase text-muted">{getIntl('Tasks')}</span>
              <br />
              {actionData?.tasksIds?.length
                ? actionData?.tasksIds.map((id: number, index: number) => (
                    <React.Fragment key={id}>
                      <Link to={generatePath(Routes.Tasks, { id })}>{id}</Link>
                      {actionData?.tasksIds?.length - 1 !== index ? ', ' : ''}
                    </React.Fragment>
                  ))
                : '-'}
            </Col>
          </Row>
        </ContentCard>
        <ContentCard>
          {actionData ? (
            actionData?.fields.map((item: any, index: number) => (
              <div
                key={item.id}
                className={classNames('d-flex flex-column py-3', {
                  'border-bottom border-muted': actionData?.fields.length - 1 !== index,
                })}
              >
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.Text] && (
                  <h5 className="mb-0">{item.title}</h5>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.TextInput] && (
                  <FormInput value={item.answer?.value || getIntl('No answer was provided')} disabled />
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.LargeTextInput] && (
                  <FormInput
                    as="textarea"
                    rows={2}
                    value={item.answer?.value || getIntl('No answer was provided')}
                    disabled
                  />
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.NumberInput] && (
                  <>
                    <h5>{item.label}</h5>
                    {item?.inputName || ''}
                    {item.answer?.value || '-'}
                    {item?.unit || ''}
                  </>
                )}

                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.Photo] && (
                  <>
                    <h5>{item.label}</h5>
                    <Gallery
                      images={[
                        {
                          id: item.id,
                          gcsFileName: item.description,
                          src: item.photo,
                        },
                      ]}
                    />
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.AssetPhoto] && (
                  <>
                    <h5>{item.description}</h5>
                    <Gallery
                      images={[
                        {
                          id: item.id,
                          gcsFileName: item.description,
                          src: item.photo,
                        },
                      ]}
                    />
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.PhotoCollection] && (
                  <>
                    <h5>{item.label}</h5>
                    <Gallery images={getGalleryImages(item.answer?.attachments || [])} />
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.Signature] && (
                  <>
                    <h5>{item.label}</h5>
                    {item.answer?.attachments?.length ? (
                      <Gallery images={getGalleryImages(item.answer?.attachments || [])} rowHeight={180} />
                    ) : (
                      getIntl('No answer was provided')
                    )}
                  </>
                )}

                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.LinkedForm] && (
                  <div>
                    {item.label && <h5 className="mb-0">{item.label}</h5>}
                    {item.answer?.formAnswerId ? (
                      <Link to={generatePath(Routes.HistoryAnswers, { id: item.answer?.formAnswerId })}>
                        {getIntl('Answer')}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </div>
                )}

                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.BinarySelect] && (
                  <div>
                    {item.options.map((i: any, index: number) => (
                      <Button
                        key={i.value}
                        labelKey={i.value}
                        variant={item.answer?.options?.includes(i.id) ? 'primary' : undefined}
                        size="small"
                        className="mr-3 cursor-none"
                      />
                    ))}
                  </div>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.TwoOption] && (
                  <>
                    <h5 className="mb-0">{item.label}</h5>
                    <div>
                      {item.options.map((i: any, index: number) => (
                        <Button
                          key={i.value}
                          labelKey={i.value}
                          variant={item.answer?.options?.includes(i.id) ? 'primary' : undefined}
                          size="small"
                          className="mx-3 mt-3 cursor-none"
                        />
                      ))}
                    </div>
                  </>
                )}
                {[
                  LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.SingleSelect],
                  LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.MultipleSelect],
                  LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.InspectionQuestion],
                ].includes(item.type) && (
                  <>
                    <h5 className="mb-0">{item.label || item.title}</h5>
                    <ListGroup variant="flush">
                      {item.options.map((option: any) => (
                        <ListGroup.Item key={option.id} className="d-flex align-items-center ml-3 p-0 py-2">
                          <Checkbox
                            className="icheck-default d-inline mr-3 cursor-none"
                            checked={Boolean(item.answer?.options.includes(option.id))}
                            disabled={!Boolean(item.answer?.options.includes(option.id))}
                            onChange={() => null}
                            type={
                              LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.SingleSelect] === item.type
                                ? 'radio'
                                : 'checkbox'
                            }
                          />
                          <Button
                            type="button"
                            className="mr-3 flex-shrink-0 rounded-circle cursor-none"
                            style={{ backgroundColor: option.color }}
                            size="small"
                            disabled
                          />
                          <div>
                            <div className="font-weight-bold">{option.value}</div>
                            <div>{option.explanation}</div>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.Date] && (
                  <>
                    <h5>{item.label}</h5>
                    {item.answer?.value ? dateFormat(item.answer?.value) : '-'}
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.LinkedFunctionality] && (
                  <>
                    <h5>{item.label}</h5>
                    {item.functionalityType}
                    {Boolean(item?.answer?.replaceHistory) && (
                      <Table bordered className="utiligize-table dataTable">
                        <thead>
                          <tr>
                            <th>{getIntl('Installation date')}</th>
                            <th>{getIntl('Type')}</th>
                            <th>{getIntl('Manufacturer')}</th>
                            <th>{getIntl('Serial number')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{dateFormat(item.answer?.replaceHistory.idriftsatdato)}</td>
                            <td>{item.answer?.replaceHistory.type}</td>
                            <td>{item.answer?.replaceHistory.fabrikat}</td>
                            <td>{item.answer?.replaceHistory.fabrikat_nummer}</td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
                {item.type === LegacyBuilderFieldsTypesMap[BuilderFieldsTypes.NumberInputInspection] && (
                  <>
                    <h5>{item.text}</h5>
                    {item.number || getIntl('No answer was provided')}
                  </>
                )}
                {Boolean(item?.answer?.comments?.length) && (
                  <ul className="mt-3 mb-0">
                    {item.answer.comments.map((comment: { id: number; message: string }) => (
                      <li key={comment.id}>{comment.message}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          ) : (
            <Spinner inline />
          )}
        </ContentCard>
      </StyledContentContainer>
    </DataTableTemplate>
  );
};

const StyledContentContainer = styled(ContentContainer)`
  max-height: calc(100vh - 123px);
  overflow: auto;
`;

const StyledControlsRow = styled(Row)`
  @media print {
    display: none;
  }
`;

export default HistoryAnswer;
