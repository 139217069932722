import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setModalConfirmationMetaAction, setInfoToastAction } from 'modules/layouts';
import { deleteTaskAttachmentsAction, setTaskDetailsAction } from 'modules/tasks';
import { Button, Gallery } from 'components/_common';
import { IconDelete } from '@utiligize/shared/resources';

const TaskDetailsUserPhotos: React.FC<Tasks.Root['taskDetails']> = task => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleSelectImage = useCallback(
    (index: number, image: Type.GalleryImage) => {
      const nextUsersAttachments = task!.usersAttachments.slice();
      nextUsersAttachments[index].isSelected = !image.isSelected;
      dispatch(setTaskDetailsAction({ ...task!, usersAttachments: nextUsersAttachments }));
    },
    [dispatch, task]
  );

  const handleDeleteButtonClick = useCallback(() => {
    const { ids, nextImages } = task!.usersAttachments.reduce(
      (acc: { ids: number[]; nextImages: Type.GalleryImage[] }, image: Type.GalleryImage) => {
        if (image.isSelected) {
          acc.ids.push(image.id);
        } else {
          acc.nextImages.push(image);
        }
        return acc;
      },
      { ids: [], nextImages: [] }
    );

    if (!ids.length) return dispatch(setInfoToastAction('Please select at least one photo'));

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () =>
          dispatch(deleteTaskAttachmentsAction(task!.id, ids)).then(() =>
            dispatch(setTaskDetailsAction({ ...task!, usersAttachments: nextImages }))
          ),
      })
    );
  }, [task, dispatch]);

  return (
    <Row>
      <Col xs={12}>
        <Gallery images={task!.usersAttachments} enableImageSelection onSelectImage={handleSelectImage} />
      </Col>
      {Boolean(task!.usersAttachments.length) && (
        <Col xs={12} className="text-right">
          <Button
            className="mt-3"
            labelKey="Delete photos"
            icon={<IconDelete />}
            onClick={handleDeleteButtonClick}
            variant="danger"
            size="large"
          />
        </Col>
      )}
    </Row>
  );
};

export default TaskDetailsUserPhotos;
